<template>
    <div class="courseInfo" @contextmenu.prevent>
      <a-spin :spinning="spinning">
        <div class="courseCenter">
          <div class="tabs">
            <span class="returnHome" @click="handleReturn">{{type==1?'课程学习':'学习计划'}}> </span>
            <span>{{detail.name}}</span>
          </div>
          <div class="contentInfo">
            <div class="videoBlock">
              <img class="videoIcon" v-if="!isPlay" @click="handleIsPlay" src="../../assets/image/videoIcon.png" alt="">
              <video class="videoUrl" ref="myVideo" :currentTime="learnedTime" :src="videoUrl" controlsList="nodownload noplaybackrate" disablePictureInPicture preload controls @timeupdate="timeupdate" @play="onPlay" @pause="onPause"  @seeked="seeked"></video>
            </div>
            <div class="videoList">
              <div class="title">课程章节</div>
              <div class="list">
                <a-collapse v-model:activeKey="activeKey" ghost>
                  <template #expandIcon="{ isActive }">
                    <CaretRightOutlined :rotate="isActive ? 90 : 0" />
                  </template>
                  <a-collapse-panel v-for="(item,index) in chaptersList" :key="index+1" :header="item.title"  :style="customStyle">
                    <div class="chaptersList">
                      <div class="chaptersItem" v-for="(itm,i) in item.catalogue" @click="handlePlay(item.id,itm.id)" :key="i">
                        <div>
                          <PauseOutlined style="color:#FFFFFF;" v-if="takeInfo.catalogueId==itm.id" />
                          <CaretRightOutlined style="color:#0960bd;" v-else />
                          <span class="aj-margin-left">{{itm.title}}</span>
                        </div>
                        <div class="chaptersTime">{{formatSeconds(itm.allSeconds)}}</div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
</template>

<script>
import { defineComponent, ref,onMounted,computed,nextTick,watchEffect} from 'vue';
import { CaretRightOutlined,PauseOutlined} from '@ant-design/icons-vue';
import {useRoute,useRouter} from 'vue-router';
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    CaretRightOutlined,
    PauseOutlined,
  },
  setup() {
    const activeKey=ref(['1'])
    const router=useRouter()
    const route = useRoute()
    const spinning=ref(false)
    const chaptersList=ref([])
    const detail=ref({})
    const customStyle ='margin-bottom:14px;border: 0;overflow: hidden;font-size:14px;line-height:16px;';
    const takeInfo=ref({
      detailId:route.query.courseId,
      chaptersId:route.query.chaptersId,
      catalogueId:route.query.catalogueId,
    })
    const type=ref(route.query.type)
    const videoUrl=ref('')
    const myVideo=ref()
    let renew=ref(0)
    const isPlay=ref(false)
    const currentTime=ref(0)
    const learnedTime=ref(0)
    const schedule=ref(0)

    onMounted(() => {
      getDetail()
      getChapters()
    })
    const Probation = ref(true) // true试用账号 false 正式账号
    let userInfo = {}
    const getIsTrial=()=>{
      defHttp.get('/trainer/School/schoolinfo?id=' + userInfo.SchoolId).then(data => {
        if (data.Success) {
          Probation.value = data.Data.Probation
        } 
      })
    }
    if (localStorage.getItem('userInfo')) {
      userInfo = JSON.parse(localStorage.getItem('userInfo'))
      getIsTrial()
    } else {
      defHttp.get('/system/account/userInfo').then(data => {
        if (data.Success) {
          userInfo = data.Data
          localStorage.setItem('userInfo',JSON.stringify(userInfo))
          getIsTrial()
        }
      }).catch(err => { })
    }

    const getDetail=()=>{
      spinning.value=true
      defHttp.get('student/course/detail',{params:{id:takeInfo.value.detailId}}).then(data=>{
        spinning.value=false
        if(data.Success){
          detail.value=data.Data
          let imgUrl=JSON.parse(data.Data.img)
          detail.value.img=imgUrl[0].Url
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handlePlay=(sectionsId,listingsId)=>{
      let firstChapterId = chaptersList.value[0].catalogue[0].id
      if (Probation.value && firstChapterId != listingsId) {
        message.success('试用账号无法查看此章节~')
        return 
      }
      clearInterval(renew.value);
      takeInfo.value.chaptersId=sectionsId
      takeInfo.value.catalogueId=listingsId
      getChapters()
    }

    
    
    const getChapters=()=>{
      defHttp.get('/student/course/chapters',{params:{id:takeInfo.value.detailId}}).then(data=>{
        if(data.Success){
          chaptersList.value=data.Data
          let list=chaptersList.value.find(p=>p.id==takeInfo.value.chaptersId).catalogue
          videoUrl.value=list.find(p=>p.id==takeInfo.value.catalogueId).url
          learnedTime.value=list.find(p=>p.id==takeInfo.value.catalogueId).studySeconds
          nextTick(() => {
            myVideo.value.currentTime = learnedTime.value
          })
          activeKey.value=chaptersList.value.map((item,index)=>{
            return index+1
          })
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    watchEffect(() => {
      if (chaptersList.value.length) {
        let firstChapterId = chaptersList.value[0].catalogue[0].id
        if (Probation.value && takeInfo.value.catalogueId != firstChapterId) {
          message.success('试用账号只能试看第一章节~')
          clearInterval(renew.value);
          takeInfo.value.chaptersId = chaptersList.value[0].id
          takeInfo.value.catalogueId = firstChapterId
          getChapters()
        }
      }
    });

    const handleReturn=()=>{
      // router.push({path:'/learningPlan'})
      router.go(-1)
    }

    const formatSeconds=(value)=>{
      let second = parseInt(value)
      let minute = 0
      let hour = 0
      if (second > 60) {
        minute = parseInt(second / 60)
        second = parseInt(second % 60)
        if (minute > 60) {
          hour = parseInt(minute / 60)
          minute = parseInt(minute % 60)
        }
      }
      let result = '' + parseInt(second) + ''
      if(result < 10){
        result = '0' + result;
      } else if(result == ''){
        result = '00';
      }
      if (minute > 0) {
        if(minute < 10){
          result = '0' + parseInt(minute) + ':' + result
        } else {
          result = '' + parseInt(minute) + ':' + result
        }
      } else {
        result = '00' + ':' + result 
      }
      
      if (hour > 0) {
        if(hour < 10){
          result = '0' + parseInt(hour) + ':' + result
        } else {
          result = '' + parseInt(hour) + ':' + result
        }
      }
      return result
    }

    const timeupdate=(e)=>{
      currentTime.value=e.target.currentTime
    }

    const handleRenew=()=>{
      if(currentTime.value>0){
        let params={
          lessonsId:takeInfo.value.catalogueId,
          seconds:parseInt(currentTime.value)
        }
        defHttp.post('/student/course/updateStudyTime',params).then(data=>{
          if(data.Success){

          }else{
            message.error(`${data.Message}`)
          }
        })
      }
      
    }

    const handleSchedule=()=>{
      if(currentTime.value>0){
        let params={
          lessonsId:takeInfo.value.catalogueId,
          seconds:parseInt(currentTime.value)
        }
        defHttp.post('/student/course/AddStudyTimeFlow',params).then(data=>{
          if(data.Success){

          }else{
            message.error(`${data.Message}`)
          }
        })
      }
      
    }

    //播放视频
    const onPlay=()=>{
      isPlay.value=true
      renew.value=setInterval(() => {
        handleRenew()
      }, 3000);

      schedule.value=setInterval(() => {
        handleSchedule()
      }, 60000);
    }

    //点击暂停视频
    const onPause=()=>{
      isPlay.value=false
      clearInterval(renew.value);
      clearInterval(schedule.value);
      handleRenew()
    }

    //点击播放按钮
    const handleIsPlay=()=>{
      myVideo.value.play()
    }

    //拉取进度条事件
    const seeked=()=>{
      if(currentTime.value!=0){
        handleRenew()
      }
    }
    return {
        activeKey,
        customStyle,
        handleReturn,
        spinning,
        chaptersList,
        getChapters,
        detail,
        formatSeconds,
        videoUrl,
        handlePlay,
        takeInfo,
        timeupdate,
        myVideo,
        renew,
        handleRenew,
        onPlay,
        onPause,
        isPlay,
        handleIsPlay,
        currentTime,
        learnedTime,
        seeked,
        schedule,
        handleSchedule,
        type
    };
  },
});
</script>

<style scoped>
.courseInfo{
  width: 100%;
  height:100%;
  background: #FFFFFF;
}
.courseCenter{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 32px;
}
.tabs{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.contentInfo{
  width: 100%;
  height: 630px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  background-color: #1D1D1D;
}
.videoBlock{
  width: 948px;
  height: 100%;
  position: relative;
}
.videoUrl{
  width: 100%;
  height: 100%;
  background-color: #1D1D1D;
}
.videoIcon{
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.videoList{
  width: 252px;
  height: 100%;
  background: #1D1D1D;
  color: #FFFFFF;
  padding: 20px;
  overflow-y: scroll;
}
.videoList::-webkit-scrollbar {
  display: none;
}
.title{
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 19px;
}
.video{
  width: 100%;
  height: 100%;
}
.list{
  padding-top: 12px;
}
:deep(.list .ant-collapse-header){
  padding: 0 !important;
  color: #FFFFFF !important;
  line-height: 16px !important;
}
.chaptersItem{
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(255,255,255,0.8);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding-top: 14px;
  cursor: pointer;
}
:deep(.list .ant-collapse-content-box){
  padding:0 !important;
  padding-bottom: 0 !important;
}
.returnHome{
  cursor:pointer;
}
</style>