<template>
  <div class="exam">
    <a-spin :spinning="spinning">
      <div class="examHeader">
        <div class="examHeaderCore">
            <div class="examName">{{paperInfo.CareerPathName}} <span>测试题</span></div>
            <div class="flex">
                <a-button class="submitBtn" type="primary" size="large" @click="handleClose">关闭</a-button>
                <a-button class="submitBtn" type="primary" size="large" @click="handleSubmit">交卷</a-button>
            </div>
        </div>
      </div>
      <div class="examContent">
        <div class="circumstances">
          <div class="examinationTime">
            <div class="progressTitle">当前进度</div>
            <div class="progress">{{computeProgress(questions)}}<span>/{{questions.length}}</span></div>
            <a-progress class="progressBar" :percent="computeProgressValue(questions)" strokeColor="#55ABFF" trailColor="#DBDBDB" :strokeWidth="4" :show-info="false" />
          </div>
          <div class="excuseList">
            <div class="subjectList">
              <div class="subjectType">答题卡:</div>
              <div class="subjectBlock">
                <div class="subjectItem" :class="{'accomplish':item.SutdentAnswers&&item.SutdentAnswers.length,'notDone':!item.SutdentAnswers}" v-for="(item,index) in questions" :key="index">{{index+1}}</div>
              </div>
            </div>
            <div class="subjectTypeTips">
              <div class="tipsItem">
                <div class="TipsColor accomplish"></div>
                <div class="tipsType">已做</div>
              </div>
              <div class="tipsItem">
                <div class="TipsColor notDone"></div>
                <div class="tipsType">未做</div>
              </div>
            </div>
          </div>
        </div>
        <div class="answerArea">
          <div class="answerAreaList" v-for="(item,index) in questions" :key="index">
            <div class="title">
              <span>{{index+1}}.（{{computeType(item.Type)}}）{{item.TheContent}}</span>
              <div class="aj-margin-top" v-if="item.ImageUrls!=null">
                <a-space>
                  <a-image :width="80" v-for="(itm,i) in JSON.parse(item.ImageUrls)" :key="i" :src="itm.url" />
                </a-space>
              </div>
            </div>
            <div class="questions">
              <div class="groupBlock" v-if="item.Type==0">
                <a-radio-group v-model:value="item.SutdentAnswers" @change="hangdeRadio(item)">
                  <a-radio :style="radioStyle" :value="String(itm.Id)" v-for="(itm,i) in item.Options" :key="i">
                    <span>{{itm.TheContent}}</span>
                    <span class="aj-margin-left">
                      <a-space v-if="itm.Images">
                        <a-image :width="40" v-for="(value,i) in JSON.parse(itm.Images)" :key="i" :src="value.Url" />
                      </a-space>
                    </span>
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="groupBlock" v-if="item.Type==1||item.Type==2">
                <a-checkbox-group v-model:value="item.SutdentAnswers">
                  <a-checkbox :style="radioStyle" :value="itm.Id" v-for="(itm,i) in item.Options" :key="i">
                    <span>{{itm.TheContent}}</span>
                    <span class="aj-margin-left">
                      <a-space v-if="itm.Images">
                        <a-image :width="40" v-for="(value,i) in JSON.parse(itm.Images)" :key="i" :src="value.Url" />
                      </a-space>
                    </span>
                  </a-checkbox>
                </a-checkbox-group>
              </div>
              <div class="groupBlock" v-if="item.Type==3">
                <a-radio-group v-model:value="item.SutdentAnswers"  @change="hangdeRadio(item)">
                  <a-radio :style="radioStyle" :value="String(itm.Id)" v-for="(itm,i) in item.Options" :key="i">
                    <span>{{itm.TheContent}}</span>
                    <span class="aj-margin-left">
                      <a-space v-if="itm.Images">
                        <a-image :width="40" v-for="(value,i) in JSON.parse(itm.Images)" :key="i" :src="value.Url" />
                      </a-space>
                    </span>
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,reactive,computed} from 'vue';
import { StarFilled } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    StarFilled
  },
  setup() {
    const value=ref(1)
    const router=useRouter()
    const route = useRoute()

    const dataList=ref({
      nodeId:route.query.nodeId,
      directionId:route.query.directionId,
    })
    
    const spinning=ref(false)
    const typeList=ref([])
    const paperInfo=ref({})
    const questions=ref([])

    const radioStyle = reactive({
      display: 'flex',
      paddingTop:'15px',
      paddingBottom:'15px',
    });

    onMounted(() => {
      getTypeList()
    })

    const getPaper=()=>{
      spinning.value=true
      defHttp.get('/student/evaluation/paper',{params:{id:dataList.value.nodeId,careerPath:dataList.value.directionId}}).then(data=>{
        spinning.value=false
        if(data.Success){
          paperInfo.value=data.Data.paperInfo
          questions.value=data.Data.questions
          questions.value.forEach(item=>{
            item.SutdentAnswers=null
          })
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

     const getTypeList=()=>{
      defHttp.get('platform/Common/Enum',{params:{name:'QuestionType'}}).then(data=>{
        if(data.Success){
          typeList.value=data.Data
          getPaper()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const computeProgress = computed(() => {
      return (val) => {
        let num=0
        val.forEach(item=>{
          if(item.SutdentAnswers&&item.SutdentAnswers.length){
            num+=1
          }
        })
        return num
      }
    })

    const computeProgressValue=computed(()=>{
      return (val) => {
        let num=0
        val.forEach(item=>{
          if(item.SutdentAnswers&&item.SutdentAnswers.length){
            num+=1
          }
        })
        let percentage=0
        percentage=(Math.round(num / questions.value.length*10000) / 100.00)
        if(percentage){
          return percentage
        }else{
          return 0
        }
      }
    })

    const computeType=(val)=>{
      if(val!=undefined){
        return typeList.value.find(p=>p.id==val).name
      }
    }

    const handleClose=()=>{
      router.go(-1)
    }

    const handleSubmit=()=>{
      let answers=[]
      questions.value.forEach(item=>{
        if(item.Type==0||item.Type==3){
          let answersObj={
            id:item.Id,
            answerOpts:[item.SutdentAnswers]
          }
          answers.push(answersObj)
        }else{
          let answersObj={
            id:item.Id,
            answerOpts:item.SutdentAnswers,
          }
          answers.push(answersObj)
        }
      })
      let params={
        paperId:paperInfo.value.Id,
        answers:answers
      }
      defHttp.post('/student/evaluation/submit',params).then(data=>{
        if(data.Success){
          message.success('交卷成功')
          setTimeout(()=>{
            router.go(-1)
          },2000)
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const hangdeRadio=(e)=>{
      let index=questions.value.findIndex(p=>p.Id==e.Id)
      questions.value[index].SutdentAnswers=String(e.SutdentAnswers)
    }

    return {
      value,
      radioStyle,
      spinning,
      questions,
      paperInfo,
      computeType,
      typeList,
      handleClose,
      computeProgress,
      handleSubmit,
      dataList,
      hangdeRadio,
      computeProgressValue
    };
  },
});
</script>
<style scoped>
.exam{
  height:100%;
  background-color: #FFFFFF;
}
.examHeader{
  width: 100%;
  height: 88px;
  background: #FFFFFF;
  border-bottom: 2px solid #CCCCCC;
}
.examHeaderCore{
  width: 62.5%;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.examName span{
    color: #666666;
}
.examName{
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
}
.submitBtn{
  margin: auto 0 auto 20px;
}
:deep(.submitBtn,.ant-btn-lg) {
  padding: 6.4px 30px !important;
}
.examContent{
  background-color: #FFFFFF;
  padding-top: 50px;
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.circumstances{
  width: 23%;
  height: 652px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  border-radius: 10px;
  box-sizing: border-box;
}
.answerArea{
  width: 75.5%;
  max-height: 707px;
  overflow-y: scroll;
  background-color: #FFFFFF;
  padding: 0 50px;
  box-sizing: border-box;
  position: relative;
}
.answerAreaList{
  margin-bottom: 15px;
}
.examinationTime{
  padding: 21px 30px 19px 30px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  border-radius: 10px 10px 0px 0px;
}
.progressTitle{
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
}
.progress{
  padding-top: 15px;
  font-size: 20px;
  font-weight: 700;
  color: #55ABFF;
  line-height: 23px;
}
.progress span{
  font-size: 16px;
  color: #666666;
}
.progressBar{
  margin-top: 3px;
}
.excuseList{
  height: 529px;
  padding: 20px 15px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subjectType{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.subjectBlock{
  display: flex;
  flex-wrap: wrap;
}
.subjectItem{
  margin: 10px 15px 0 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 1px solid #CCCCCC;
}
.accomplish{
  background: #55ABFF;
  border: 1px solid #55ABFF;
  color: #FFFFFF;
}
.notDone{
  border: 1px solid #CCCCCC;
  color: #333333;
}
.mark{
  background: #0960bd;
  border: 1px solid #0960bd;
  color: #FFFFFF;
}
.active{
  border: 1px solid #55ABFF;
  color: #333333;
}
.subjectTypeTips{
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
}
.tipsItem{
  display: flex;
}
.TipsColor{
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.tipsType{
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
.title{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.fraction{
  color: #999999;
}
.markBtn{
  margin-left: 4px;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #999999;
  text-align: center;
  line-height: 30px;
}
.NextQuestion{
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  text-align:center;
}
:deep(.NextQuestion .ant-btn) {
  padding: 4px 150px !important;
}
.questions{
  max-height: 500px;
  overflow-y: scroll;
}
.questions::-webkit-scrollbar { 
  width: 0; 
}
:deep(.questions .ant-radio-wrapper-checked span){
  color: var(--theme) !important;
}
.activeMark{
  border: 1px solid var(--theme);
  color: var(--theme) !important;
}
:deep(.ant-checkbox-wrapper){
  margin-left: 0 !important;
}
</style>
