<template>
    <div class="tinymce-container" :style="{ width: containerWidth }">
        <a-upload name="file" :file-list="fileList" :multiple="false" :customRequest="uploadMedia" :showUploadList="false">
            <a href="javascript:void(0);" ref="uploadBtn" style="opacity: 0;"></a>
        </a-upload>
        <textarea :id="tinymceId" ref="elRef" :style="{ visibility: 'hidden' }" v-if="!initOptions.inline"></textarea>
        <slot v-else></slot>
    </div>
</template>
<script>
import {
    defineComponent,
    computed,
    nextTick,
    ref,
    unref,
    watch,
    onDeactivated,
    onBeforeUnmount,
} from 'vue'
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/tabfocus';
// import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

import { toolbar, plugins } from './tinymce';
import { bindHandlers } from './helper';
import { buildShortUUID } from '@/utils/uuid'
import defHttp from "@/utils/http.js"
import { message } from 'ant-design-vue'
import { onMountedOrActivated } from '@/utils/common'

const tinymceProps = {
    options: {
        type: Object,
        default: () => ({}),
    },
    value: {
        type: String,
    },

    toolbar: {
        type: Array,
        default: toolbar,
    },
    plugins: {
        type: Array,
        default: plugins,
    },
    modelValue: {
        type: String,
    },
    height: {
        type: [Number, String],
        required: false,
        default: 400,
    },
    width: {
        type: [Number, String],
        required: false,
        default: 'auto',
    },
    showImageUpload: {
        type: Boolean,
        default: true,
    },
};

export default defineComponent({
    name: 'Tinymce',
    inheritAttrs: false,
    props: tinymceProps,
    emits: ['change', 'update:modelValue', 'inited', 'init-error'],
    setup(props, { emit, attrs }) {
        const editorRef = ref(null);
        const uploadBtn = ref();
        const fileList = ref([])
        const fullscreen = ref(false);
        const tinymceId = ref(buildShortUUID('tiny-vue'));
        const elRef = ref(null);

        const tinymceContent = computed(() => props.modelValue);

        const containerWidth = computed(() => {
            const width = props.width;
            if (Number(width) != NaN) {
                return `${width}px`;
            }
            return width;
        });

        const initOptions = computed(() => {
            const { height, options, toolbar, plugins } = props;
            const publicPath = '';
            return {
                selector: `#${unref(tinymceId)}`,
                height,
                toolbar,
                menubar: 'file edit insert view format table',
                plugins,
                language_url: publicPath + 'resource/tinymce/langs/zh_CN.js',
                language: 'zh_CN',
                branding: false,
                default_link_target: '_blank',
                link_title: false,
                object_resizing: false,
                auto_focus: true,
                skin: 'oxide',
                skin_url: publicPath + 'resource/tinymce/skins/ui/oxide',
                content_css:
                    publicPath + 'resource/tinymce/skins/ui/oxide/content.min.css',
                ...options,
                setup: (editor) => {
                    editorRef.value = editor;
                    editor.ui.registry.addButton('customUpload', {
                        icon: 'image',
                        tooltip: '插入媒体',
                        onAction: () => {
                            uploadBtn.value.click()
                        }
                    })
                    editor.on('init', (e) => initSetup(e));
                },
            };
        });

        const disabled = computed(() => {
            const { options } = props;
            const getdDisabled = options && Reflect.get(options, 'readonly');
            const editor = unref(editorRef);
            if (editor) {
                editor.setMode(getdDisabled ? 'readonly' : 'design');
            }
            return getdDisabled ?? false;
        });

        watch(
            () => attrs.disabled,
            () => {
                const editor = unref(editorRef);
                if (!editor) {
                    return;
                }
                editor.setMode(attrs.disabled ? 'readonly' : 'design');
            },
        );

        onMountedOrActivated(() => {
            if (!initOptions.value.inline) {
                tinymceId.value = buildShortUUID('tiny-vue');
            }
            nextTick(() => {
                setTimeout(() => {
                    initEditor();
                }, 30);
            });
        });

        onBeforeUnmount(() => {
            destory();
        });

        onDeactivated(() => {
            destory();
        });

        const uploadMedia = ({ file }) => {
            message.loading({
                content: '上传中...',
                duration: 0,
                key: 'loading'
            })
            const formData = new FormData()
            formData.append('file', file, file.name)
            defHttp.post('/platform/common/upload', formData).then(data => {
                if (data.Success) {
                    message.success({
                        content: '上传成功!',
                        duration: 2,
                        key: 'loading'
                    })
                    handleDone(file.name, data.Data[0])
                } else {
                    message.error({
                        content: data.Message,
                        duration: 2,
                        key: 'loading'
                    })
                }
            }).catch(err => {
                message.error({
                    content: '上传失败',
                    duration: 2,
                    key: 'loading'
                })
            }).finally(() => fileList.value = [])
        }

        function destory() {
            if (tinymce !== null) {
                tinymce?.remove?.(unref(initOptions).selector);
            }
        }

        function initEditor() {
            const el = unref(elRef);
            if (el) {
                el.style.visibility = '';
            }
            tinymce
                .init(unref(initOptions))
                .then((editor) => {
                    emit('inited', editor);
                })
                .catch((err) => {
                    emit('init-error', err);
                });
        }

        function initSetup(e) {
            const editor = unref(editorRef);
            if (!editor) {
                return;
            }
            const value = props.modelValue || '';

            editor.setContent(value);
            bindModelHandlers(editor);
            bindHandlers(e, attrs, unref(editorRef));
        }

        function setValue(editor, val, prevVal) {
            if (
                editor &&
                typeof val === 'string' &&
                val !== prevVal &&
                val !== editor.getContent({ format: attrs.outputFormat })
            ) {
                editor.setContent(val);
            }
        }

        function bindModelHandlers(editor) {
            const modelEvents = attrs.modelEvents ? attrs.modelEvents : null;
            const normalizedEvents = Array.isArray(modelEvents) ? modelEvents.join(' ') : modelEvents;

            watch(
                () => props.modelValue,
                (val, prevVal) => {
                    setValue(editor, val, prevVal);
                },
            );

            watch(
                () => props.value,
                (val, prevVal) => {
                    setValue(editor, val, prevVal);
                },
                {
                    immediate: true,
                },
            );

            editor.on(normalizedEvents ? normalizedEvents : 'change keyup undo redo', () => {
                const content = editor.getContent({ format: attrs.outputFormat });
                emit('update:modelValue', content);
                emit('change', content);
            });

            editor.on('FullscreenStateChanged', (e) => {
                fullscreen.value = e.state;
            });
        }

        function handleImageUploading(name) {
            const editor = unref(editorRef);
            if (!editor) {
                return;
            }
            editor.execCommand('mceInsertContent', false, getUploadingImgName(name));
            const content = editor?.getContent() ?? '';
            setValue(editor, content);
        }

        function handleDone(name, url) {
            const editor = unref(editorRef);
            if (!editor) {
                return;
            }
            //const content = editor?.getContent() ?? '';
            //const val = content?.replace(getUploadingImgName(name), `<img src="${url}"/>`) ?? '';
            setValue(editor, `<img src="${url}"/>`);
        }

        function getUploadingImgName(name) {
            return `[uploading:${name}]`;
        }

        return {
            containerWidth,
            initOptions,
            tinymceContent,
            elRef,
            tinymceId,
            handleImageUploading,
            handleDone,
            editorRef,
            fullscreen,
            disabled,
            uploadBtn,
            fileList,
            uploadMedia
        };
    },
})
</script>
<style scoped>
textarea {
    z-index: -1;
    visibility: hidden;
}
</style>
