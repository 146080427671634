import { ref, watch, unref, nextTick, onMounted, onActivated } from 'vue';
import { useThrottleFn, useDebounceFn } from '@vueuse/core';

export function wrapperEnv(envConf) {
    const ret = {};

    for (const envName of Object.keys(envConf)) {
        let realName = envConf[envName].replace(/\\n/g, '\n');
        realName = realName === 'true' ? true : realName === 'false' ? false : realName;

        if (envName === 'VITE_PORT') {
            realName = Number(realName);
        }
        if (envName === 'VITE_PROXY' && realName) {
            try {
                realName = JSON.parse(realName.replace(/'/g, '"'));
            } catch (error) {
                realName = '';
            }
        }
        ret[envName] = realName;
        // if (typeof realName === 'string') {
        //   process.env[envName] = realName;
        // } else if (typeof realName === 'object') {
        //   process.env[envName] = JSON.stringify(realName);
        // }
    }
    return ret;
}

export function onMountedOrActivated(hook) {
    let mounted = false;

    onMounted(() => {
        hook();
        nextTick(() => {
            mounted = true;
        });
    });

    onActivated(() => {
        if (mounted) {
            hook();
        }
    });
}

export function withInstall(component, alias) {
    component.install = (app) => {
        const compName = component.name || component.displayName;
        if (!compName) return;
        app.component(compName, component);
        if (alias) {
            app.config.globalProperties[alias] = component;
        }
    };
    return component
};

export function tryParseJson(str, type = 'object') {
    try {
        return JSON.parse(str)
    } catch {
        return type == 'object' ? {} : []
    }
}

export function arrayGroupBy(list, key, keyName, groupName) {
    var groupObj = list.reduce((obj, item) => {
        if (!obj[item[key]]) {
            obj[item[key]] = []
            obj[item[key]].push(item)
        } else {
            obj[item[key]].push(item)
        }
        return obj
    }, {});

    const array = [];

    for (let p in groupObj) {
        let obj = {};
        obj[keyName] = p
        obj[groupName] = groupObj[p]
        array.push(obj)
    }
    return array;
}

export function useEventListener({
    el = window,
    name,
    listener,
    options,
    autoRemove = true,
    isDebounce = true,
    wait = 80,
}) {
    let remove = () => {};
    const isAddRef = ref(false);

    if (el) {
        const element = ref(el);

        const handler = isDebounce ? useDebounceFn(listener, wait) : useThrottleFn(listener, wait);
        const realHandler = wait ? handler : listener;
        const removeEventListener = (e) => {
            isAddRef.value = true;
            e.removeEventListener(name, realHandler, options);
        };
        const addEventListener = (e) => e.addEventListener(name, realHandler, options);

        const removeWatch = watch(
            element,
            (v, _ov, cleanUp) => {
                if (v) {
                    !unref(isAddRef) && addEventListener(v);
                    cleanUp(() => {
                        autoRemove && removeEventListener(v);
                    });
                }
            }, { immediate: true },
        );

        remove = () => {
            removeEventListener(element.value);
            removeWatch();
        };
    }
    return { removeEvent: remove };
}

export function isvalidPhone(phone) {
    const isPhone = /^1[3|4|5|6|7|8|9][0-9]{9}$/
    if (isPhone.test(phone)) {
        return true
    } else {
        return false
    }
};


const utils = {
    randomRange: (min, max) => {
      return min + Math.round(Math.random() * (max - min))
    },
    // 使用指定key对数组去重, 不传key 则array按照简单数组处理
    uniqueArray: (array, key = '') => {
      if (!array || array.length == 0) {
        return array
      }
      if (key) {
        return array.reduce((prev, cur) => prev.map(a => a[key]).includes(cur[key]) ? prev : [...prev, cur], []);
      }
      return array.reduce((prev, cur) => prev.includes(cur) ? prev : [...prev, cur], []);
    },
    convertToJson: (json, type = 'object') => {
      try {
        let result = json ? JSON.parse(json) : (type == 'object' ? {} : [])
        if (typeof result === 'string') {
          return utils.convertToJson(result, type)
        }
        return result
      } catch (e) {
        return type == 'object' ? {} : []
      }
    },
    overflow: (value, max = 100) => {
      if (!value) {
        return ''
      }
      if (value.length > max) {
        value = value.substring(0, max) + '...'
      }
      return value
    },
    clearHtml: (html) => {
      if (!html) {
        return ''
      }
      return html.replace(/&nbsp;/g, " ")
        .replace(/<p\/?[^>]*>/g, "\r\n")
        .replace(/<div\/?[^>]*>/g, "\r\n")
        .replace(/<image\/?[^>]*>/g, "")
        .replace(/<video\/?[^>]*>/g, "")
        .replace(/<audio\/?[^>]*>/g, "")
        .replace(/<\/?[^>]*>/g, "")
        .replace(/^\s+|\s+$/g, '')
    },
    computeFileIcon: (file) => {
      if (commonRegex.image.test(file.Extension)) {
        return file.Url
      }
      const config = fileIconConfig.find(p => p.name.test(file.Extension))
      if (config) {
        return config.icon
      }
      return UNKONWNFILEICONURL
    },
    computeFileExt: (url) => {
      return url.substring(url.lastIndexOf("."), url.length);
    },
    computeFileName: (url) => {
      return url.substring(url.lastIndexOf("/") + 1, url.length);
    },
    regionDataToCascader: function (sources, regionJsonString) {
        const array = [];
        if (!regionJsonString) {
            return array
        }
        // 四个直辖市可能要单独处理
        const theFour = ["北京市", "上海市", "重庆市", "天津市"]
        try {
            const regionArray = JSON.parse(regionJsonString)
            if (regionArray.length == 0) {
                return array
            }
            if (regionArray[0] && regionArray[0].Name) {
  
                // 省
                let pdata = sources.find(p => p.name == regionArray[0].Name)
  
                if (pdata) {
                    array.push(pdata.code)
                    // 如果有四个直辖市
                    let inTheFour = theFour.findIndex(p => p == regionArray[0].Name) != -1
  
                    if (inTheFour) {
                        array.push(pdata.children[0].code)
  
                        if (regionArray[1] && regionArray[1].Name) {
  
                            let cname = regionArray[2] && regionArray[2].Name
                                ? regionArray[2].Name : ''
                            // 区域
                            let adata = pdata.children[0]
                                .children.find(p => p.name == regionArray[1].Name || p.name == cname)
                            if (adata) {
                                array.push(adata.code)
                            }
                        }
  
                    } else {
                        // 市
                        if (regionArray[1] && regionArray[1].Name) {
                            let cdata = pdata.children.find(p => p.name == regionArray[1].Name)
                            if (cdata) {
                                array.push(cdata.code)
                                // 区
                                if (regionArray[2] && regionArray[2].Name) {
                                    let adata = cdata.children
                                        .find(p => p.name == regionArray[2].Name)
                                    if (adata) {
                                        array.push(adata.code)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return array
        } catch (e) {
            console.log(e)
            return array
        }
    },
    cascaderToRegionData: function (sources, cascaderArray) {
        const regionData = [];
        let pCode = cascaderArray[0];
  
        if (pCode) {
  
            const p = sources.find(p => p.code == pCode);
  
            regionData.push({
                Code: p.code,
                Name: p.name
            });
  
            if (p.children && p.children.length > 0) {
  
                let cCode = cascaderArray[1];
  
                if (cCode) {
  
                    const c = p.children.find(p => p.code == cCode);
  
                    regionData.push({
                        Code: c.code,
                        Name: c.name
                    });
  
                    if (c.children && c.children.length > 0) {
  
                        let aCode = cascaderArray[2];
  
                        if (aCode) {
  
                            const a = c.children.find(p => p.code == aCode);
  
                            regionData.push({
                                Code: a.code,
                                Name: a.name
                            });
                        }
                    }
                }
            }
        }
        return regionData;
    },
  
    /**
    * 文件链接转文件流下载--主要针对pdf 解决谷歌浏览器a标签下载pdf直接打开的问题
    * @param url  ：文件链接
    * @param fileName  ：文件名;
    * @param type  ：文件类型;
    */
    fileLinkToStreamDownload: function (url, fileName, type, fn) {
      // console.log(url, fileName, type,fn);
      
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      // console.log(reg.test(url));
      let xhr = new XMLHttpRequest();
      xhr.open('get', url, true);
      xhr.setRequestHeader('Content-Type', `application/${type}`);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //接受二进制文件流
          var blob = this.response;
          downloadExportFile(blob, fileName, type, fn)
        }
      }
      // xhr.onprogress = function (e) {
      //   //文件下载进度
      //   if (fn && typeof fn == 'function') {
      //     fn(e); //监听文件下载进度;
      //   }
      // }
  
      xhr.onerror  = function () {
        downloadByUrl({
          url: url,
          target: '_blank',
        });
        if (fn && typeof fn == 'function') {
          fn();
        }
      }
      xhr.send();
      
      // if (!reg.test(url)) {
      //   downloadByUrl({
      //     url: url,
      //     target: '_blank',
      //   });
      //   throw new Error("传入参数不合法,不是标准的文件链接");
      // } else {
      //   let xhr = new XMLHttpRequest();
      //   xhr.open('get', url, true);
      //   xhr.setRequestHeader('Content-Type', `application/${type}`);
      //   xhr.responseType = "blob";
      //   xhr.onload = function () {
      //     if (this.status == 200) {
      //       //接受二进制文件流
      //       var blob = this.response;
      //       downloadExportFile(blob, fileName, type,fn)
      //     }
      //   }
      //   // xhr.onprogress = function (e) {
      //   //   //文件下载进度
      //   //   if (fn && typeof fn == 'function') {
      //   //     fn(e); //监听文件下载进度;
      //   //   }
      //   // }
  
      //   xhr.onerror  = function () {
      //     downloadByUrl({
      //       url: url,
      //       target: '_blank',
      //     });
      //     if (fn && typeof fn == 'function') {
      //       fn();
      //     }
      //   }
      //   xhr.send();
      // }
    }
  
  
  
  }
  
export default utils
  