<template>
  <div class="exam">
    <a-spin :spinning="spinning">
      <div class="examHeaderBlock">
        <div class="examHeader">
          <div class="examHeaderCore">
            <div class="examName">{{ detaiInfo.Title }}</div>
            <div class="flex">
              <a-button class="submitBtn" type="primary" size="large" @click="handleClose">关闭</a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="examContent">
        <div class="circumstances">
          <div class="examinationTime">
            <div class="progressCircle">
              <a-progress type="circle" :percent="detaiInfo.Accuracy * 100" :width="102" strokeColor="#28C445">
                <template #format="percent">
                  <div style="color:#28C445;">{{ percent }}%</div>
                  <div class="percentText">正确率</div>
                </template>
              </a-progress>
            </div>
          </div>
          <div class="excuseList">
            <div class="subjectList">
              <div class="subjectType">答题卡:</div>
              <div class="subjectBlock">
                <div class="subjectItem"
                  :class="{ exactness: item.IsExactness, mark: !item.IsExactness && item.StudentAnswersId != null, notDone: item.StudentAnswersId == null }"
                  v-for="(item, index) in detaiInfo.Questions" :key="index">{{ index + 1 }}</div>
              </div>
            </div>
            <div class="subjectTypeTips">
              <div class="tipsItem">
                <div class="TipsColor exactness"></div>
                <div class="tipsType">正确</div>
              </div>
              <div class="tipsItem">
                <div class="TipsColor mark"></div>
                <div class="tipsType">错误</div>
              </div>
              <div class="tipsItem">
                <div class="TipsColor notDone"></div>
                <div class="tipsType">未做</div>
              </div>
            </div>
          </div>
        </div>
        <div class="answerArea">
          <div class="answerAreaList" v-for="(item, index) in detaiInfo.Questions" :key="index">
            <div class="title">
              <span>{{ index + 1 }}.（{{ computeType(item.Type) }}）{{ item.TheContent }}</span>
              <span class="fraction">（{{ item.QuestionPoints }}分）</span>
            </div>
            <div v-if="item.Type == 4">
              <div class="appendix">
                <img class="appendixImg" src="../../assets/image/appendixImg.png" alt="">
                <div class="attachmentsList">
                  <div class="attachmentsItem" v-for="(itm, i) in item.AnswersAttachments" :key="i">
                    <div class="attachmentsName">{{ itm.name }}</div>
                    <a class="downloadBtn" :href="itm.url" :download="itm.name">下载</a>
                  </div>
                </div>
              </div>
              <div class="appendix aj-margin-top">
                <span>我的答案：</span>
                <div class="attachmentsList">
                  <div class="attachmentsItem" v-for="(itm, i) in item.Attachments" :key="i">
                    <div class="attachmentsName">{{ itm.name }}</div>
                    <a class="downloadBtn" :href="itm.url" :download="itm.name">下载</a>
                  </div>
                </div>
              </div>
              <div class="aj-padding-top">得分：{{ item.Score }}</div>
              <div class="aj-padding-top">
                <div>评价</div>
                <a-table class="aj-margin-top" :data-source="item.AppraiseList">
                  <a-table-column data-index="id" title="序号">
                    <template #default="{ record, index }">
                      <span>{{ index + 1 }}</span>
                    </template>
                  </a-table-column>
                  <a-table-column data-index="Images" title="截图">
                    <template #default="{ record }">
                      <a-space v-if="record.Images">
                        <a-image v-for="(p,i) in JSON.parse(record.Images)" :width="40" :src="p.url" />
                      </a-space>
                    </template>
                  </a-table-column>
                  <a-table-column data-index="Content" title="评价"></a-table-column>
                  <a-table-column data-index="DeductPoints" title="扣分"></a-table-column>
                  <a-table-column data-index="resumptionVideo" title="复学课件">
                    <template #default="{ record }">
                      <div class="videoUrl" v-for="(itm, i) in JSON.parse(record.resumptionVideo)" :key="i"  @click="handleSeeVideo(itm.VideoUrl)">{{ computeVideoName(itm.VideoUrl) }}</div>
                    </template>
                  </a-table-column>
                </a-table>
              </div>
            </div>
            <div class="questions">
              <!-- 单选题 -->
              <div class="groupBlock" v-if="item.Type == 0">
                <div class="optionList" v-for="(itm, i) in item.OptionsList" :key="i">
                  <div class="aj-flex" v-if="item.IsExactness">
                    <CheckCircleTwoTone v-if="itm.Id == item.CorrectAnswerId" two-tone-color="#28C445"
                      style="font-size:18px;" /> <!-- 正确 -->
                    <div class="initialRadio" v-if="itm.Id != item.CorrectAnswerId"></div> <!-- 默认 -->
                  </div>
                  <div class="aj-flex" v-if="!item.IsExactness">
                    <CheckCircleTwoTone v-if="itm.Id == item.CorrectAnswerId" two-tone-color="#28C445"
                      style="font-size:18px;" /> <!-- 正确 -->
                    <CheckCircleTwoTone v-if="itm.Id == item.StudentAnswersId" two-tone-color="#0960bd"
                      style="font-size:18px;" /> <!-- 错误 -->
                    <div class="initialRadio" v-if="itm.Id != item.CorrectAnswerId && itm.Id != item.StudentAnswersId"></div>
                    <!-- 默认 -->
                  </div>
                  <span class="optionName">{{ itm.TheContent }}</span>
                </div>
              </div>
              <!-- 多选题 -->
              <div class="groupBlock" v-if="item.Type == 1 || item.Type == 2">
                <div class="optionList" v-for="(itm, i) in item.OptionsList" :key="i">
                  <div class="aj-flex" v-if="item.IsExactness">
                    <CheckSquareTwoTone v-if="computeShow(itm.Id, item.CorrectAnswerId, item.StudentAnswersId, 4)"
                      two-tone-color="#28C445" style="font-size:18px;" /> <!-- 正确 -->
                    <div class="optionCheck" v-if="computeShow(itm.Id, item.CorrectAnswerId, item.StudentAnswersId, 5)">
                    </div> <!-- 默认 -->
                  </div>
                  <div class="aj-flex" v-else>
                    <CheckSquareTwoTone v-if="computeShow(itm.Id, item.CorrectAnswerId, item.StudentAnswersId, 1)"
                      two-tone-color="#28C445" style="font-size:18px;" /> <!-- 正确 -->
                    <CheckSquareTwoTone v-if="computeShow(itm.Id, item.CorrectAnswerId, item.StudentAnswersId, 2)"
                      two-tone-color="#0960bd" style="font-size:18px;" /> <!-- 错误 -->
                    <div class="optionCheck" v-if="computeShow(itm.Id, item.CorrectAnswerId, item.StudentAnswersId, 3)">
                    </div> <!-- 默认 -->
                  </div>
                  <span class="optionName">{{ itm.TheContent }}</span>
                </div>
              </div>
              <!-- 判断题 -->
              <div class="groupBlock" v-if="item.Type == 3">
                <div class="optionList" v-for="(itm, i) in item.OptionsList" :key="i">
                  <div class="aj-flex" v-if="item.IsExactness">
                    <CheckCircleTwoTone v-if="itm.Id == item.CorrectAnswerId" two-tone-color="#28C445"
                      style="font-size:18px;" /> <!-- 正确 -->
                    <div class="initialRadio" v-if="itm.Id != item.CorrectAnswerId"></div> <!-- 默认 -->
                  </div>
                  <div class="aj-flex" v-if="!item.IsExactness">
                    <CheckCircleTwoTone v-if="itm.Id == item.CorrectAnswerId" two-tone-color="#28C445"
                      style="font-size:18px;" /> <!-- 正确 -->
                    <CheckCircleTwoTone v-if="itm.Id == item.StudentAnswersId" two-tone-color="#0960bd"
                      style="font-size:18px;" /> <!-- 错误 -->
                    <div class="initialRadio" v-if="itm.Id != item.CorrectAnswerId && itm.Id != item.StudentAnswersId"></div>
                    <!-- 默认 -->
                  </div>
                  <span class="optionName">{{ itm.TheContent }}</span>
                </div>
              </div>
              <div class="analysis" v-if="item.Type != 4">
                <div>解析:{{ item.Analysis }}</div>
                <div class="aj-padding-top-sm">知识点:{{ item.knowledgePoints }}</div>
                <div class="aj-padding-top-sm aj-flex">
                  <div>复学课件:</div>
                  <div>
                    <div class="videoUrl" v-for="(itm, i) in item.resumptionVideo" :key="i"
                      @click="handleSeeVideo(itm.VideoUrl)">{{ computeVideoName(itm.VideoUrl) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

    <a-modal v-model:visible="videoVisible" width="50%" title="复学视频" :footer="null" destroyOnClose :maskClosable="false">
      <div class="videoStyle">
        <video :src="videoUrl" controls style="width:100%;height:100%;"></video>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { StarFilled, CheckCircleTwoTone, CheckSquareTwoTone } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    StarFilled,
    CheckCircleTwoTone,
    CheckSquareTwoTone
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const value = ref(1)
    const typeList = ref([])
    let id = route.query.id
    const spinning = ref(false)
    const detaiInfo = ref({})
    const videoVisible = ref(false)
    const videoUrl = ref('')

    const radioStyle = reactive({
      display: 'flex',
      paddingTop:'15px',
      paddingBottom:'15px',
    });

    onMounted(() => {
      getTypeList()
    })

    const computeVideoName = computed(() => {
      return (val) => {
        if (val) {
          var array = decodeURIComponent(val).split('/');
          var b = array[array.length - 1];
          return b
        }
      }
    })

    const computeShow = computed(() => {
      return (val, seleVal, stuVal, type) => {
        if (stuVal) {
          if (type == 1) {
            let index = seleVal.findIndex(p => p == val)
            let i = stuVal.findIndex(p => p == val)
            if (index >= 0 && i < 0) {
              return true
            } else {
              return false
            }
          } else if (type == 2) {
            let index = stuVal.findIndex(p => p == val)
            if (index >= 0) {
              return true
            } else {
              return false
            }
          } else if (type == 3) {
            let index = stuVal.findIndex(p => p == val)
            let i = seleVal.findIndex(p => p == val)
            if (index < 0 && i < 0) {
              return true
            } else {
              return false
            }
          } else if (type == 4) {
            let index = seleVal.findIndex(p => p == val)
            if (index >= 0) {
              return true
            } else {
              return false
            }
          } else if (type == 5) {
            let index = seleVal.findIndex(p => p == val)
            if (index < 0) {
              return true
            } else {
              return false
            }
          }
        } else {
          if (type == 3) {
            let index = seleVal.findIndex(p => p == val)
            if (index >= 0) {
              return false
            } else {
              return true
            }
          } else if (type == 1) {
            let index = seleVal.findIndex(p => p == val)
            if (index >= 0) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        }
      }
    })

    const getTypeList = () => {
      defHttp.get('platform/Common/Enum', { params: { name: 'QuestionType' } }).then(data => {
        if (data.Success) {
          typeList.value = data.Data
          getAllList()
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const getAllList = () => {
      spinning.value = true
      defHttp.get('/student/examination/paperDetail', { params: { paperId: id } }).then(data => {
        spinning.value = false
        if (data.Success) {
          detaiInfo.value = data.Data
          detaiInfo.value.Questions.forEach(item => {
            if (item.Attachments) {
              item.Attachments = JSON.parse(item.Attachments)
            }
            if (item.AnswersAttachments) {
              item.AnswersAttachments = JSON.parse(item.AnswersAttachments)
            }
            if (item.CorrectAnswerId) {
              item.CorrectAnswerId = JSON.parse(item.CorrectAnswerId)
            }
            if (item.StudentAnswersId) {
              item.StudentAnswersId = JSON.parse(item.StudentAnswersId)
            }
            if (item.resumptionVideo) {
              item.resumptionVideo = JSON.parse(item.resumptionVideo)
            }
          })
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { spinning.value = false })
    }

    const computeType = (val) => {
      if (val != undefined) {
        return typeList.value.find(p => p.id == val).name
      }
    }

    const handleClose = () => {
      router.push({
        path: '/personal', query: {
          tab: route.query.tab
        }
      })
    }

    const handleSeeVideo = (url) => {
      videoUrl.value = url
      videoVisible.value = true
    }

    return {
      value,
      radioStyle,
      getAllList,
      typeList,
      spinning,
      detaiInfo,
      handleClose,
      computeType,
      computeShow,
      computeVideoName,
      handleSeeVideo,
      videoUrl,
      videoVisible
    };
  },
});
</script>
<style scoped>
.exam {
  height: 100%;
  background-color: #FFFFFF;
}

.examHeaderBlock {
  width: 100%;
  height: 88px;
  background: #FFFFFF;
}

.examHeader {
  width: 100%;
  height: 84px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
}

.examHeaderCore {
  width: 62.5%;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.examName span {
  color: #666666;
}

.examName {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
}

.submitBtn {
  margin: auto 0 auto 20px;
}

:deep(.submitBtn, .ant-btn-lg) {
  padding: 6.4px 30px !important;
}

.examContent {
  background-color: #FFFFFF;
  padding-top: 50px;
  width: 62.5%;
  min-width: 1200px;
  /* margin: 6px auto 0 auto; */
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.circumstances {
  width: 23%;
  height: 652px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.answerArea {
  width: 75.5%;
  max-height: 707px;
  overflow-y: scroll;
  background-color: #FFFFFF;
  padding: 0 50px;
  box-sizing: border-box;
  position: relative;
}

.answerAreaList {
  margin-bottom: 20px;
}

.examinationTime {
  padding: 21px 30px 19px 30px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
}

.progressCircle {
  text-align: center;
  padding: 20px 0;
}

.progressTitle {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
}

.progress {
  padding-top: 15px;
  font-size: 20px;
  font-weight: 700;
  color: #55ABFF;
  line-height: 23px;
}

.progress span {
  font-size: 16px;
  color: #666666;
}

.progressBar {
  margin-top: 3px;
}

.excuseList {
  flex: 1;
  padding: 20px 15px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subjectType {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}

.subjectBlock {
  display: flex;
  flex-wrap: wrap;
}

.subjectItem {
  margin: 10px 15px 0 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.accomplish {
  background: #55ABFF;
  border: 1px solid #55ABFF;
  color: #FFFFFF;
}

.notDone {
  background-color: #FFFFFF !important;
  border: 1px solid #CCCCCC !important;
  color: #333333 !important;
}

.mark {
  background: #0960bd !important;
  border: 1px solid #0960bd !important;
  color: #FFFFFF !important;
}

.exactness {
  background: #28C445;
  border: 1px solid #28C445;
  color: #FFFFFF;
}

.subjectTypeTips {
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
}

.tipsItem {
  display: flex;
}

.TipsColor {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.tipsType {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

.title {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}

.fraction {
  color: #999999;
}

.markBtn {
  margin-left: 4px;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #999999;
  text-align: center;
  line-height: 30px;
}

.NextQuestion {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  text-align: center;
}

:deep(.NextQuestion .ant-btn) {
  padding: 4px 150px !important;
}

.questions {
  max-height: 500px;
  overflow-y: scroll;
}

.questions::-webkit-scrollbar {
  width: 0;
}

:deep(.questions .ant-radio-wrapper-checked span) {
  color: var(--theme) !important;
}

.activeMark {
  border: 1px solid var(--theme);
  color: var(--theme) !important;
}

.analysis {
  margin-top: 14px;
  padding: 12px 14px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #CCCCCC;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}

.initialRadio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #666666;
}

.optionCheck {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #666666;
}

.optionList {
  display: flex;
  padding-top: 30px;
}

.optionName {
  padding-left: 26px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}

.appendix {
  display: flex;
  margin-top: 8px;
}

.appendixImg {
  width: 50px;
  height: 50px;
}

.attachmentsList {
  margin-left: 8px;
}

.attachmentsItem {
  display: flex;
  margin-bottom: 12px;
}

.downloadBtn {
  color: #02A7F0;
  margin-left: 8px;
}

.percentText {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 14px
}

.videoUrl {
  color: #55ABFF;
  cursor: pointer;
}

.videoStyle {
  width: 100%;
  height: 605px;
}</style>
