<template>
  <div class="navBlock">
    <div class="navTab">
      <div class="navContent">
        <div class="navList" style="position: relative;">
          <a-tabs v-model:activeKey="activeKey" class="tabStyle" centered size="large" :tabBarGutter="40" @tabClick="handleJump">
            <a-tab-pane :key="item.id" :tab="item.name" v-for="(item,index) in navList"></a-tab-pane>
          </a-tabs>
          <img v-if="schoolLogo"  :src="schoolLogo" alt="" style="height: 50px;position: absolute;right: -32px;top: 50%;transform: translate(100%,-50%);">
        </div>  
        <div class="other">
          <a-input v-model:value="searchValue" size="large" class="searchInputBlock" placeholder="搜索感兴趣的课程内容">
            <template #suffix>
              <search-outlined @click="onSearch" />
            </template>
          </a-input>
          <a-popover v-model:visible="menuVisible" trigger="click">
            <template #content>
              <div class="menuList">
                <div class="menuItem" @click="handleContent('/notice')">
                  <img class="menuIcon" src="../assets/image/menuIcon.png" alt="">
                  <div class="menuName">通知</div>
                </div>
                <div class="menuItem" @click="handleContent('/base')">
                  <img class="menuIcon" src="../assets/image/menuIcon1.png" alt="">
                  <div class="menuName">基础资料</div>
                </div>
                <div class="menuItem" @click="handleContent('/feedback')">
                  <img class="menuIcon" src="../assets/image/menuIcon2.png" alt="">
                  <div class="menuName">意见反馈</div>
                </div>
                <div class="menuItem" @click="logOut">
                  <img class="menuIcon" src="../assets/image/menuIcon3.png" alt="">
                  <div class="menuName">退出登录</div>
                </div>
              </div>
            </template>
            <img class="userHeader" v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
            <img class="userHeader" v-else src="../assets/image/defaultHaderImg.png" alt="">
          </a-popover>
        </div>
      </div>
    </div>
    <div class="pageContent">
      <router-view v-slot="{ Component }">
          <keep-alive :include="include">
            <component :is="Component" :key="$route.fullPath"/>
          </keep-alive>
      </router-view>
    </div>
    <a-drawer
      width="380"
      :placement="placement"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <template #title>
        <div style="box-sizing: border-box;width: 100%;display: flex;align-items: center;justify-content: space-between;">
          <img src="../assets/image/ai.png" style="height: 26px;"/>
          <CloseOutlined @click="onClose" />
        </div>
      </template>
      <div style="width:100%;height: 100%;display: flex;flex-direction: column;">
        <div style="width:100%;flex: 1;overflow-y: auto;padding-bottom: 24px;box-sizing: border-box;background-color: #EAF0F9;">

          <div v-for="(item,index) in messageList" :key="index">
            <div class="query" style="width: 100%;display: flex;justify-content: flex-end;margin-bottom: 12px;padding: 10px;box-sizing: border-box;">
              <div style="background: #C1DBFF;padding: 10px;box-sizing: border-box;border-radius: 12px 0 12px 12px;">{{ item.query }}</div>
            </div>
            <div class="answer" style="width: 100%;display: flex;margin-bottom: 12px;padding: 10px;box-sizing: border-box;">
              <div style="background: #fff;padding: 10px;box-sizing: border-box;border-radius: 0 12px 12px 12px;">{{ item.answer }}</div>
            </div>
          </div>
        </div>
        <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;padding: 12px 0;box-sizing: border-box;">
          <a-textarea v-model:value="messageContent" placeholder="请输入您的问题描述" :auto-size="{ minRows: 1, maxRows: 5 }" />
          <a-button type="primary" style="margin-left: 12px;" @click="sendMessage" :loading="messageLoading">发送</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,watch} from 'vue';
import {useRouter} from 'vue-router'
import defHttp from "@/utils/http.js";
import { CloseOutlined,SearchOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
export default defineComponent({
  components:{
    CloseOutlined,
    SearchOutlined
  },
  setup() {
    const router=useRouter()
    const userName=ref('')
    const activeKey = ref(1);
    const searchValue=ref('')
    const menuVisible=ref(false)
    const userInfo=ref({})
    const UnreadNum=ref(true)
    const schoolBadge=ref('')
    const navList=ref([
      {name:'课程学习',id:1,link:'/course'},
      {name:'学习计划',id:2,link:'/learningPlan'},
      {name:'实操训练',id:3,link:'/practice'},
      {name:'技能测评',id:4,link:'/evaluation'},
      // {name:'工单管理',id:6,link:'/workOrder'},
      {name:'个人中心',id:5,link:'/personal'},
      {name:'作品库',id:6,link:'/works'},
    ])
    watch(() =>router.currentRoute.value.path,(newValue,oldValue)=> {
      if(newValue=='/courseDetail'){
        activeKey.value=1
      }
    },{ immediate: true })

    let schoolLogo = ref('')
    const getschoolinfo = () => {
      defHttp.get('/trainer/School/schoolinfo?id=' + userInfo.value.SchoolId).then(data => {
        if (data.Success) {
          schoolLogo.value = data.Data.Logo
        } 
      })
    }


    onMounted(() => {
      if(!localStorage.getItem('token-smartvg')){
        router.push('/login')
        return
      }
      getBaseInfo()
      let link=router.currentRoute.value.fullPath
      let index=navList.value.findIndex(p=>p.link==link)
      if(index>=0){
        activeKey.value=navList.value.find(p=>p.link==link).id
      }else{
        activeKey.value=0
      }
      getNoticeInfo()
      if(localStorage.getItem('schoolBadge')){
        schoolBadge.value=localStorage.getItem('schoolBadge')
      }else{
        schoolBadge.value=''
      }
    })

    //侧边抽屉
      const placement = ref('right');
      const messageContent = ref('');
      const visible = ref(false);
      const messageLoading = ref(false);
      const sendmessageList = ref([]);
      const replymessageList = ref([]);
      const messageList = ref([]);

      const sendMessage = () => {
        if (messageContent.value == ''){
          createMessage.error('请先输入问题描述',1);
          return 
        }
        messageList.value.push({
          query:messageContent.value,
          answer:''
        })
        messageLoading.value = true
        defHttp.get(`/platform/Common/AIRobot?query=${messageContent.value}`).then(res=>{
          messageLoading.value = false
          if (res.Data.code == 0) {
            if (res.Data.obj) {
              messageList.value[messageList.value.length - 1].answer = res.Data.obj.reply
            } else {
              messageList.value[messageList.value.length - 1].answer = "暂时不知道您问的问题！"
            }
            messageContent.value = ''
          } else {
            createMessage.error('暂时无法提供此服务,请稍后再试',2);
            messageList.value.pop()
          }
        }).catch(() => {
          messageLoading.value = false
          messageList.value.pop()
        })

      };
      const showDrawer = () => {
        visible.value = true;
        messageLoading.value = false;
        messageContent.value = ''
      };
      const onClose = () => {
        visible.value = false;
      };


    const onSearch = () => {
      console.log("searchValue",searchValue.value)
      router.push({path:'/courseAll',query:{search:searchValue.value}})
    };

    const handleJump=(e)=>{
      let url=navList.value.find(p=>p.id==e).link
      router.push(url)
    }

    const handleContent=(url)=>{
      activeKey.value=0
      router.push(url)
      menuVisible.value=false
    }

    const logOut=()=>{
      localStorage.removeItem('token-smartvg')
      router.push('/login')
    }

    const getBaseInfo=()=>{
      defHttp.get('/student/account/userInfo').then(data=>{
        if(data.Success){
          userInfo.value=data.Data
          localStorage.setItem('userInfo',JSON.stringify(userInfo.value))
          getschoolinfo()
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const getNoticeInfo=()=>{
      defHttp.get('/system/message/dots').then(data=>{
        if(data.Success){
          if(data.Data.length){
            let index=data.Data.findIndex(p=>p.Count>0)
            if(index>=0){
              UnreadNum.value=true
            }else{
              UnreadNum.value=false
            }
          }else{
            UnreadNum.value=false
          }
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }
    let include = ref(['practice'])

    return {
      include,
      activeKey,
      searchValue,
      onSearch,
      userName,
      navList,
      handleJump,
      handleContent,
      menuVisible,
      logOut,
      userInfo,
      UnreadNum,
      schoolBadge,
      
      showDrawer,
      onClose,
      visible,
      placement,
      messageContent,
      messageLoading,
      sendMessage,
      replymessageList,
      sendmessageList,
      messageList,
      schoolLogo
    };
  },
})
</script>
<style scoped>
.navBlock{
  width: 100%;
  height: 100%;
}
.navTab{
  width: 100%;
  background-color: #fff;
  height: 70px;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.pageContent{
  padding-top: 70px;
  height:100%;
}
.navContent{
  width: 70%;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.lofoFlex{
  display: flex;
  align-items: center;
}
.tabLeftBlock{
  margin-top: 20px;
}
.logoBlock{
  /* width: 47px; */
  height: 31px;
  margin: auto 0;
}
.terrace{
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.terraceType{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--theme);
  margin-left: 10px;
}
.terraceName{
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
}
:deep(.tabStyle .ant-tabs-tab){
  padding: 0 !important;
  line-height: 70px;
  color: #545C63;
}
:deep(.tabStyle .ant-tabs-nav::before){
  border-bottom: 0 !important;
}
:deep(.tabStyle .ant-tabs-ink-bar){
  height: 0px !important;
}
.other{
  display: flex;
  margin: auto 0;
}
.noticeBlock{
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: block;
  margin:auto 26px auto 35px;
  position: relative;
  cursor: pointer;
}
.noticeImg{
  width: 100%;
  height: 100%;
}
.badge{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #E34D4E;
  position: absolute;
  top: 0;
  right: 2px;
}
.userHeader{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: auto 0;
  cursor:pointer;
}
.searchInputBlock{
  margin-right: 16px;
  background-color: #f3f5f6;
  border-radius: 4px;
  border-color: #f3f5f6;
}
:deep(.searchInputBlock .ant-input-lg){
  font-size: 14px !important;
}
:deep(.searchInputBlock .ant-input){
  background-color: #f3f5f6 !important;
}
.menuList{
  width: 80px !important;
  padding: 4px 0;
}
.menuItem{
  display: flex;
  margin-bottom: 10px !important;
  cursor: pointer;
}
.menuItem:last-child{
  margin-bottom: 0 !important;
}
.menuIcon{
  width: 20px !important;
  height: 20px !important;
}
.menuName{
  font-weight: 400;
  font-size: 12px !important;
  margin-left: 12px;
}
</style>
