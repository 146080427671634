<template>
  <div class="container">
    <img class="studyPlanCover" src="../../assets/image/studyPlan.jpg" alt="">
    <div class="courseCore">
      <div class="studyPlanList">
        <div class="studyPlanItem" v-for="(item,index) in directionList" :key="index" @click="handleStudyPlan(item.id)">
          <img class="cover" v-if="item.imgUrl" :src="item.imgUrl" alt="">
          <div v-else class="coverBlock">暂无图片</div>
          <div class="studyPlanName">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,} from 'vue';
import defHttp from "@/utils/http.js";
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const directionList=ref([])
    const router = useRouter()
    onMounted(() => {
      getDirectionList()
    })

    const getDirectionList=()=>{
      defHttp.get('/student/direction').then(data=>{
        directionList.value=data.Data
      }).catch(err => {})
    }

    const handleStudyPlan=(id)=>{
      router.push({ path: 'studyPlan', query: { id: id } })
    }
    
    return {
      getDirectionList,
      directionList,
      handleStudyPlan
      
    };
  },
});
</script>
<style scoped>
.container{
  height: 100%;
  background-color: #fff;
}
.studyPlanCover{
  width: 100%;
}
.courseCore {
  width: 62.5%;
  margin: 0 auto;
  padding: 20px 0;
}

.studyPlanList{
  display: flex;
  flex-wrap: wrap;
  margin-left: -22px;
  margin-right: -22px;
}
.studyPlanItem{
  width: 268px;
  margin-top: 17px;
  margin-left: 20px;
  margin-right: 20px;
}
.cover{
  width: 268px;
  height: 140px;
  border-radius: 10px;
}
.coverBlock{
  width: 268px;
  height: 140px;
  background-color: #f2f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.studyPlanName{
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
</style>