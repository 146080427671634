<template>
  <div class="login-container">
    <div class="left" >
      <img src="http://adzjgc.tumengkj.com/images/loginAd.png" alt="" style="width: 100%;height:100%;">
    </div>
    <div  class="right" >
      <div class="login-form">
        <div class="login-header">
          <div class="loginType" :class="isTeacherLogin ? 'correct' : ''" @click="toggle(true)">教师登录</div>
          <div class="loginType" :class="isTeacherLogin ? '' : 'correct'" @click="toggle(false)">学生登录</div>
        </div>
        <div class="gap1"></div>
        <div class="login-body">
          <a-form :model="formData" :rules="rules" ref="formIns" :layout="'vertical'">
            <a-form-item label="账号" name="account">
              <a-input class="ipt" v-model:value="formData.account" placeholder="请输入账号/手机号" />
            </a-form-item>
            <a-form-item label="密码" name="password">
              <a-input-password class="ipt" v-model:value="formData.password" placeholder="请输入密码" />
            </a-form-item>
            <a-form-item label="验证码" name="code" style="position: relative;">
              <div class="aj-flex">
                <a-input class="ipt" v-model:value="formData.code" style="flex:1" autocomplete="off" placeholder="请输入验证码" />
                <a-spin :spinning="refreshCode">
                  <a-image style="height: 48px;border: 1px solid #d9d9d9;border-left:0;" @click="getcode" :src="codeImg" alt="" :preview="false" placeholder></a-image>
                </a-spin>
              </div>
            </a-form-item>
          </a-form>
          <a-button class="loginbtn" type="primary" @click="toLogin" :loading="spinning">登录</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { LockOutlined, SafetyOutlined, UserDeleteOutlined } from '@ant-design/icons-vue';
import { buildShortUUID } from '@/utils/uuid.js'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    UserDeleteOutlined,
    LockOutlined,
    SafetyOutlined
  },
  setup() {
    const router = useRouter()
    const refreshCode = ref(false);
    const isTeacherLogin = ref(true)
    const autoLogin = ref(false)
    const spinning = ref(false)
    const formIns = ref()
    const logoUrl=ref('')
    const BgCover=ref('')
    const codeImg = ref('http://adzjgc.tumengkj.com/platform/common/GetImgSecruityCode');
    const formData = ref({
      account: '',
      password: '',
      code: '',
    });
    const rules = {
      account: [{ required: true, trigger: 'blur', message: '该项必须填写', type: 'string' }],
      password: [{ required: true, trigger: 'blur', message: '该项必须填写', type: 'string' }],
      code: [{ required: true, trigger: 'blur', message: '该项必须填写', type: 'string' }],
    }

    onMounted(() => {
      localStorage.clear()
      getSysLogo()
      getcode()
    })

    const getSysLogo=()=>{
      defHttp.get('/system/config/SchoolInfo').then(data => {
        if (data.Success && data.Data) {
          logoUrl.value=data.Data.Logo2
          BgCover.value=data.Data.BgCover
          localStorage.setItem('schoolBadge',data.Data.Logo)
        } else if (!data.Success) {
          message.error(`${data.Message}`)
        }
      })
    }

    const toggle = (type) => {
      isTeacherLogin.value = type
    }

    const getcode = () => {
      refreshCode.value=true
      setTimeout(()=>{
        codeImg.value = `http://adzjgc.tumengkj.com/platform/common/GetImgSecruityCode?t=` + buildShortUUID()
        refreshCode.value=false
      },100)
    }

    const toLogin = () => {
      spinning.value = true
      formIns.value.validate().then(() => {
        spinning.value = true
        let param = {
          account: formData.value.account,
          password: formData.value.password,
          code: formData.value.code,
        }
        const url = isTeacherLogin.value ? 'system/account/login' : 'customer/Account/Login'
        defHttp.post(url, param).then(data => {
          spinning.value = false
          if (data.Success) {
            localStorage.setItem('token-smartvg', data.Data.token)
            localStorage.setItem('tokenType', isTeacherLogin.value ? 'teacher' : 'student')
            message.success('登录成功')
            setTimeout(() => {
              isTeacherLogin.value ? router.push('/systemSelection') : router.push('/')
            }, 2000)
          } else {
            message.error(`${data.Message}`)
          }
        }).catch(err => { spinning.value = false })
      }).catch(() => { spinning.value = false })
    }

    return {
      isTeacherLogin,
      toggle,
      formData,
      rules,
      autoLogin,
      refreshCode,
      codeImg,
      getcode,
      toLogin,
      spinning,
      formIns,
      logoUrl,
      BgCover
    };
  },
})
</script>
<style lang="less" scoped>
  .login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #fff;
    .left {
      width: 50%;
      height: 100%;
      background-color: #000;
      position: relative;
    }
    .right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .login-form {
        width: 80%;
        height: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        color: #2F3033;
        .title {
          color: #000;
          font-family: PingFang SC;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
        }
      
        .forgetpwd {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          margin-top: -8px;
        }
        .ipt {
          height: 48px;
          padding: 0 16px;
          box-sizing: border-box;
        }
        .loginbtn {
          width: 100%;
          border-radius: 8px;
          height: 48px;
          font-size: 16px;
          margin-top: 40px;
        }
        .xieyi {
          color:#8D9199;
          text-align: center;
          width: 100%;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          span {
            color: #2F3033;
            font-weight: 500;
            cursor: pointer;
          }
        }
        .other-login {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .otherTitle {
            width: 100%;
            display: flex;
            align-items: center;
            .line {
              height: 1px;
              flex: 1;
              background: linear-gradient(270deg, #D9D9D9 0.67%, rgba(217, 217, 217, 0.00) 100%);
              transform: rotate(-180deg);
            }
            .line:nth-child(1){
              transform: rotate(0deg);
            }
            .doubt {
              margin: 0 16px;
              color:#8D9199;
            }
          }
        }
        .gap1 {
          width: 100%;
          height: 64px;
        }
        .gap2 {
          width: 100%;
          height: 24px;
        }
      }
    }
  }
  .coverText{
    width: 100%;
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 103px;
    left: 0;
    z-index: 999;
  }
  .sysTitle{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 3.2px;
  }
  .Abbreviation{
    margin-top: 16px;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }
  .describe{
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.6;
  }
  .registerBtn{
    max-width: 40px;
    width:40px;
    height:40px;
    border-radius: 50%;
    border: 1px solid #2f3033;
    text-align: center;
    line-height: 40px;
    margin: 16px 0 24px 0;
    font-size: 24px;
  }
  .codeImgBlock{
    height: 48px;
  }
  .login-header {
    width: 100%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.302);
    font-weight: 400;
    font-size: 24px;
    display: flex;
    color: #858D98;
    line-height: 34px;
    text-align:center;
  }
  .loginType{
    width: 50%;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .correct {
    border-bottom:4px solid var(--theme);
    color: var(--theme);
    font-weight: bold;
  }
  .loginbtn {
    width: 100%;
    border-radius: 8px;
    height: 48px;
    font-size: 16px;
    margin-top: 40px;
  }
</style>
