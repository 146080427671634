<template>
  <div class="feedback">
    <div class="feedbackBlock">
      <div class="title">意见反馈</div>
      <div class="tips">感谢您提供意见反馈，我们会及时跟进解决您的问题！</div>
      <div class="importBlock">
        <div class="item">*1.您的意见反馈</div>
        <a-textarea class="importInput" v-model:value="feedback.form.idea" placeholder="不超过80个字" :maxlength="80" :bordered="false" style="resize:none;" :rows="4" />
        <div class="item">2.您的联系方式</div>
        <a-input class="importInput" v-model:value="feedback.form.contact" placeholder="请输入您的联系方式" :bordered="false" />
      </div>
      <div class="submit">
         <a-button type="primary" :loading="feedback.loading" :disabled="feedback.loading" @click="handleSubmit">提交</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted } from 'vue';
import defHttp from "@/utils/http.js";
import { useRouter } from 'vue-router'
import { isvalidPhone } from '@/utils/common'
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {},
  setup() {
    const feedback=ref({
      loading:false,
      form:{
        idea:'',
        contact:'',
      }
    })
    const router = useRouter()

    onMounted(() => {})

    const handleSubmit=()=>{
      feedback.value.loading=true
      if(feedback.value.form.idea==''){
        message.error('请填写您的反馈意见')
        feedback.value.loading=false
        return
      }
      if(!isvalidPhone(feedback.value.form.contact)){
        message.error('手机号未填写或格式有误')
        feedback.value.loading=false
        return
      }
      defHttp.post('/student/account/feedback',feedback.value.form).then(data=>{
        if(data.Success){
          feedback.value.loading=false
          message.success('反馈成功！',() => {
            router.replace({ path: '/personal'})
          })
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {feedback.value.loading=false})
    }

    return {
      feedback,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.feedback{
  width: 100%;
  background-color: #f0f2f5;
  padding-top: 40px;
}
.feedbackBlock{
  width: 62.5%;
  min-width: 1200px;
  margin:  0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 40px;
}
.title{
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
}
.tips{
  padding-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.importBlock{
  padding-top: 18px;
}
.item{
  padding: 20px 0 18px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.importInput{
  width: 612px;
  background: #F6F6F6;
  border-radius: 10px;
}
.importInput,.ant-input-borderless:hover{
  background: #F6F6F6 !important;
}
.submit{
  padding: 90px 0;
}
</style>
