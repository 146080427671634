<template>
  <div class="rehearse">
    <div class="tab  aj-flex aj-align-center aj-justify-between">
      <div>
        <span class="centreBtn" @click="handleCentre">个人中心></span>
        <span class="activeTab">错题重练</span>
      </div>
      <div class="text-bold text-lg">
        完成度：<span>{{ nweComplete }}</span> / <span>{{ questionsList.length }}</span>
      </div>
    </div>
    <div class="content">
      <div class="subject" v-if="questionsList">
        <div class="flex aj-justify-between">
          <div class="title">{{ questionIndex + 1 }}.（{{ computeType(currentQuestion.Type) }}）{{
            currentQuestion.TheContent }}
          </div>
          <!-- <div class="completionDegree">完成度:10/100</div> -->
        </div>
        <div class="option">
          <!-- 单选题 -->
          <div class="groupBlock" v-if="currentQuestion.Type == 0">
            <a-radio-group v-model:value="currentQuestion.SutdentAnswers">
              <a-radio :style="radioStyle" :value="item.Id" v-for="(item, index) in currentQuestion.Options"
                :key="index">{{ item.TheContent }}</a-radio>
            </a-radio-group>
          </div>
          <!-- 多选题 -->
          <div class="groupBlock" v-if="currentQuestion.Type == 1 || currentQuestion.Type == 2">
            <a-checkbox-group v-model:value="currentQuestion.SutdentAnswers">
              <a-checkbox :style="radioStyle" :value="item.Id" v-for="(item, index) in currentQuestion.Options"
                :key="index">{{ item.TheContent }}</a-checkbox>
            </a-checkbox-group>
          </div>
          <!-- 判断题 -->
          <div class="groupBlock">
            <a-radio-group v-model:value="currentQuestion.SutdentAnswers" v-if="currentQuestion.Type == 3">
              <a-radio :style="radioStyle" :value="item.Id" v-for="(item, index) in currentQuestion.Options"
                :key="index">{{ item.TheContent }}</a-radio>
            </a-radio-group>
          </div>
          <a-button class="defineBtn" type="primary"
            :disabled="!currentQuestion.SutdentAnswers || !currentQuestion.SutdentAnswers.length"
            @click="handleDefine">确定</a-button>
        </div>
        <div class="answer" v-if="answer">
          <div class="answerBlock">
            <div class="answerType">考生答案:</div>
            <div class="answerInfo">
              <span class="wrong">{{ computeStuAnswer() }}</span>
              <CloseOutlined v-if="!answer.isExactness" class="wrongIcon" />
            </div>
          </div>
          <div class="answerBlock">
            <div class="answerType">正确答案:</div>
            <div class="answerInfo">
              <span class="exactness">{{ computeAnswer(answer.correctAnswerId) }}</span>
            </div>
          </div>
          <div class="knowledge">解析:{{ answer.analysis }}</div>
          <div class="knowledge">知识点:{{ answer.knowledgePoints }}</div>
          <div class="knowledge aj-flex">
            <div>复学课件:</div>
            <div class="scoursewareLink" v-for="(item, index) in answer.resumptionVideo" :key="index"
              @click="handleSeeVideo(item)">{{ computeVideoName(item) }}</div>
          </div>
        </div>
        <div class="next">
          <a-button class="nextBtn" type="primary" size="large" @click="nextQuestion"
            v-if="questionIndex + 1 < questionsList.length && currentQuestion.SutdentAnswers && currentQuestion.SutdentAnswers.length && isAnswer">下一题</a-button>
          <!-- <a-button class="nextBtn" type="primary" size="large" @click="PreviousQuestion" v-if="questionIndex+1==questionsList.length">上一题</a-button> -->
        </div>
      </div>
      <div v-else>
        <a-empty description="暂无错题~" />
      </div>
    </div>

    <a-modal v-model:visible="videoVisible" width="50%" title="复学视频" :footer="null" destroyOnClose :maskClosable="false">
      <div class="videoStyle">
        <video :src="videoUrl" controls style="width:100%;height:100%;"></video>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { CloseOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    CloseOutlined
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    let isAll = route.query.isAll
    const questionsList = ref([])
    const currentQuestion = ref({})
    const questionIndex = ref(0)
    const typeList = ref([])
    const nweComplete=ref(0)
    const answer = ref(null)
    const isAnswer = ref(false)
    const videoVisible = ref(false)
    const videoUrl = ref('')
    const alphabet = ref(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',])
    const radioStyle = reactive({
      display: 'flex',
      paddingTop:'15px',
      paddingBottom:'15px',
    });

    onMounted(() => {
      if (isAll) {
        getTypeList()
      }
    })

    const computeVideoName = computed(() => {
      return (val) => {
        if (val) {
          var array = decodeURIComponent(val).split('/');
          var b = array[array.length - 1];
          return b
        }
      }
    })

    const getTypeList = () => {
      defHttp.get('platform/Common/Enum', { params: { name: 'QuestionType' } }).then(data => {
        if (data.Success) {
          typeList.value = data.Data
          getAllList()
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const getAllList = () => {
      defHttp.get('/student/wrongQuestion/practiseList', { params: { isAll: isAll } }).then(data => {
        if (data.Success) {
          questionsList.value = data.Data
          questionsList.value.forEach(item => {
            item.SutdentAnswers = []
          })
          currentQuestion.value = questionsList.value[questionIndex.value]
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { })
    }

    const computeType = (val) => {
      if (val != undefined) {
        return typeList.value.find(p => p.id == val).name
      }
    }

    const handleDefine = () => {
      if (currentQuestion.value.SutdentAnswers == '' || currentQuestion.value.SutdentAnswers == []) {
        message.error('请选择您的答案')
        return
      }
      nweComplete.value=questionIndex.value + 1
      
      let answers = []
      if (currentQuestion.value.Type == 0 || currentQuestion.value.Type == 3) {
        answers = [currentQuestion.value.SutdentAnswers]
      } else {
        answers = currentQuestion.value.SutdentAnswers
      }
      let params = {
        questionId: currentQuestion.value.Id,
        answerId: answers
      }
      defHttp.post('/student/wrongQuestion/answer', params).then(data => {
        if (data.Success) {
          answer.value = data.Data
          isAnswer.value = true
          if(nweComplete.value==questionsList.value.length){
            message.success('您已完成错题重练')
            setTimeout(() => {
              router.go(-1)
            }, 1800);
          }
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    // 上一题
    const PreviousQuestion = () => {
      questionIndex.value = questionIndex.value - 1
      currentQuestion.value = questionsList.value[questionIndex.value]
    }

    // 下一题
    const nextQuestion = () => {
      answer.value = null
      isAnswer.value = false
      if (questionIndex.value + 1 < questionsList.value.length) {
        questionIndex.value = questionIndex.value + 1
        currentQuestion.value = questionsList.value[questionIndex.value]
      }
    }

    const computeStuAnswer = () => {
      if (currentQuestion.value.Type == 0 || currentQuestion.value.Type == 3) {
        let index = currentQuestion.value.Options.findIndex(p => p.Id == currentQuestion.value.SutdentAnswers)
        return alphabet.value[index]
      } else {
        let arr = currentQuestion.value.SutdentAnswers
        let arr1 = currentQuestion.value.Options
        let name = ''
        for (let i = 0; i < arr1.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (arr1[i].Id === arr[j]) {
              name += alphabet.value[i] + ' '
            }
          }
        }
        return name
      }
    }

    const computeAnswer = (val) => {
      let arr = JSON.parse(val)
      let arr1 = currentQuestion.value.Options
      let name = ''
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (arr1[i].Id === arr[j]) {
            name += alphabet.value[i] + ' '
          }
        }
      }
      return name
    }

    const handleCentre = () => {
      router.push({
        path: '/personal', query: {
          tab: route.query.tab
        }
      })
    }

    const handleSeeVideo = (url) => {
      videoUrl.value = url
      videoVisible.value = true
    }

    return {
      getAllList,
      currentQuestion,
      questionIndex,
      typeList,
      questionsList,
      radioStyle,
      computeType,
      handleDefine,
      answer,
      alphabet,
      PreviousQuestion,
      nextQuestion,
      computeAnswer,
      computeStuAnswer,
      handleCentre,
      isAnswer,
      videoVisible,
      videoUrl,
      handleSeeVideo,
      computeVideoName,
      nweComplete
    };
  },
});
</script>

<style scoped>
.rehearse {
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 32px;
}

.tab {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.activeTab {
  color: var(--theme);
}

.content {
  padding: 42px 147px;
}

.subject {
  padding: 30px 50px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.completionDegree {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.option {
  padding: 20px 0;
}

.answer {
  padding: 12px 14px;
  border-radius: 10px;
  border: 1px solid #CCCCCC;
  overflow: auto;
}

.wrong {
  font-size: 20px;
  font-weight: 700;
}

.wrongIcon {
  font-weight: bold;
  line-height: 20px;
  margin-left: 8px;
  color: #0960bd;
}

.answerBlock {
  margin-bottom: 12px;
}

.answerType {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.answerInfo {
  display: flex;
  margin-top: 4px;
}

.exactness {
  color: #28C445;
  font-size: 20px;
  font-weight: 700;
}

.knowledge {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  letter-spacing: 1px;
}

.scoursewareLink {
  color: #55ABFF;
  cursor: pointer;
}

.next {
  margin-top: 24px;
  width: 100%;
  text-align: center;
}

:deep(.next .ant-btn) {
  padding: 4px 150px !important;
}

.groupBlock {
  padding-top: 10px;
}

.defineBtn {
  margin-top: 30px;
}

.ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.centreBtn {
  cursor: pointer;
}</style>