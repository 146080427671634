<template>
  <div class="notice">
    <a-spin :spinning="spinning">
      <div class="noticeCenter">
        <div class="noticeMenuList">
          <div class="noticeMenuItem" @click="changing(1)">
            <span :class="activeTab==1?'activeTab':''">回复我的</span>
            <span class="amount" v-if="UnreadNum&&messageNum.replyNum>0">{{messageNum.replyNum>99?messageNum.replyNum+'+':messageNum.replyNum}}</span>
          </div>
          <div class="noticeMenuItem" @click="changing(2)">
            <span :class="activeTab==2?'activeTab':''">课程消息</span>
            <span class="amount" v-if="UnreadNum&&messageNum.curriculumNum>0">{{messageNum.curriculumNum>99?messageNum.curriculumNum+'+':messageNum.curriculumNum}}</span>
          </div>
          <div class="noticeMenuItem" @click="changing(3)">
            <span :class="activeTab==3?'activeTab':''">系统消息</span>
            <span class="amount" v-if="UnreadNum&&messageNum.systemNum>0">{{messageNum.systemNum>99?messageNum.systemNum+'+':messageNum.systemNum}}</span>
          </div>
          <div class="noticeMenuItem" @click="changing(4)">
            <span :class="activeTab==4?'activeTab':''">反馈消息</span>
            <span class="amount" v-if="UnreadNum&&messageNum.systemNum>0">{{messageNum.systemNum>99?messageNum.systemNum+'+':messageNum.systemNum}}</span>
          </div>
        </div>
        <div class="noticeContent">
          <div class="header">
            <div class="title">{{activeTab==1?'回复我的':activeTab==2?'课程消息':'系统消息'}}</div>
            <div class="read" @click="handleRead" v-if="messageList.length">全部已读</div>
          </div>
          <div class="noticeList">
            <div class="noticeItem" @click="handleDetail(item)" :class="item.isRead || item.Read?'readItem':''" v-for="(item,index) in messageList" :key="index">
              <div v-if="activeTab != 4">{{item.title}}</div>
              <div v-if="activeTab != 4">{{item.dateTime}}</div>

              <div v-if="activeTab == 4">{{item.Title}}</div>
              <div v-if="activeTab == 4">{{item.CreateDate}}</div>
            </div>
            <div class="aj-padding-top-lg">
              <a-empty v-if="!messageList.length" description="暂无数据" />
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <a-modal v-model:visible="contentVisible" centered title="消息详情" :footer="null" @cancel="handleCancel" :maskClosable="false">
      <div class="contentModal">
        <div class="formItem">
            <div class="NoticeTitle">消息标题：</div>
            <div class="NoticeConetnt">{{contentInfo.title}}</div>
        </div>
        <div class="formItem">
            <div class="NoticeTitle">消息内容：</div>
            <div class="NoticeConetnt" v-html="contentInfo.content"></div>
        </div>
        <div class="formItem" v-if="contentInfo.images.length">
            <div class="NoticeTitle">消息图片：</div>
            <div class="NoticeConetnt">
              <div class="aj-flex aj-flex-wrap">
                  <div v-for="(item,index) in contentInfo.images" :key="index"  style="margin:8px 8px 0 0;">
                      <a-image :width="120" :height="120" :src="item.Url" />
                  </div>
              </div>
          </div>
        </div>
        
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted } from 'vue';
import { WomanOutlined,ManOutlined } from '@ant-design/icons-vue';
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    WomanOutlined,
    ManOutlined
  },
  setup() {
    const activeTab=ref(1)
    const messageNumId=ref(1)
    const spinning=ref(false)
    const page=ref(1)
    const messageList=ref([])
    const UnreadNum=ref(false)
    const contentInfo=ref({})
    const contentVisible=ref(false)
    const messageNum=ref({
      replyNum:0,  //回复
      curriculumNum:0,  //课程
      systemNum:0  //系统
    })

    onMounted(() => {
      getNoticeInfo()
      getMessageList()
    })

    const getNoticeInfo=()=>{
      defHttp.get('/system/message/dots').then(data=>{
        if(data.Success){
          if(data.Data.length){
            UnreadNum.value=true
            data.Data.forEach(item=>{
              if(item.Type==300){
                messageNum.value.replyNum=item.Count
              }else if(item.Type==200){
                messageNum.value.curriculumNum=item.Count
              }else if(item.Type==0){
                messageNum.value.systemNum=item.Count
              }
            })
          }else{
            UnreadNum.value=false
          }
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const getMessageList=()=>{
      spinning.value=true
      defHttp.get('/student/message/list',{params:{type:activeTab.value,page:page.value,limit:9999}}).then(data=>{
        if(data.Success){
          spinning.value=false
          messageList.value=data.Data.Rows
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const getSystemMessageList=()=>{
      spinning.value=true
      defHttp.get('/system/message/SysMessagge',{params:{type:2,page:page.value,limit:9999}}).then(data=>{
        if(data.Success){
          spinning.value=false
          messageList.value=data.Data.Rows
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleRead=()=>{
      let list=[]
      messageList.value.forEach(item=>{
        list.push(item.id)
      })
      defHttp.post('/System/Message/Read',list).then(data=>{
        if(data.Success){
          message.success('操作成功')
          setTimeout(()=>{
            getMessageList()
            getNoticeInfo()
          },2000)
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const changing=(type)=>{
      activeTab.value=type
      messageList.value=[]
      if(type==4){
        getSystemMessageList()
      } else {
        getMessageList()
      }
    }

    const handleDetail=(value)=>{
      contentInfo.value=value
      if(activeTab.value == 4){
        contentInfo.value.content = value.TextContent;
        contentInfo.value.title = value.Title;
        contentInfo.value.id = value.Id;
      } else {
        contentInfo.value.content=value.content;
        contentInfo.value.id = value.id;
      }

      if(value.images){
        contentInfo.value.images=JSON.parse(value.images)
      }else{
        contentInfo.value.images=[]
      }

      defHttp.post('/System/Message/Read',[contentInfo.value.id]).then(data=>{})
      contentVisible.value=true
    }

    const handleCancel=()=>{
      if(activeTab.value == 4){
        getSystemMessageList()
      } else {
        getMessageList()
        getNoticeInfo()
      }
      
    }

    return {
      activeTab,
      getMessageList,
      getSystemMessageList,
      spinning,
      page,
      changing,
      messageList,
      messageNum,
      messageNumId,
      handleRead,
      UnreadNum,
      contentInfo,
      handleDetail,
      contentVisible,
      handleCancel,
      getNoticeInfo
    };
  },
});
</script>

<style scoped>
.notice{
  width: 100%;
  background-color: #f0f2f5;
  padding-top: 40px;
}
.noticeCenter{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.noticeMenuList{
  width: 274px;
  border-radius: 5px;
  overflow: hidden;
}
.noticeMenuItem{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  background: #FFFFFF;
  cursor: pointer;
  border-top: 1px solid #f6f6f6;
}
.amount{
  margin-left: 6px;
  padding: 2px 6px;
  background: #0960bd;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 12px;
}
.noticeContent{
  margin-left: 10px;
  flex: 1;
  background: #FFFFFF;
  border-radius:5px;
  overflow: hidden;
}
.header{
  display: flex;
  justify-content: space-between;
  padding: 20px 48px;
  border-bottom: 1px solid #f6f6f6;
}
.title{
  font-size: 20px;
  line-height: 23px;
}
.read{
  font-size: 12px;
  font-weight: 400;
  color: #115BA4;
  line-height: 23px;
  cursor: pointer;
}
.noticeList{
  padding: 10px 30px 30px 30px;
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll;
}
.noticeList::-webkit-scrollbar {
  display: none;
}
.noticeItem{
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 18px;
  border-bottom:1px solid #D8D8D8;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}
.noticeItem:last-child{
  border-bottom: 0;
}
.readItem{
  color: #999999;
}
.activeTab{
  color: var(--theme);
}
.contentModal{
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}
.contentModal::-webkit-scrollbar {
  display: none;
}
.formItem{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 24px;
  display: flex;
}
.NoticeTitle{
  width: 70px;
}
.NoticeConetnt{
  flex: 1;
}
</style>
