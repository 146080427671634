<template>
  <div class="courseBlock">
    <a-spin :spinning="spinning">
      <div class="curriculum">
        <div class="curriculumType">
          <div class="curriculumTypeList">
            <div class="curriculumTab">
              <div class="direction">热搜</div>
              <div class="verticalLine"></div>
              <div class="curriculumList" :class="isDevelop?'developStyle':''">
                <div class="curriculumItem" :class="activeId==item.id?'activeTab':''" v-for="(item,index) in curriculumList" :key="index" @click="handleSelect(item.id)">{{item.name}}</div>
              </div>
            </div>
            <div v-if="!isDevelop" class="develop" @click="handleDevelop(true)">
              <span class="developText">展开</span>
              <DownOutlined />
            </div>
            <div v-if="isDevelop" class="develop" @click="handleDevelop(false)">
              <span class="developText">收起</span>
              <UpOutlined />
            </div>
          </div>
          <div class="classify">
            <div class="classifyItem" v-for="(item,index) in sortOrder" :key="index" @click="handleSort(item.id)" :class="item.id==sortType?'activeSort':''">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="courseCore">
        <a-tabs v-model:activeKey="activeKey" class="courseTab" @change="changeTabs" style="width:100%;">
          <a-tab-pane :key="1" :tab="'全站课程('+courseLists.length+')'">
            <div class="courseList">
              <div class="courseItem" v-for="(item,index) in courseLists" key="index" @click="handleDetail(item.id)">
                <div class="showArea">
                  <img class="courseImg" :src="item.img" alt="">
                  <!-- <div class="courseType">实战课</div> -->
                  <!-- <div class="courseType free">免费课</div> -->
                </div>
                <div class="courseInfo">
                  <div>
                    <div class="title">{{item.name}}</div>
                    <div class="introduce">{{item.synopsis}}</div>
                  </div>
                  <div class="otherInfo">讲师:{{item.teacherName}} 学习人数:{{item.learnersNum}}人</div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="2" :tab="'课程内容('+courseContent.length+')'">
            <div class="courseList">
              <div class="contentItem" v-for="(item,index) in courseContent" :key="index">
                <div class="contentTitle">
                  <span>第{{item.chapters}}章 {{item.chapterTitle}}</span>
                  <span class="tips">来源课程:{{item.title}}</span>
                </div>
                <div class="contentIntroduce">{{item.chapterContent}}</div>
                <div class="catalogueList">
                  <div class="catalogue" v-for="(itm,i) in item.catalogueList" :key="i">{{itm.title}}</div>
                </div>
                <div class="courseOther">讲师:{{computedTeacher(item.teacher)}} 学习人数:{{item.learnersNum}}人</div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="3" tab="文档资料">
            <div class="courseList">
              <a-empty description="暂无数据" style="padding:100px 0;" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,computed,watch} from 'vue';
import { DownOutlined,UpOutlined } from '@ant-design/icons-vue';
import {useRouter,useRoute} from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    DownOutlined,
    UpOutlined
  },
  setup() {
    const router=useRouter()
    const route = useRoute()
    const search=ref(route.query.search,)
    const isDevelop=ref(false)
    const curriculumList=ref([
      {name:'全部',id:-1}])
    const sortOrder=ref([
      {name:'最新上线',id:1},
      {name:'学习人数',id:2},
    ])
    const courseLists=ref([])
    const activeId=ref(-1)
    const sortType=ref(1)
    const spinning=ref(false)
    const activeKey=ref(1)
    const courseContent=ref([])

    const handleSelect=(id)=>{
      activeId.value=id
      if(activeKey.value==1){
        getCourseList()
      }else if(activeKey.value==2){
        getCourseContent()
      }
    }

    watch(()=> route.query.search,
      (newValue, oldValue)=> {
        search.value=route.query.search
        console.log("route.query",route.query)
        getCourseList()
      },
    )

    onMounted(() => {
      getDirectionList()
      getCourseList()
      getCourseContent()
    })

    const computedTeacher = computed(() => {
      return (val) => {
        let array=JSON.parse(val)
        let name = array.map((item, index) => {
          return item.Name;
        }).join(",");
        return name
      }
    })

    const getDirectionList=()=>{
      defHttp.get('/student/direction').then(data=>{
        if(data.Success){
          curriculumList.value.push(...data.Data)
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handleSort=(type)=>{
      sortType.value=type
      if(activeKey.value==1){
        getCourseList()
      }else if(activeKey.value==2){
        getCourseContent()
      }
    }

    const getCourseList=()=>{
      spinning.value=true
      defHttp.get('/student/course/list',{params:{directionId:activeId.value,sort:sortType.value,search:search.value}}).then(data=>{
        spinning.value=false
        if(data.Success){
          courseLists.value=data.Data
          courseLists.value.forEach(item=>{
            let imgUrl=JSON.parse(item.img)
            item.img=imgUrl[0].Url
          })
        }else{
          message.error(`${data.Message}`)
        }

      }).catch(err => {spinning.value=false})
    }

    const getCourseContent=()=>{
      spinning.value=true
      defHttp.get('/student/course/search',{params:{directionId:activeId.value,sort:sortType.value,search:search.value}}).then(data=>{
        spinning.value=false
        if(data.Success){
          courseContent.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const changeTabs=()=>{
      if(activeKey.value==1){
        getCourseList()
      }else if(activeKey.value==2){
        getCourseContent()
      }
    }

    const handleDetail=(id)=>{
      router.push({path:'/courseDetail',query:{courseId:id}})
    }

    const handleDevelop=(type)=>{
      isDevelop.value=type
    }
    return {
      curriculumList,
      activeId,
      handleSelect,
      handleDetail,
      activeKey,
      getDirectionList,
      sortOrder,
      handleSort,
      getCourseList,
      courseLists,
      spinning,
      sortType,
      changeTabs,
      getCourseContent,
      courseContent,
      handleDevelop,
      handleDetail,
      isDevelop,
      computedTeacher,
      search
    };
  },
});
</script>

<style scoped>
.courseBlock{
  min-height: 100%;
  background-color:#f0f2f5;
}
.curriculum{
  width: 100%;
  padding: 22px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.16);
}
.curriculumType{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
}
.curriculumTypeList{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.curriculumTab{
  display: flex;
}
.direction{
  min-width: 65px;
  font-size: 32px;
  line-height: 37px;
  color: #333333;
}
.verticalLine{
  width: 2px;
  height: 31px;
  background: #CCCCCC;
  margin: 0 50px;
}
.curriculumList{
  display: flex;
  max-height: 45px;
  overflow: hidden;
  flex-wrap: wrap;
}
.curriculumItem {
  margin-right: 10px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #545c63;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 15px;
}

.curriculumItem:hover{
  color: #FFFFFF;
  font-weight: bold;
  background: #545c63;
  border-radius: 15px;
}

.activeTab {
  color: #FFFFFF;
  font-weight: bold;
  background: #545c63;
  border-radius: 15px;
}
.develop{
  min-width: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 33px;
  color: var(--theme);
  cursor: pointer;
}
.developText{
  margin-right: 10px;
}
.classify{
  margin-top: 34px;
  display: flex;
  justify-content: flex-end;
}
.classifyItem{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  margin-left: 28px;
  cursor: pointer;
}
.courseCore{
  width: 62.5%;
  margin:0 auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
:deep(.courseTab .ant-tabs-nav::before){
  border-bottom: 0 !important;
}
:deep(.courseTab .ant-tabs-ink-bar){
  height: 6px !important;
}
.courseList{
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(217,217,217,0.16);
  border-radius: 10px;
}
.courseItem{
  margin-bottom: 38px;
  display: flex;
  padding-bottom: 32px;
  border-bottom: 2px solid #CCCCCC;
  cursor: pointer;
}
.courseItem:last-child{
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.showArea{
  width: 263px;
  min-width: 263px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.courseImg{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.courseType{
  width: 58px;
  height: 29px;
  text-align: center;
  background: #115BA4;
  border-radius: 10px 0px 10px 0px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 29px;
}
.free{
  background-color: #FFC285;
}
.courseInfo{
  flex: 1;
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}
.title{
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  line-height: 19px;
}
.introduce{
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  letter-spacing: 1px;
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
}
.otherInfo{
  font-size: 10px;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
}
.contentItem{
  padding-bottom: 16px;
  border-bottom: 2px solid #CCCCCC;
  margin-bottom: 35px;
}
.contentItem:last-child{
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
}
.contentTitle{
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 1px;
}
.tips{
  padding-left: 9px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  letter-spacing: 1px;
}
.contentIntroduce{
  padding-top: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  letter-spacing: 1px;
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
}
.catalogueList{
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
}
.catalogue{
  padding: 10px 12px;
  background: #F4F4F4;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  margin-right: 16px;
  margin-bottom: 12px;
}
.courseOther{
  padding-top: 30px;
  font-size: 10px;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  letter-spacing: 1px;
}
.activeSort{
  color: var(--theme);
}
.developStyle{
  max-height: none !important;
}
</style>