<template>
  <div class="noticeDetail">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240);width: 1200px;"
      title="公告详情"
      @back="router.back()"
    >
      <div class="container">
        <div class="title">{{ noticeDetail.Title }}</div>
        <div class="tiem">发布时间: &nbsp;{{ noticeDetail.CreateDate }}</div>
        <div class="content" v-html="noticeDetail.TheContent"></div>
      </div>
    </a-page-header>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, unref, toRaw } from 'vue';
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
import defHttp from "@/utils/http.js";
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    DownOutlined,
    UpOutlined
  },
  setup() {
    
    const router = useRouter()
    const route = useRoute()
    let id = route.query.id
    const noticeDetail = ref({
      Title: '',
      TheContent: '',
      CreateDate: '',
    })
    const getnoticeList = () => {
      defHttp.get('/system/Notice/Detail', { params: { id } }).then(data => {
        if (data.Success) {
          noticeDetail.value = data.Data
        } 
      }).catch(err => {  })
    }
    getnoticeList()

    return {
      router,
      noticeDetail,
    };
  },
});
</script>

<style scoped>
.noticeDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding:48px;
  box-sizing: border-box;
  background-color: #fff;
  min-height: calc(100vh - 72px);

}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
 width: 900px;
  background-color: #fff;
  margin-bottom: 12px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.tiem {
  margin-bottom: 12px;
}
.content {
  width: 900px;
}
</style>