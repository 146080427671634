import axios from "axios";
import { message } from 'ant-design-vue';
import qs from "qs";

const instance = axios.create({
    // baseURL: "https://mock.apifox.cn/m1/2410791-0-default",
    // baseURL: "http://adzjgc.tumengkj.com",
    baseURL: "https://adzjgc.tumengkj.com",
    // baseURL: "https://localhost:15094",
});
instance.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('token-smartvg') ? localStorage.getItem('token-smartvg') : 'MVrUyoYLK/EtgHmktD1Zw0ggi+Pd5WZLebNpeKvdd+LClbPKQXlzkAJbrobZl0g9V2PFABzxc29LYgzXkJ1T+jUSme4Wh+IWZ2r9PcpVkTglHXuo8aHrHOT7BxdZquB4PlSpktYotAqL2+TSRNc5383CCy+c6fdkxmcIw+uPr2Y='
    return config;
})

instance.interceptors.response.use((response) => response.data,
    (error) => {
        message.error(error.message ? error.message : (error.Message || '出错了'))
        return Promise.reject(error);
    }
);


//单独给工单管理使用
export const instance2 = axios.create({
    // baseURL: "https://mock.apifox.cn/m1/2410791-0-default",
    baseURL: "https://adzjgc.tumengkj.com",
    // baseURL: "https://localhost:15094",
});
instance2.interceptors.request.use((config) => {
    console.log('instance2');
    // config.data = qs.stringify(config.data);
    config.headers.Authorization = localStorage.getItem('userInfo') ? localStorage.getItem('userInfo').CreatorToken : ''
        //config.headers.Authorization = 'JupUq6WyLZpkHDLMRMEzKiR/60HwiHMdt3Me9gu14wneGpeKzJtuNUOEjlXImoE257usB9Q0nSiEVUTDx4oas+NSCZ6aaes1wd+s2NKpzrtY2t8XfNcMYL8hOpvkLNd21NIYGR6iHN1ZWFbU+8hzymwozILfXb5+Fsae+0svhQQ='

    return config;
})

instance2.interceptors.response.use((response) => response.data,
    (error) => {
        message.error(error.message ? error.message : (error.Message || '出错了'))
        return Promise.reject(error);
    }
);

export default instance;