<template>
  <div class="courseInfo">
    <a-spin class="spinBlock" size="large" v-if="emptyPlan" />
    <mindmap class="mindmapContent" v-if="isShow" :modelValue="treeData" @update:modelValue="treeData" :zoom="true" :ctm="true" :branch="2" @select="handleSelect" :defalutScale="1.2"></mindmap>
    <div class="EmptyState" v-if="!isShow">
      <img class="EmptyStateImg" src="../../assets/image/EmptyStateImg.png" alt="">
      <div class="tips">学习计划暂时为空，<span class="set" @click="handleSet">设置</span>课程分类，保存后系统将关联属于您的学习计划。</div>
      <a-button type="primary" @click="handleSet" size="large" style="margin-top:34px;">设置课程分类</a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,} from 'vue';
import { useRoute, useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
import mindmap from 'vite-mindmap'
export default defineComponent({
  components: {
    mindmap
  },
  setup() {
    const careerOrientationId=ref(0)
    const isShow=ref(false)
    const treeData=ref([])
    const router=useRouter()
    const emptyPlan=ref(true)
    const route = useRoute()
    
    onMounted(() => {
      careerOrientationId.value=route.query.id
      getTreeData()
    })
    
    const getTreeData=()=>{
      defHttp.get(`/student/direction?careerId=${route.query.id}`).then(data=>{
        isShow.value=false
        emptyPlan.value=false
        if(data.Success){
          let list=data.Data
          let dataList=list.find(p=>p.id==careerOrientationId.value).treeData
          treeData.value=JSON.parse(dataList)
          treeData.value.forEach(item=>{
            if(item.children.length){
              item.children.forEach(itm=>{
                console.log(itm)
                if(itm.children.length){
                  itm.children.forEach(i=>{
                    if(i.children.length){
                      i.collapse=true
                    }
                  })
                }
              })
            }
          })
          getNodeTree(treeData.value)
          isShow.value=true
          emptyPlan.value=false
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const getNodeTree= (node)=>{
      node.forEach((item) => {
        if(item.children.length<=0){
          if(item.isTest){
            defHttp.get('/student/evaluation/paperStatus',{params:{id:item.id,careerPath:careerOrientationId.value}}).then(data=>{
              if(data.Success){
                if(data.Data.IsAccomplish){
                  item.name=item.name+'-已完成'
                  item.isAccomplish=true
                }else{
                  item.name=item.name+'-未完成'
                  item.isAccomplish=false
                }
              }else{
                message.error(`${data.Message}`)
              }
            })
          }else if(item.ksId){
            emptyPlan.value=true
            // defHttp.get('/student/course/chapters',{params:{id:item.kcId}}).then(data=>{
            //   if(data.Success){
            //     emptyPlan.value=false
            //     let list=data.Data
            //     let index=list.findIndex(p=>p.id==item.ksParentId)
            //     let i=list[index].catalogue.findIndex(p=>p.id==item.ksId)
            //     let progress=Number(list[index].catalogue[i].progress)*100
            //     item.name=item.name+'-(学习进度'+progress+'%)'
            //   }else{
            //     message.error(`${data.Message}`)
            //     emptyPlan.value=false
            //   }
            // })
          }
        }else{
          getNodeTree(item.children);
        }     
      })
    }

    const handleSet=()=>{
      router.push('/base')
    }

    const handleSelect=(e)=>{
      if(e.rawData.isFinal){
        if(e.rawData.ksId){
          router.push({path:'/videoLearning',query:{courseId:e.rawData.kcId,chaptersId:e.rawData.ksParentId,catalogueId:e.rawData.ksId}})
          return
        }else if(!e.rawData.isAccomplish){
          router.push({path:'/failed',query:{nodeId:e.rawData.id,directionId:careerOrientationId.value}})
        }else if(e.rawData.isAccomplish){
          router.push({path:'/accomplish',query:{nodeId:e.rawData.id,directionId:careerOrientationId.value}})
        }
      }else if (e.rawData.isTest) {
        if(!e.rawData.isAccomplish){
          router.push({path:'/failed',query:{nodeId:e.rawData.id,directionId:careerOrientationId.value}})
        }else if(e.rawData.isAccomplish){
          router.push({path:'/accomplish',query:{nodeId:e.rawData.id,directionId:careerOrientationId.value}})
        }
      }
    }

    
    return {
      treeData,
      getTreeData,
      isShow,
      careerOrientationId,
      handleSet,
      handleSelect,
      getNodeTree,
      emptyPlan,
    };
  },
});
</script>
<style scoped>
.courseInfo{
  margin: 0 auto;
  position: relative;
  height: calc(100vh - 46px);
}
.mindmapContent{
  width:100%;
  min-width: 1200px;
  margin: 0 auto;
  height: 100%;
}
:deep(.Mindmap_svg_fgvb6){
  background-color:#f0f2f5 !important;
}
.EmptyState{
  padding-top: 64px;
  text-align: center;
}
.EmptyStateImg{
  width: 200px;
  height: 200px;
}
.tips{
  padding-top: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
.set{
  color: #55ABFF;
  cursor: pointer;
}
.spinBlock{
  position: absolute;
  top: 50%;  
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
}

/deep/.Mindmap_svg_1vdhe .Mindmap_root_1vdhe .depth-1>.Mindmap_content_1vdhe>.Mindmap_text_1vdhe>rect{
  /* opacity: 1;
  fill: pink; */
}
</style>