<template>
  <div class="courseBlock">
    <div v-if="!graduated">
      <div class="curriculum">
        <div class="curriculumType">
          <div class="flex">
            <div class="curriculumItem" :class="activeId==item.id?'activeTab':''" v-for="(item,index) in curriculumList" :key="index" @click="handleSelect(item.id)">{{item.name}}</div>
          </div>
          <div class="classify">
            <div class="classifyItem" v-for="(item,index) in sortOrder" :key="index" @click="handleSort(item.id)" :class="item.id==sortType?'activeSort':''">{{item.name}}</div>
          </div>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <div class="courseCore">
          <div class="courseItemBlock" v-for="(item,index) in evaluationList" :key="index">
            <div class="courseItem">
              <div class="statusLabel" v-if="item.status==-1">未参加</div>
              <div class="statusLabel failed" v-if="item.status==3">未通过</div>
              <div class="statusLabel pass" v-if="item.status==2">已通过</div>
              <div class="statusLabel underway" v-if="item.status==0">考试中</div>
              <div class="statusLabel" v-if="item.status==1">待阅卷</div>
              <img class="courseImg" :src="item.img" alt="">
              <div class="courseInfo">
                <div>
                  <div class="courseName">{{item.course?.Name || '--'}}</div>
                  <div class="profile">简介:{{item.desciption}}</div>
                  <div class="profile">分类:{{item.name || '--'}}</div>
                </div>
                <div class="courseFun">
                  <div></div>
                  <a-button class="primaryBtn" type="primary" @click="handleJoin(item.id)" v-if="item.status==0||item.status==-1 || item.status==3 ||item.status==-99">参加考核</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-empty v-if="!evaluationList.length" description="暂无数据" />
      </a-spin>
    </div>
    <div v-else class="graduateState">
      <img class="graduateImg" src="../../assets/image/graduateImg.png" alt="">
      <div class="graduateText">您已毕业已经是一名合格的设计师，加入接单平台与我们一起见证AIGC的发展<a style="color:#55ABFF;" href="http://gongdan.tumengkj.com/admin/index.html">http://gongdan.tumengkj.com/admin/index.html</a><br>（使用当前平台账号密码或手机号验证方式可直接登录系统）</div>
    </div>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      :width="650"
    >
      <div class="joinModal">
        <div class="modalTitle">{{explainModal.title}}</div>
        <div class="explain">考核说明</div>
        <div class="explainContent">
          <div class="examineItem">
            <div>考核限时:</div>
            <div>{{explainModal.TimeSpan}}分钟</div>
          </div>
          <div class="examineItem">
            <div>及格分数:</div>
            <div>{{explainModal.PassLine}}%</div>
          </div>
        </div>
        <div class="rubric">考核说明： <br/>1.离开或退出考核界面答题计时不停止，请不要中途离开考核界面。<br/> 2.考核时间截至或答题时间结束，如果处于答题界面，将自动提交试卷。</div>
        <div class="agree">
          <a-checkbox v-model:checked="readChecked">我已阅读</a-checkbox>
        </div>
        <div class="margin-0-auto">
          <a-button type="primary" size="large" @click="handleExamine">进入考核</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import {useRouter} from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    DownOutlined
  },
  setup() {
    const router=useRouter()
    const spinning=ref(false)
    const visible = ref(false);
    const evaluationList=ref([])
    const examineId=ref(0)
    const sortType=ref(1)
    const readChecked=ref(false)
    const examinationId=ref(0)
    const graduated=ref(false)
    const explainModal=ref({
      title:'',
      TimeSpan:0,
      PassLine:0,
    })
    const sortOrder=ref([
      {name:'最新上线',id:1},
      {name:'考核人数',id:2},
    ])
    const curriculumList=ref([
      {name:'全部',id:'-2'},
      {name:'未参加',id:-1},
      {name:'答题中',id:0},
      {name:'待阅卷',id:1},
      {name:'已通过',id:2},
      {name:'未考过',id:3},
    ])
    const activeId=ref(-2)

    onMounted(() => {
      getEvaluationList()
      let info=JSON.parse(localStorage.getItem('userInfo'))
      graduated.value=info.graduated
    })

    const handleJoin = (id) => {
      readChecked.value=false
      examineId.value=id
      getExplain()
      visible.value = true;
    };

    const getExplain=()=>{
      defHttp.get('/trainer/System/Paper',{params:{id:examineId.value}}).then(data=>{
        if(data.Success){
          explainModal.value.title=data.Data.CareerPathName
          explainModal.value.TimeSpan=data.Data.TimeSpan
          explainModal.value.PassLine=data.Data.PassLine
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handleSelect=(id)=>{
      activeId.value=id
      getEvaluationList()
    }

    const getEvaluationList=()=>{
      spinning.value=true
      defHttp.get('student/examination/list',{params:{type:activeId.value,page:1,limit:9999,sortbystudent:sortType.value}}).then(data=>{
        spinning.value=false
        if(data.Success){
          evaluationList.value=data.Data.Rows
          evaluationList.value.forEach(item=>{
            let img=JSON.parse(item.img)
            if(img.Id){
              item.img=img[0].Url
            }else{
              item.img=img[0].url
            }
            
          })
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleSort=(type)=>{
      sortType.value=type
      getEvaluationList()
    }

    const handleExamine=()=>{
      defHttp.get('/student/examination/start',{params:{examitionId:examineId.value}}).then(data=>{
        if(data.Success){
          examinationId.value=data.Data
          if(examinationId.value){
            if(readChecked.value){
              router.push({path:'/exam',query:{examinationId:examinationId.value,examineId:examineId.value}})
            }else{
              message.error('请阅读并勾选考核说明')
            } 
          }
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    return {
      visible,
      handleJoin,
      curriculumList,
      activeId,
      handleSelect,
      handleExamine,
      sortType,
      handleSort,
      sortOrder,
      getEvaluationList,
      spinning,
      evaluationList,
      examineId,
      getExplain,
      readChecked,
      explainModal,
      examinationId,
      graduated
    };
  },
});
</script>
<style scoped>
.courseBlock {
  min-height: 100%;
  background-color: #f0f2f5;
}
.curriculum{
  width: 100%;
  padding: 22px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.16);
}
.curriculumType{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.curriculumItem {
  margin-right: 10px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #545c63;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 15px;
}

.curriculumItem:hover{
  color: #FFFFFF;
  font-weight: bold;
  background: #545c63;
  border-radius: 15px;
}

.activeTab {
  color: #FFFFFF;
  font-weight: bold;
  background: #545c63;
  border-radius: 15px;
}
.develop{
  font-weight: 400;
  font-size: 12px;
  line-height: 33px;
  color: var(--theme);
}
.developText{
  margin-right: 10px;
}
.classify{
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
.classifyItem{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  margin-left: 28px;
}
.courseCore{
  width: 62.5%;
  margin:0 auto;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.courseItemBlock{
  width: 23%;
  margin-right: 2%;
  min-width: 263px;
  display: flex;
  justify-content: center;
}
.courseItem{
  width: 263px;
  height: 300px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(199, 215, 225, 0.31);
  overflow: hidden;
  margin-bottom:20px;
  position: relative;
  cursor:pointer;
}
.statusLabel{
  width: 58px;
  height: 29px;
  background: #D9D9D9;
  border-radius: 10px 0px 10px 0px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 29px;
  text-align: center;
}
.failed{
  background: #0960bd;
  color: #FFFFFF;
}
.pass{
  background: #28C445;
  color: #FFFFFF;
}
.underway{
  background-color: #1677ff;
  color: #FFFFFF;
}
.courseImg{
  width: 100%;
  height: 150px;
}
.courseInfo{
  height: 150px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.courseName{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.primaryBtn{
  padding: 4px 10px;
}
.profile{
  margin-top: 11px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}
.courseFun{
  display: flex;
  justify-content: space-between;
}
.encoded{
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 32px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;

}
.joinModal{
  padding: 16px 16px 0 16px;
}
.modalTitle{
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
}
.explain{
  padding-top: 23px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
  text-align: center;
}
.explainContent{
  padding-top: 30px;
}
.examineItem{
  padding: 15px 0;
  border-bottom: 2px solid #CCCCCC;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
}
.rubric{
  padding-top: 20px;
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
  letter-spacing: 1px;
}
.agree{
  padding-top: 15px;
}
.margin-0-auto{
  margin-top: 25px;
  width: 100%;
  text-align: center;
}
.classify{
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
.classifyItem{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  margin-left: 28px;
  cursor: pointer;
}
.activeSort{
  color: var(--theme);
}
.graduateState{
  padding-top: 135px;
  text-align: center;
}
.graduateImg{
  width: 286px;
  height: 220px;
}
.graduateText{
  padding-top: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
</style>
