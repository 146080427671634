import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import navTab from '../views/navTab.vue'
import teacherNavTab from '../components/TeacherNavTab.vue'
import course from '../views/course/index.vue'
import noticeDetail from '../views/course/noticeDetail.vue'
import courseAll from '../views/course/all.vue'
import courseDetail from '../views/course/detail.vue'
import practice from '../views/practice/index.vue'
import practiceDetail from '../views/practice/detail.vue'
import evaluation from '../views/evaluation/index.vue'
import exam from '../views/evaluation/exam.vue'
import learningPlan from '../views/learningPlan/index.vue'
import videoLearning from '../views/learningPlan/videoLearning.vue'
import failed from '../views/learningPlan/failed.vue'
import passed from '../views/learningPlan/passed.vue'
import accomplish from '../views/learningPlan/accomplish.vue'
import studyPlan from '../views/learningPlan/studyPlan.vue'
import personal from '../views/personal/index.vue'
import rehearse from '../views/personal/rehearse.vue'
import base from '../views/personal/base.vue'
import feedback from '../views/personal/feedback.vue'
import notice from '../views/personal/notice.vue'
import login from '../views/login.vue'
import systemSelection from '../views/systemSelection.vue'

import workOrder from '../views/workOrder/index.vue'
import workOrderDetail from '../views/workOrder/detail.vue'
import works from '../views/works/index.vue'
import worksDetail from '../views/works/detail.vue'
import { name } from 'dayjs/locale/zh-cn'

const routes = [{
    path: '/',
    component: navTab,
    redirect: '/course', //加入此行
    children: [{
            path: 'course',
            component: course
        }, {
            path: 'courseAll',
            component: courseAll
        }, {
            path: 'courseDetail',
            component: courseDetail
        }, {
            path: 'practice',
            name: 'practice',
            component: practice
        }, {
            path: 'practiceDetail',
            component: practiceDetail
        },
        {
            path: 'workOrder',
            component: workOrder
        }, {
            path: 'workOrderDetail',
            component: workOrderDetail
        },
        {
            path: 'noticeDetail',
            component: noticeDetail
        },

        {
            path: 'evaluation',
            component: evaluation
        }, {
            path: 'learningPlan',
            component: learningPlan
        }, {
            path: 'videoLearning',
            component: videoLearning
        }, {
            path: 'studyPlan',
            component: studyPlan
        }, {
            path: 'passed',
            component: passed
        }, {
            path: 'accomplish',
            component: accomplish
        }, {
            path: 'personal',
            component: personal
        }, {
            path: 'works',
            component: works
        }, {
            path: 'worksDetail',
            component: worksDetail
        }, {
            path: 'rehearse',
            component: rehearse
        }, {
            path: 'base',
            component: base
        }, {
            path: 'feedback',
            component: feedback
        }, {
            path: 'notice',
            component: notice
        }
    ]
}, {
    path: '/failed',
    component: failed
}, {
    path: '/exam',
    component: exam
}, {
    path: '/login',
    component: login
}, {
    path: '/systemSelection',
    component: systemSelection
}, {
    path: '/teacher',
    component: teacherNavTab,
    redirect: '/teacher/careerpath/index',
    children: [{
        path: 'careerpath',
        children: [{
            path: 'index',
            component: async() =>
                import ('../views/teacher/careerpath/index.vue')
        }, {
            path: 'test-form',
            component: async() =>
                import ('../views/teacher/careerpath/test-form.vue')
        }]
    }, {
        path: 'message',
        children: [{
            path: 'index',
            component: async() =>
                import ('../views/teacher/message/index.vue')
        }]
    }, {
        path: 'student',
        children: [{
            path: 'list',
            component: async() =>
                import ('../views/teacher/student/list.vue')
        }]
    }, {
        path: 'test',
        children: [{
            path: 'list',
            component: async() =>
                import ('../views/teacher/test/list.vue')
        }, {
            path: 'detail',
            component: async() =>
                import ('../views/teacher/test/detail.vue')
        }]
    }, {
        path: 'order',
        children: [{
            path: 'list',
            component: async() =>
                import ('../views/teacher/order/list.vue')
        }, {
            path: 'detail',
            component: async() =>
                import ('../views/teacher/order/detail.vue')
        }]
    }, {
        path: 'profile',
        children: [{
            path: 'index',
            component: async() =>
                import ('../views/teacher/profile/index.vue')
        }]
    }, {
        path: 'feedback',
        children: [{
            path: 'index',
            component: async() =>
                import ('../views/teacher/feedback/index.vue')
        }]
    }, {
        path: 'study',
        children: [{
            path: 'index',
            component: async() =>
                import ('../views/teacher/study/index.vue')
        }, {
            path: 'detail',
            component: async() =>
                import ('../views/teacher/study/detail.vue')
        }, {
            path: 'videoLearning',
            component: async() =>
                import ('../views/teacher/study/videoLearning.vue')
        }]
    }, {
        path: 'repository',
        children: [{
            path: 'list',
            component: async() =>
                import ('../views/teacher/repository/list.vue')
        }, {
            path: 'works',
            component: async() =>
                import ('../views/teacher/repository/works.vue')
        }, {
            path: 'detail',
            component: async() =>
                import ('../views/teacher/repository/detail.vue')
        }]
    }]
}]
const router = createRouter({
    history: createWebHashHistory(),
    routes,
})
export default router;