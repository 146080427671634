<template>
  <div class="systemSelection">
    <div class="systemBlock">
      <div class="systemList" v-for="(item,index) in rolesList" :key="index" @click="handleSelect(item)" :class="{seleceStyle:roleName==item.roleName}">
        <img v-if="roleName==item.roleName" class="selectIcon" src="../assets/image/selectIcon.png" alt="">
        <img v-if="item.roleName=='授课教师'" class="systemImg" src="../assets/image/teacherSys.png" alt="">
        <img v-if="item.roleName=='学校系统管理员' || item.roleName=='管理员' || item.roleName=='系统管理员'" class="systemImg " src="../assets/image/jcsjSys.png" alt="">
        <div class="sysName">{{item.roleName}}</div>
      </div>
    </div>
    <div class="enterBtn" @click="handleEnter">进入平台</div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {

  },
  setup() {
    const rolesList=ref({})
    const router = useRouter()
    const roleName=ref(null)

    onMounted(() => {
      getUserInfo()
    })
    
    const getUserInfo=()=>{
      defHttp.get('/system/account/userinfo').then(data=>{
        if(data.Success){
          rolesList.value=data.Data.roles
          roleName.value=rolesList.value[0].roleName
          localStorage.setItem('userInfo',JSON.stringify(data.Data))

        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const handleSelect=(item)=>{
      roleName.value=item.roleName
    }

    const handleEnter=()=>{
      if(roleName.value=='授课教师'){
        router.push('/teacher/study/index')
      }else if(roleName.value=='学校系统管理员'){
        window.location.href="/basedata/index.html"
      }
    }

    return {
      rolesList,
      handleSelect,
      roleName,
      handleEnter
    };
  },
})
</script>
<style scoped>
.systemSelection {
  width: 100%;
  height: 100vh;
  background-image: url('../assets/image/systemSelection.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}
.systemBlock{
  display: flex;
  align-items: center;
  justify-content: center;
}
.systemList{
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #FFF;
  background: #FFF;
  margin: 0 12px;
  cursor: pointer;
  color: #231815;
  position: relative;
}
.systemImg{
  width: 320px;
  height: 240px;
}
.sysName{
  margin-top: 12px;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
}
.seleceStyle{
  border-color: #0960BD;
  box-shadow: 0px 4px 24px 0px rgba(9, 96, 189, 0.24);
  color: #0960BD;
}
.enterBtn{
  margin-top: 48px;
  width: 384px;
  height: 48px;
  border-radius: 44px;
  background-color: #0960BD;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.selectIcon{
  width: 28px;
  height: 28px;
  position: absolute;
  top: 12px;
  left: 12px;
}
</style>
