<template>
  <div class="prolist">
    <div class="worksContainer" @scroll="handleScroll">
      <a-card :bordered="false">
        <a-space>
          <!-- <a-input v-model:value="searchForm.name" placeholder="搜索学校/学生名称" allow-clear /> -->
          <a-select v-model:value="searchForm.modelId" placeholder="筛选模型分类" allowClear style="width:200px;">
            <a-select-option :value="item.Id" v-for="(item,index) in modelList" :key="index">{{item.Name}}</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.styleId" placeholder="筛选风格分类" allowClear style="width:200px;">
            <a-select-option :value="item.Id" v-for="(item,index) in styleList" :key="index">{{item.Name}}</a-select-option>
          </a-select>
          <a-select v-model:value="searchForm.enable" placeholder="是否启用" allowClear style="width:200px;">
            <a-select-option value="true">启用</a-select-option>
            <a-select-option value="false">禁用</a-select-option>
          </a-select>
          <a-range-picker v-model:value="searchForm.PassTime" :placeholder="['开始时间', '结束时间']" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" style="width:200px;" />
          <a-button type="primary" @click="getWorksList">搜索</a-button>
        </a-space>
        <!-- <a-spin :spinning="table.loading">
          <div class="worksList" id="myElement">
            <div class="worksItem" v-for="(item,index) in table.list" :key="index">
              <img class="worksImg" :src="JSON.parse(item.Img)[0].url" @click="setDetail(item)" @load="imgOnLoad" />
              <a-button class="editBtn" type="primary" @click="edit(item)" v-if="item.CustomerId==userId">编辑</a-button>
            </div>
          </div>
        </a-spin> -->
        <Waterfall :list="table.list" :breakpoints="options.breakpoints" rowKey="Id">
            <!-- v2.6.0之前版本插槽数据获取 -->
            <!-- <template #item="{ item, url, index }"> -->
            <!-- 新版插槽数据获取 -->
            <template #default="{ item }">
              <div class="card">
                <LazyImg :url="JSON.parse(item.Img)[0].url" style="border-radius: 8px;cursor: pointer;"  @click="setDetail(item)" />
              </div>
            </template>
        </Waterfall>

        <div class="loadmorebox" style="padding:24px 16px 16px 16px;display: flex;justify-content: center;">
          <a-spin v-show="table.loading"></a-spin>
        </div>
      </a-card>
    </div>

    <a-modal v-model:visible="workFormModal.visible" centered width="800px" title="编辑作品" destroyOnClose>
      <section class="workFormModal">
        <a-form :model="workFormModal.form" :rules="workFormModal.rules" ref="formIns" :labelCol="{span:4}" :wrapperCol="{span:12}">
          <a-form-item label="模型类型" name="ModelId" :wrapper-col="{ span: 12}">
            <a-select v-model:value="workFormModal.form.ModelId" placeholder="选择模型类型">
              <a-select-option :value="item.Id" v-for="(item,index) in modelList" :key="index">{{item.Name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="风格类型" name="StyleId" :wrapper-col="{ span: 12}">
            <a-select v-model:value="workFormModal.form.StyleId" placeholder="选择风格类型">
              <a-select-option :value="item.Id" v-for="(item,index) in styleList" :key="index">{{item.Name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="标签" name="Tags">
            <a-select v-model:value="workFormModal.form.Tags" mode="tags" :token-separators="[',']" placeholder="请输入标签" ></a-select>
          </a-form-item>
          <a-form-item label="作品介绍" name="Desc" :wrapperCol="{span:16}">
            <Tinymce v-model="workFormModal.form.Desc" width="100%" :height="500"/>
          </a-form-item>
        </a-form>
      </section>
      <template #footer>
        <a-button type="primary" @click="preservation" :loading="workFormModal.processing">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
  import {reactive, ref,onMounted,unref,toRaw,computed,defineComponent,onBeforeUnmount, nextTick} from 'vue';
  import defHttp from "@/utils/http.js";
  import { message, Modal } from 'ant-design-vue';
  import { LeftCircleOutlined, RightCircleOutlined,FileExcelFilled} from '@ant-design/icons-vue';
  import { Tinymce } from '@/components/tinymce/index';
  import {useRouter} from 'vue-router'
  import { LazyImg, Waterfall } from 'vue-waterfall-plugin-next'
  import 'vue-waterfall-plugin-next/dist/style.css'
  export default defineComponent({
    components: {
      LeftCircleOutlined,
      RightCircleOutlined,
      Tinymce,
      FileExcelFilled,
      LazyImg,
      Waterfall
    },
    setup() {
      const router=useRouter()
      const formIns = ref()
      const searchForm=ref({
        name:'',
        modelId:null,
        styleId:null,
        enable:true,
        PassTime:[],
      })
      const modelList=ref([])
      const styleList=ref([])
      const HasNextPage=ref(true)
      const userId=ref('')
      const table =ref({
        list: [],
        pageConfig: {
          size: 'small',
          current: 1,
          pageSize:30,
          total: 0,
          showTotal: (total) => { return `共 ${total}` }
        },
        loading:false,
      })
      const workFormModal =ref({
        visible:false,
        processing:false,
        form: {
          ModelId:null,
          StyleId:null,
          Desc:'',
          Tags:[],
        },
        rules: {
          ModelId: [{required: true,trigger: 'change',message: '该项必须选择',type:'string'}],
          StyleId: [{required: true,trigger: 'change',message: '该项必须选择',type:'string'}],
        }
      })
      function loadmore () {
        if(HasNextPage.value){
          table.value.pageConfig.current+=1
          getWorksList()
        } else if (table.value.pageConfig.current > 1) {
          message.warning(`没有更多了`)
        }
      }
      onMounted(() => {
        let userInfo=JSON.parse(localStorage.getItem('userInfo'))
        userId.value=userInfo.userId
        getWorksList().then(() => {
          // 开始观察
          intersectionObserver.observe(
            document.querySelector('.loadmorebox')
          );
        })
        getClassifyList()
        
      })


      var intersectionObserver = new IntersectionObserver(
        function (entries) {
          // 如果不可见，就返回
          if (entries[0].intersectionRatio <= 0) return;
          console.log('Loadmore');
          loadmore()
        }
      );

      


      onBeforeUnmount(() => {
        intersectionObserver.unobserve(document.querySelector('.loadmorebox'));
      })


      const getClassifyList=()=>{
        defHttp.get('trainer/PhotoWall/CategoryList', { params: {
          type:0,page:1,limit:999
        } }).then(data => {
          if (data.Success) {
            modelList.value=data.Data.Rows
          } else {
            message.error(`${data.Message}`)
          }
        })
        defHttp.get('trainer/PhotoWall/CategoryList', { params: {
          type:1,page:1,limit:999
        } }).then(data => {
          if (data.Success) {
            styleList.value=data.Data.Rows
          } else {
            message.error(`${data.Message}`)
          }
        })
      }

      // 获取列表
      const getWorksList=(isSearch = false)=>{
        return new Promise((resolve, reject) => {
          if(isSearch){
            table.value.pageConfig.current=1
          }
          table.value.loading = true
          const query = { ...toRaw(unref(searchForm.value)) }
          query.start = query.PassTime?query.PassTime[0]:''
          query.end = query.PassTime?query.PassTime[1]:''
          delete query.PassTime
          defHttp.get('trainer/PhotoWall/List', { params: {
              page: table.value.pageConfig.current,
              limit: table.value.pageConfig.pageSize,
              ...query,
          } }).then(data => {
              if (data.Success) {
                if (table.value.pageConfig.current == 1) {
                  table.value.list = data.Data.Rows
                } else {
                  table.value.list = [...table.value.list,...data.Data.Rows]
                }
                HasNextPage.value=data.Data.HasNextPage
                table.value.pageConfig.total = data.Data.TotalItemCount
              } else {
                message.error(`${data.Message}`)
              }
              table.value.loading=false
              resolve(true)
          }).catch(() => {
            table.value.loading=false
            reject(false)
          })
        })
      }

      const imgOnLoad=()=>{
        const waterfallBox = document.getElementById('myElement');
        const items = waterfallBox.children;
        const column = 5;
        const gap = 16;
        // 每个图片的宽度
        const itemsWidth = (waterfallBox.offsetWidth - gap * (column - 1)) / column;
        const miniHeightList = [];
        // 第一行铺满，后面的图片在高度最低的位置依次向下排列
        for (let i = 0; i < items.length; i++) {
          items[i].style.width = itemsWidth + "px";
          if(i < column){
            items[i].style.top = "0px";
            items[i].style.left = (itemsWidth + gap) * i + "px";
            miniHeightList.push(items[i].offsetHeight);
          }else{
            // 获取高度最低的索引
            let miniIndex = miniHeightList.indexOf(Math.min(...miniHeightList));
            items[i].style.top = miniHeightList[miniIndex] + gap + "px";
            items[i].style.left = (itemsWidth + gap) * miniIndex +"px";
            miniHeightList[miniIndex] += gap + items[i].offsetHeight;
          }
        }
      }
      
      const handleScroll=(event)=>{
        return
        const container = event.target;
        if (container.scrollHeight - container.scrollTop <= container.clientHeight) {
          if(HasNextPage.value){
            table.value.pageConfig.current+=1
            getWorksList()
          }else{
            message.warning(`没有更多了`)
          }
        }
      }

      // 查看详情
      const setDetail=(record)=>{
        router.push({ path: '/worksDetail', query: { id: record.Id } })
      }

      // 编辑作品基本信息
      const edit=(record)=>{
        defHttp.get('trainer/PhotoWall/Detail', { params: {id:record.Id} }).then(data => {
          if (data.Success) {
            workFormModal.value.form=data.Data
            try {
              workFormModal.value.form.Tags=JSON.parse(data.Data.Tags)
            } catch (err) {
              workFormModal.value.form.Tags=[]
            }
            workFormModal.value.visible=true
          } else {
            message.error(`${data.Message}`)
          }
        })
      }

      // 保存编辑
      const preservation=()=>{
        formIns.value.validate().then(() => {
          workFormModal.value.processing = true
          const formData = { ...toRaw(unref(workFormModal.value.form)) }
          formData.Tags=JSON.stringify(formData.Tags)
          defHttp.post('trainer/PhotoWall/Save',formData).then(data => {
          if (data.Success) {
            workFormModal.value.processing = false
            message.success(`保存成功`)
            workFormModal.value.visible=false
            getWorksList()
          } else {
            message.error(`${data.Message}`)
          }
        })
        }).catch(() => {workFormModal.value.processing = false})
      }

      const options = reactive({
        // 唯一key值
        rowKey: 'Id',
        // 卡片之间的间隙
        gutter: 10,
        // 是否有周围的gutter
        hasAroundGutter: true,
        // 卡片在PC上的宽度
        width: 320,
        // 自定义行显示个数，主要用于对移动端的适配
        breakpoints: {
          3840: {
            // 4K下
            rowPerView: 8,
          },
          2560: {
            // 2K下
            rowPerView: 7,
          },
          1920: {
            // 2K下
            rowPerView: 6,
          },
          1600: {
            // 2K下
            rowPerView: 5,
          },
          1366: {
            // 2K下
            rowPerView: 4,
          },
          800: {
            // 当屏幕宽度小于等于800
            rowPerView: 3,
          },
          500: {
            // 当屏幕宽度小于等于500
            rowPerView: 2,
          },
        },
        lazyload: true,
        crossOrigin: true,
      })
      return {
        formIns,
        searchForm,
        modelList,
        styleList,
        table,
        getWorksList,
        getClassifyList,
        setDetail,
        workFormModal,
        edit,
        preservation,
        userId,
        imgOnLoad,
        handleScroll,
        loadmore,
        HasNextPage,
        options
      };
    }
  })
</script>
<style lang="less" scoped>
.prolist {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
.worksContainer{
  height: calc(100vh - 150px);
  overflow-y: auto;
  background-color: #fff;
}
.ant-carousel :deep(.slick-slide){
  text-align: center;
  height: 450px;
  line-height: 450px;
  overflow: hidden;
}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}
.worksList{
  position:relative;
  margin-top: 16px;
}
.worksItem{
  position:absolute;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.worksImg{
  width: 100%;
  height: auto;
  display: block;
}
.editBtn{
  position:absolute;
  top:8px;
  right:8px;
}
.worksImg:hover{
  transform: scale(1.2);
  transition:transform 0.5s ease;
}
.worksImg:not(:hover){
  transform: scale(1);
  transition:transform 0.5s ease;
}
.workImg{
  height:450px;
  width:auto;
  margin:0 auto;
  overflow: hidden;
  border-radius: 8px;
}
.workFormModal{
  max-height: 600px;
  overflow-y: scroll;
}
:deep(.lazy__img[lazy=loaded]):hover {
  transform: scale(1.2);
  transition:transform 0.5s ease;
}
</style>