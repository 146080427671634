<template>
  <div class="courseBlock">
    <div class="courseBanner">
      <div class="curriculumType" style="display: flex;padding: 0;box-sizing: border-box;" >
        <img src="http://adzjgc.tumengkj.com/images/banner1.png" alt="" class="courseBannerImg" style="height: 345px">
        <div class="personInfo">
          <img style="width: 64px;height: 64px;border-radius: 50%;" v-if="userInfo.avatar" :src="userInfo.avatar" />
          <img style="width: 64px;height: 64px;border-radius: 50%;" v-else src="../../assets/image/defaultHaderImg.png" />
          <div style="font-weight: 600;font-size: 15px">{{ userInfo.username }}</div>
          <div v-if=" userInfo.className">{{ userInfo.college }} ~ {{ userInfo.className }}</div>
          <div v-if=" userInfo.SchoolName">{{ userInfo.SchoolName }}</div>
        </div>
      </div>
    </div>
    <div class="courseBanner" style="padding: 0;" v-if="noticeList.length">
      <div class="noticelist">
        <a-carousel autoplay :dot-position="'right'" :dots="false">
          <div class="noticitem" v-for="notice in noticeList" :key="notice.Id" @click="toNoticeDetail(notice.Id)">{{ notice.Title }}</div>
        </a-carousel>
      </div>
    </div>
    <div class="curriculum">
      <div class="curriculumType">
        <div class="curriculumTypeList">
          <div class="curriculumTab">
            <!-- <div class="direction">方向</div>
            <div class="verticalLine"></div> -->
            <div class="curriculumList" :class="isDevelop ? 'developStyle' : ''">
              <div class="curriculumItem" :class="activeId == item.id ? 'activeTab' : ''" v-for="(item, index) in curriculumList"
                :key="index" @click="handleSelect(item.id)">{{ item.name }}</div>
            </div>
          </div>
          <div v-if="!isDevelop" class="develop" @click="handleDevelop(true)">
            <span class="developText">展开</span>
            <DownOutlined />
          </div>
          <div v-if="isDevelop" class="develop" @click="handleDevelop(false)">
            <span class="developText">收起</span>
            <UpOutlined />
          </div>
        </div>
        <div class="classify">
          <div class="classifyItem">共{{courseLists.length}}门课程</div>
          <!-- <div class="classifyItem" v-for="(item, index) in sortOrder" :key="index" @click="handleSort(item.id)"
            :class="item.id == sortType ? 'activeSort' : ''">{{ item.name }}</div> -->
        </div>
      </div>
    </div>
    <a-spin :spinning="spinning">
      <div class="courseCore">
        <div class="courseItemBlock" v-for="(item, index) in courseLists" :key="index">
          <div class="courseItem"  @click="handleDetail(item.id)">
            <img class="courseImg" :src="item.img" alt="">
            <div class="courseInfo">
              <div class="courseName">{{ item.name }}</div>
              <div class="otherInfo">
                <div class="teacher">{{ item.teacherName }}</div>
                <div class="classHour">共{{ item.allClassHour }}个课时</div>
              </div>
              <div class="Learned">已学{{ (item.progress * 100).toFixed(0) }}%</div>
              <a-progress :strokeWidth="4" :percent="item.progress * 100" :show-info="false" strokeColor="#0960bd"
                trailColor="#E5E5E5" />
              <div class="NextLesson" v-if="item.progress>0&&item.currentClassHour">学习进度：{{ item.currentClassHour.name }}</div>
            </div>
          </div>
        </div>
        <div class="courseItemBlock" ></div>
        <div class="courseItemBlock" ></div>
        <div class="courseItemBlock" ></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, unref, toRaw } from 'vue';
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    DownOutlined,
    UpOutlined
  },
  setup() {

    const userInfo = ref({
      className:'',
      avatar:'',
      username:''
    })
    const getBaseInfo=()=>{
      defHttp.get('/student/account/userInfo').then(data=>{
        if(data.Success){
          userInfo.value=data.Data
          localStorage.setItem('userInfo',JSON.stringify(userInfo.value))
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }
    let hadUserInfo = localStorage.getItem('userInfo')
    if (hadUserInfo) {
      try {
        userInfo.value = {...JSON.parse(hadUserInfo)}
      } catch (error) {
        getBaseInfo()
      }
    } else {
      getBaseInfo()
    }
    
    

    const router = useRouter()

    const isTeacher = window.localStorage.getItem('tokenType') == 'teacher'
    if (isTeacher) {
      router.push('/teacher/careerpath/index')
      return
    }

    const sortOrder = ref([
      { name: '最新上线', id: 1 },
      { name: '学习人数', id: 2 },
    ])
    const sortType = ref(1)
    const visible = ref(false);
    const spinning = ref(false)
    const isDevelop = ref(false)
    const curriculumList = ref([
      { name: '全部', id: -1 }
    ])
    const courseLists = ref([])
    const activeId = ref(-1)
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = e => {
      visible.value = false;
    };

    const handleSelect = (id) => {
      activeId.value = id
      getCourseList()
    }

    onMounted(() => {
      getDirectionList()
      if (localStorage.getItem('token-smartvg')) {
        getCourseList()
      }
    })

    const getDirectionList = () => {
      defHttp.get('/student/direction').then(data => {
        if (data.Success) {
          curriculumList.value.push(...data.Data)
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const handleSort = (type) => {
      sortType.value = type
      getCourseList()
    }

    const getCourseList = () => {
      spinning.value = true
      defHttp.get('/student/course/list', { params: { directionId: activeId.value, number: sortType.value } }).then(data => {
        spinning.value = false
        if (data.Success) {
          courseLists.value = data.Data
          courseLists.value.forEach(item => {
            if(item.progress=='NaN'){
              item.progress=0
            }
            item.currentClassHour=item.currentClassHour[0]
          })
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { spinning.value = false })
    }

    const handleDetail = (id) => {
      router.push({ path: '/courseDetail', query: { courseId: id } })
    }

    const handleDevelop = (type) => {
      isDevelop.value = type
    }

    const noticeList = ref([])
    const getnoticeList = () => {
      defHttp.get('/system/Notice/List', { params: { page: 1, limit: 10 } }).then(data => {
        if (data.Success) {
          noticeList.value = data.Data.Rows
        } 
      }).catch(err => {  })
    }
    getnoticeList()
    const toNoticeDetail = (id) => {
      router.push({ path: '/noticeDetail', query: { id } })
    }
    return {
      visible,
      showModal,
      toNoticeDetail,
      handleOk,
      curriculumList,
      activeId,
      handleSelect,
      handleDetail,
      getCourseList,
      spinning,
      courseLists,
      sortOrder,
      sortType,
      handleSort,
      handleDevelop,
      isDevelop,
      userInfo,
      noticeList
    };
  },
});
</script>

<style scoped>
.noticelist {
  width: 70%;
  min-width: 1200px;
  background-color: #fff;
  height: 50px;
  margin-bottom: 16px;
}
.noticitem {
  width: 100%;
  height: 50px;
  padding: 0 16px;
  box-sizing: border-box;
  line-height: 50px;
  cursor: pointer;
}
.courseBannerImg {
  flex: 1;
  object-fit: cover;
}
.courseBanner{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
}
.personInfo {
  margin-left: 16px;
  width: 284px;
  height: 345px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
}
.personInfo div{
  margin-top: 12px;
  text-align: center;
}

.courseBlock {
  min-height: 100%;
  background-color: #f0f2f5;
}

.curriculum {
  width: 100%;
  padding: 21px 0 12px 0;
  /* height: 98px; */
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.16);
}

.curriculumType {
  width: 70%;
  min-width: 1200px;
  margin: 0 auto;
}

.curriculumTypeList {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.curriculumTab {
  display: flex;
}

.direction {
  min-width: 40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  color: #333333;
}

.verticalLine {
  width: 2px;
  height: 31px;
  background: #CCCCCC;
  margin: 0 20px;
}

.curriculumList {
  display: flex;
  max-height: 45px;
  overflow: hidden;
  flex-wrap: wrap;
}

.curriculumItem {
  margin-right: 10px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #545c63;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 15px;
}

.curriculumItem:hover{
  color: #FFFFFF;
  font-weight: bold;
  background: #545c63;
  border-radius: 15px;
}

.activeTab {
  color: #FFFFFF;
  font-weight: bold;
  background: #545c63;
  border-radius: 15px;
}

.develop {
  min-width: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 33px;
  color: var(--theme);
  cursor: pointer;
}

.developText {
  margin-right: 10px;
}

.classify {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}

.classifyItem {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  margin-left: 28px;
  cursor: pointer;
}

.courseCore {
  width: 70%;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
.courseItemBlock{
  width: 22.5%;
  min-width: 275px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
}
.courseItem {
  width:100%;
  max-height: 300px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(199, 215, 225, 0.31);
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
}

.courseImg {
  width: 100%;
  height: 149px;
  object-fit: cover;
}

.courseInfo {
  padding: 13px 12px 21px 12px;
}

.courseName {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.otherInfo {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
}

.Learned {
  margin-top: 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--theme);
}

.NextLesson {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
}

.teacher {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeSort {
  color: var(--theme);
}

.developStyle {
  max-height: none !important;
}

</style>