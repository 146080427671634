// Any plugins you want to setting has to be imported
// Detail plugins list see https://www.tinymce.com/docs/plugins/
// Custom builds see https://www.tinymce.com/download/custom-builds/
// colorpicker/contextmenu/textcolor plugin is now built in to the core editor, please remove it from your editor configuration

export const plugins = [
    'advlist anchor autolink autosave code codesample  directionality  fullscreen hr insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace tabfocus  template  textpattern visualblocks visualchars wordcount',
  ];
  
  export const toolbar = [
    'fontsizeselect lineheight searchreplace bold italic underline strikethrough alignleft aligncenter alignright blockquote undo redo removeformat code',
    'hr bullist numlist link preview pagebreak insertdatetime customUpload forecolor backcolor fullscreen',
  ];