<template>
    <div>
        <a-modal title="公告" width="60%" v-model:visible="visible" :bodyStyle="{ padding: '4px' }" destroyOnClose @cancel="closeModal" :maskClosable="false">
            <a-table :data-source="table.list" size="small" :scroll="{ y: height - 8 }" row-key="Id"
                :rowSelection="table.rowSelection" :loading="table.loading" class="ant-table-striped border padding20"
                :rowClassName="(_, index) => (index % 2 === 1 ? 'table-striped' : null)" :pagination="table.pageConfig"
                :columns="table.columns" @change="table.pageChange">
                <template #title>
                    <div class="aj-margin-tb-sm aj-flex aj-justify-between aj-align-center">
                        <a-space>
                            <a-input-search v-model:value="searchForm.search" placeholder="请输入关键词搜索" @search="getList" />
                        </a-space>
                        <a-space>
                            <a-button type="default" @click="showAdd(null)">新增公告</a-button>
                            <a-button type="primary" @click="mutilRemove"
                                :disabled="table.rowSelection.selectedRowKeys.length == 0">批量删除</a-button>
                            <a-button type="default" @click="mutilPublish"
                                :disabled="table.rowSelection.selectedRowKeys.length == 0">批量发布</a-button>
                        </a-space>
                    </div>
                </template>
                <template #bodyCell="{ record, column }">
                    <div class="aj-flex aj-justify-between aj-align-center">
                        <div>
                            <div>
                                <span class="aj-margin-right-sm aj-margin-bottom-lg text-df">{{ record.Title }}</span>
                                <a-tag :color="record.Status == 0 ? '' : 'error'">{{ record.StatusDesc }}</a-tag>
                            </div>
                            <div class="text-gray text-xs">{{ record.TheContent }}</div>
                            <div class="aj-margin-tb-xs text-gray">{{ record.CreateDate }}</div>
                        </div>
                        <div>
                            <div>
                                <div style="text-align: right;">
                                    <a-button type="primary" @click="publish(record)">发布公告</a-button>
                                </div>
                                <div class="aj-margin-top">
                                    <a-button type="link" size="small" @click="handlePreview(record.Id)">预览</a-button>
                                    <a-button type="link" size="small" @click="showAdd(record)" v-if="record.Status==0">修改</a-button>
                                    <a-popconfirm title="确认删除该公告吗?" okText="确定" cancelText="取消" @confirm="remove(record)">
                                        <a-button type="text" size="small" style="color:#999;">删除</a-button>
                                    </a-popconfirm>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </a-table>
            <template #footer>
                <a-space>
                    <a-button type="primary" @click="closeModal">取消</a-button>
                    <a-button type="default" @click="closeModal">确定</a-button>
                </a-space>
            </template>
        </a-modal>

        <a-modal :title="formModal.form.Id==0?'新增公告':'编辑公告'" width="50%" centered v-model:visible="formModal.visible" destroyOnClose :maskClosable="false">
            <a-form :model="formModal.form" :rules="formModal.rules" ref="formIns">
                <a-form-item name="Title" label="公告标题">
                    <a-input v-model:value="formModal.form.Title" :maxlength="50" placeholder="不超过50个字符" />
                </a-form-item>
                <a-form-item name="TheContent" label="公告内容" ref="editor" :autoLink="false">
                    <a-textarea v-model:value="formModal.form.TheContent" placeholder="请输入公告内容" :rows="4" style="resize:none" />
                </a-form-item>
                <a-form-item name="TheContent" label="公告图片" ref="editor" :autoLink="false">
                    <div class="aj-flex aj-flex-wrap">
                        <div class="aj-margin-top" v-for="(item,index) in formModal.form.Images" :key="index">
                            <div class="imageBlock aj-margin-right-sm">
                                <img class="uploadImg" :src="item.Url" alt="">
                                <div class="deleteImg" @click="handleDeleteImg(index)">删除</div>
                            </div>
                        </div>
                        <a-upload v-if="formModal.form.Images.length<8" v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequestImg" :before-upload="beforeUploadImg" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                            <div class="uploadBtn aj-margin-top">
                                <div class="uploadIcon">
                                    <plus-outlined />
                                </div>
                                <span class="uploadText">上传图片</span>
                            </div>
                        </a-upload>
                        </div>
                </a-form-item>
            </a-form>
            <template #footer>
                <a-space>
                    <a-button type="default" @click="() => formModal.visible = false">取消</a-button>
                    <a-button type="primary" @click="formModal.save" :loading="formModal.loading">确定</a-button>
                </a-space>
            </template>
        </a-modal>

        <a-modal title="预览公告" width="40%" centered v-model:visible="previewVisible" destroyOnClose :footer="null" :maskClosable="false">
            <div class="PreviewModal">
                <div class="formItem">
                    <div class="NoticeTitle">公告标题：</div>
                    <div class="NoticeConetnt">{{previewInfo.Title}}</div>
                </div>
                <div class="formItem">
                    <div class="NoticeTitle">公告内容：</div>
                    <div class="NoticeConetnt">{{previewInfo.TheContent}}</div>
                </div>
                <div class="formItem">
                    <div class="NoticeTitle">公告图片：</div>
                    <div class="NoticeConetnt">
                        <div class="aj-flex aj-flex-wrap">
                            <div v-for="(item,index) in previewInfo.Images" :key="index"  style="margin:8px 8px 0 0;">
                                <a-image :width="120" :height="120" :src="item.Url" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref, watch,toRaw,unref } from 'vue'
import defHttp from "@/utils/http.js"
import { message, notification } from 'ant-design-vue'
import { plusCircleFilled,PlusOutlined } from '@ant-design/icons-vue';
export default defineComponent({
    components: {plusCircleFilled,PlusOutlined},
    emits: ['update:show'],
    props: {
        show: {
            type: Boolean
        }
    },
    setup(props, { emit }) {
        const visible = ref(false)
        const previewVisible=ref(false)
        const previewInfo=ref({})
        const formIns = ref()
        const fileList = ref([])
        const formModal = ref({
            visible: false,
            loading: false,
            form: {
                Id:0,
                Title: '',
                TheContent: '',
                Images:[],
            },
            rules: {
                Title: [{
                    required: true,
                    trigger: 'blur',
                    message: '该项必填'
                }],
                TheContent: [{
                    required: true,
                    trigger: 'change',
                    message: '该项必填'
                }]
            },
            save: () => {
                formIns.value.validate().then(() => {
                    formModal.value.loading = true
                    const formDataList = { ...toRaw(unref(formModal.value.form)) }
                    formDataList.Images=JSON.stringify(formDataList.Images)
                    defHttp.post('/system/message/save', {...formDataList}).then(data => {
                        if (data.Success) {
                            notification.success({
                                message: '提示',
                                description: '保存成功',
                                duration: 2,
                                onClose: () => {
                                    formModal.value.visible = false
                                    getList()
                                }
                            })

                        } else {
                            message.error(data.Message)
                        }
                    }).catch(() => { }).finally(() => { formModal.value.loading = false })
                }).catch(() => { })
            }
        })

        const table = ref({
            list: [],
            rowSelection: {
                columnWidth: 40,
                fixed: true,
                selectedRowKeys: [],
                selectedRows: [],
                onChange: (keys, rows) => {
                    table.value.rowSelection.selectedRowKeys = keys
                    table.value.rowSelection.selectedRows = rows
                }
            },
            columns: [{
                dataIndex: 'TheContent',
                title: false,
            }],
            pageConfig: {
                size: 'small',
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total) => { return `共 ${total}` }
            },
            loading: false,
            pageChange: ({ current, pageSize }) => {
                table.value.pageConfig.current = current
                table.value.pageConfig.pageSize = pageSize
                getList()
            }
        })

        const searchForm = ref({
            search: '',
        })

        const height = ref(document.getElementById('app').clientHeight * 0.74)

        if (props.show) {
            visible.value = props.show
        }

        watch(() => props.show, (val) => {
            visible.value = val
            if (val) {
                getList()
            }
        })

        const confirmPublish = (ids) => {
            table.value.loading = true
            defHttp.post('/system/message/publish', ids).then(data => {
                if (data.Success) {
                    notification.success({
                        message: '提示',
                        description: '发布成功',
                        duration: 2,
                        onClose: () => {
                            getList()
                        }
                    })
                } else {
                    message.error(data.Message)
                }
            }).catch(() => { }).finally(() => {
                table.value.loading = false
            })
        }

        const confirmRemove = (ids) => {
            table.value.loading = true
            defHttp.post('/system/message/delete', ids).then(data => {
                if (data.Success) {
                    notification.success({
                        message: '提示',
                        description: '删除成功',
                        duration: 2,
                        onClose: () => {
                            getList()
                        }
                    })
                } else {
                    message.error(data.Message)
                }
            }).catch(() => { }).finally(() => {
                table.value.loading = false
            })
        }

        const publish = ({ Id }) => {
            confirmPublish([Id])
        }

        const remove = ({ Id }) => {
            confirmRemove([Id])
        }

        const mutilPublish = () => {
            confirmPublish(table.value.rowSelection.selectedRowKeys)
        }

        const mutilRemove = () => {
            confirmRemove(table.value.rowSelection.selectedRowKeys)
        }

        const showAdd = (record) => {
            if(record){
                formModal.value.form.Id=record.Id
                formModal.value.form.Title=record.Title
                formModal.value.form.TheContent=record.TheContent
                formModal.value.form.Images=JSON.parse(record.Images)
            }else{
                formModal.value.form = {
                    Id:0,
                    Title: '',
                    TheContent: '',
                    Images:[],
                }
            }
            
            formModal.value.visible = true

        }

        const closeModal = () => {
            visible.value = false
            emit('update:show', false)
        }

        const getList = () => {
            table.value.loading = true
            defHttp.get('/system/message/list', {
                params: {
                    search: searchForm.value.search,
                    page: table.value.pageConfig.current,
                    limit: table.value.pageConfig.pageSize
                }
            }).then(data => {
                if (data.Success) {
                    table.value.pageConfig.total = data.Data.TotalItemCount
                    table.value.list = data.Data.Rows
                } else {
                    message.error(data.Message)
                }

            }).catch(() => { }).finally(() => { table.value.loading = false })
        }

        const handlePreview=(id)=>{
            defHttp.get('/system/message/list', {
                params: {
                    search: id,
                    page:1,
                    limit:1
                }
            }).then(data => {
                if (data.Success) {
                    previewInfo.value= data.Data.Rows[0]
                    previewInfo.value.Images=JSON.parse(previewInfo.value.Images)
                    previewVisible.value=true
                } else {
                    message.error(data.Message)
                }

            })
        }

        const beforeUploadImg = (file) => {
            let size = file.size / 1024 / 1024
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG file!');
                return false
            }
            if (size > 20) {
                createMessage.error('上传文件太大',1.5,)
                return false
            }
            return true;
        }

        const customRequestImg=(file)=>{
            const formData = new FormData();
            formData.append("file",file.file);
            defHttp.post('/platform/Common/Upload',formData).then(data=>{
                if(data.Success){
                    let list={
                        Url:data.Data[0],
                        Id:file.file.uid,
                    }
                    formModal.value.form.Images.push(list)
                }else{
                    message.error(`${data.Message}`)
                }
            })
            
        }

        const handleDeleteImg=(index)=>{
            formModal.value.form.Images.splice(index,1)
        }

        return {
            visible,
            height,
            closeModal,
            searchForm,
            table,
            showAdd,
            mutilPublish,
            mutilRemove,
            getList,
            publish,
            remove,
            formIns,
            formModal,
            previewInfo,
            handlePreview,
            previewVisible,
            fileList,
            customRequestImg,
            beforeUploadImg,
            handleDeleteImg
        }
    },
})
</script>
<style scoped>
.ant-table-wrapper thead.ant-table-header tr {
    display: none;
}
.padding20{
    padding: 20px;
}
.formItem{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-bottom: 24px;
    display: flex;
}
.NoticeTitle{
    width: 80px;
    min-width: 80px;
}
.NoticeConetnt{
    flex: 1;
}
.PreviewModal{
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
}
.PreviewModal::-webkit-scrollbar {
    display: none;
}
.uploadBtn{
    width: 120px;
    height: 120px;
    background: #F7F9FC;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.uploadIcon{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #0A5AFF;
    color: #FFFFFF;
    text-align: center;
    line-height: 24px;
}
.uploadText{
    margin-top: 3px;
    font-size: 12px;
    font-weight: 400;
    color: #8D9199;
    line-height: 14px;
}
.imageBlock{
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}
.uploadImg{
  width: 100%;
  height: 100%;
}
.deleteImg{
  width: 100%;
  height: 28px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(51,51,51,0.6);
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

</style>
