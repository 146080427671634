<template>
  <div class="myCenter">
    <div class="center">
      <div class="infoBlock">
        <div class="userInfo">
          <img class="userImg" v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
          <img class="userImg" v-else src="../../assets/image/defaultHaderImg.png" alt="">
          <div class="baseInfo">
            <div class="userName">{{userInfo.username}}</div>
            <div class="school">{{userInfo.college}}学院 {{userInfo.specializedSubject}}专业 {{userInfo.grade}}级 {{userInfo.className}}班</div>
          </div>
        </div>
        <div class="study">
          <div class="total">累计学习时长:</div>
          <div class="duration">{{userInfo.learningDuration}}</div>
        </div>
      </div>
    </div>
    <a-spin :spinning="spinning">
      <div class="content">
        <div class="functionList">
          <div class="functionItem" :class="activeTab==1?'activeTab':''" @click="handleSelect(1)">
            <img class="funIcon " src="../../assets/image/functionIcon1.png" alt="">
            <span>课程收藏</span>
          </div>
          <div class="functionItem" :class="activeTab==2?'activeTab':''" @click="handleSelect(2)">
            <img class="funIcon" src="../../assets/image/functionIcon2.png" alt="">
            <span>错题集</span>
          </div>
          <div class="functionItem" :class="activeTab==3?'activeTab':''" @click="handleSelect(3)">
            <img class="funIcon" src="../../assets/image/functionIcon3.png" alt="">
            <span>考核记录</span>
          </div>
        </div>
        <div class="courseList" v-if="activeTab==1">
          <div class="courseItem" v-for="(item,index) in collectList" :key="index" @click="handleDetail(item.id)">
            <img class="courseImg" :src="item.img" alt="">
            <div class="courseInfo">
              <div class="courseName">{{item.name}}...</div>
              <div class="otherInfo">
                <div class="teacher">{{item.teacherName}}</div>
                <div class="classHour">共{{item.allClassHour}}个课时</div>
              </div>
              <div class="Learned">已学{{(item.progress*100).toFixed(0)}}%</div>
              <a-progress :strokeWidth="4" :percent="item.progress*100" :show-info="false" strokeColor="#0960bd" trailColor="#E5E5E5" />
              <div class="NextLesson">第{{item.currentClassHour.number}}课时 {{item.currentClassHour.name}}</div>
            </div>
          </div>
          <div class="emptyBlock" v-if="!collectList.length">
            <a-empty description="暂无数据" />
          </div>
        </div>
        <div class="questionBlock" v-if="activeTab==2">
          <div class="questionInfo">
            <div class="flex">
              <div class="questionShow">
                <div class="questionNum">{{statisticsInfo.todayAmount}}</div>
                <div class="questionType">今日错题</div>
              </div>
              <div class="cutApart"></div>
              <div class="questionShow">
                <div class="questionNum">{{statisticsInfo.accumulativeTotal}}</div>
                <div class="questionType">累计错题</div>
              </div>
            </div>
            <div class="operate">
              <div class="openType">
                <span class="aj-margin-right">答对后自动移除错题</span>
                <a-switch v-model:checked="statisticsInfo.isRemove" @change="handleOpen" />
              </div>
              <a-radio-group v-model:value="statisticsInfo.exactnessNum" @change="handleOpen" class="aj-margin-top-lg-important operateRadio">
                <a-space :size="14" >
                  <a-radio :value="1">答对一次移除</a-radio>
                  <a-radio :value="2">答对三次移除</a-radio>
                </a-space>
              </a-radio-group>
            </div>
          </div>
          <div class="questionList">
            <div class="listHeader">
              <div class="listTitle">错误题目</div>
              <a-space :size="20">
                <a-button type="primary" @click="handleRehearse" v-if="questionList.length">开始错题重练</a-button>
                <a-input-search
                  v-model:value="searchValue"
                  placeholder="搜索错题"
                  style="width:176px"
                  class="searchInput"
                  @search="getQuestionList"
                />
              </a-space>
            </div>
            <div class="questionTable">
              <div class="tableHeader tableHeaderTitle">
                <div class="num">序号</div>
                <div class="questionTitle">题目名称</div>
                <div class="errorsNum">错误次数</div>
                <div class="tableOperate">操作</div>
              </div>
              <div class="tableHeader" v-for="(item,index) in questionList" :key="index">
                <div class="num">{{index+1}}</div>
                <div class="questionTitle">{{item.title}}</div>
                <div class="errorsNum">{{item.frequency}}</div>
                <div class="tableOperate">
                  <span @click="handleSeeDetail(item.id)">查看详情</span>
                </div>
              </div>
              <div class="aj-padding-top-lg">
                <a-empty v-if="!questionList.length" description="暂无数据" />
              </div>
            </div>
          </div>
        </div>
        <div class="examine" v-if="activeTab==3">
          <div class="examineInfo">
            <div class="examineInfoType">
              <img class="examineIcon" src="../../assets/image/examineIcon1.png" alt="">
              <div class="examineData">
                <div class="examineNum">
                  <span>{{examineInfo.certificateNum}}</span>份
                </div>
                <div class="examineType">通过考核职业</div>
              </div>
            </div>
            <div class="examineInfoType">
              <img class="examineIcon" src="../../assets/image/examineIcon2.png" alt="">
              <div class="examineData">
                <div class="examineNum">
                  <span>{{examineInfo.examineNum}}</span>次
                </div>
                <div class="examineType">参与考核次数</div>
              </div>
            </div>
          </div>
          <div class="questionTable aj-padding-top-xlimportant">
            <div class="tableHeader tableHeaderTitle">
              <div class="num">序号</div>
              <div class="certificate">证书名称</div>
              <div class="status">状态</div>
              <div class="examinationNum">考试次数</div>
              <div class="fraction">最高分</div>
              <div class="period">补考等待周期</div>
              <div class="operateBlock">操作</div> 
            </div>
            <div class="tableHeader" v-for="(item,index) in examineList" :key="index">
              <div class="num">{{index+1}}</div>
              <div class="certificate">{{item.title}}</div>
              <div class="status">{{item.statusDesc}}</div>
              <div class="examinationNum">{{item.examinationNum}}</div>
              <div class="fraction">{{item.highestScore}}</div>
              <div class="period">{{item.period}}天</div>
              <div class="operateBlock" @click="handleSee(item.id)"><span>查看试卷</span></div> 
            </div>
            <div class="aj-padding-top-lg">
              <a-empty v-if="!examineList.length" description="暂无数据" />
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <a-modal v-model:visible="detailVisible" title="错题详情" :footer="null" :maskClosable="false">
      <div>{{computeType(detailInfo.Type)}} {{detailInfo.TheContent}}</div>
      <div class="optionList">
        <div class="optionItem aj-padding-top" v-for="(item,index) in detailInfo.Options" :key="index">{{item.TheContent}}</div>
      </div>
      <div class="exactness">正确答案：{{detailInfo.CorrectAlphabet}}</div>
      <div class="wrong">你的答案：{{detailInfo.wrongAlphabet}}</div>
      <div class="aj-padding-top-sm">题目解析：{{detailInfo.Analysis}}</div>
      <div class="aj-padding-top-sm">知识点：{{detailInfo.knowledgePoints}}</div>
      <div class="aj-padding-top-sm aj-flex">
        <div>复学视频：</div>
        <div>
          <div class="videoUrl" v-for="(itm,i) in detailInfo.ResumptionVideo" :key="i" @click="handleSeeVideo(itm.VideoUrl)" >{{computeVideoName(itm.VideoUrl)}}</div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model:visible="videoVisible" width="50%" title="复学视频" :footer="null" destroyOnClose :maskClosable="false">
      <div class="videoStyle">
        <video :src="videoUrl" controls style="width:100%;height:100%;"></video>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,computed} from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import {useRoute, useRouter} from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    DownOutlined
  },
  setup() {
    const route = useRoute()
    const searchValue=ref('')
    const router=useRouter()
    const collectList=ref([])
    const statisticsInfo=ref({})
    const spinning=ref(false)
    const checked=ref(true)
    const radioValue=ref('1')
    const activeTab=ref( route.query.tab || 1)
    const questionList=ref([])
    const examineInfo=ref({})
    const examineList=ref([])
    const userInfo=ref({})
    const detailVisible=ref(false)
    const typeList=ref([])
    const detailInfo=ref({})
    const videoVisible=ref(false)
    const videoUrl=ref('')
    const alphabet=ref(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R'])

    onMounted(() => {
      getTypeList()
      getBaseInfo()
      handleSelect(activeTab.value)
    })

    const computeVideoName=computed(()=>{
      return (val)=>{
        if(val){
          var array = decodeURIComponent(val).split('/');
          var b = array[array.length-1];
          return b
        }
      }
    })

    const getTypeList=()=>{
      defHttp.get('platform/Common/Enum',{params:{name:'QuestionType'}}).then(data=>{
        if(data.Success){
          typeList.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const getBaseInfo=()=>{
      defHttp.get('/student/account/userInfo').then(data=>{
        if(data.Success){
          userInfo.value=data.Data
          localStorage.setItem('userInfo',JSON.stringify(userInfo.value))
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const computeType=(val)=>{
      if(val!=undefined){
        return typeList.value.find(p=>p.id==val).name
      }
    }

    const getCollectList=()=>{
      spinning.value=true
      defHttp.get('/student/collect/list').then(data=>{
        spinning.value=false
        if(data.Success){
          collectList.value=data.Data
          collectList.value.forEach(item=>{
            if(item.progress=='NaN'){
              item.progress=0
            }
          })
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const getQuestionStatistics=()=>{
      spinning.value=true
      defHttp.get('/student/wrongQuestion/statistics').then(data=>{
        spinning.value=false
        if(data.Success){
          statisticsInfo.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleDetail=(id)=>{
      router.push({path:'/courseDetail',query:{courseId:id}})
    }

    const handleSelect=(id)=>{
      activeTab.value=id
      if(activeTab.value==1){
        getCollectList()
      }else if(activeTab.value==2){
        getQuestionStatistics()
        getQuestionList()
      }else{
        getExamineInfo()
        getExamineList()
      }
    }

    const getQuestionList=()=>{
      spinning.value=true
      defHttp.get('/student/wrongQuestion/list',{params:{search:searchValue.value}}).then(data=>{
        spinning.value=false
        if(data.Success){
          questionList.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleOpen=(e)=>{
      let params={
        isRemove:statisticsInfo.value.isRemove,
        exactnessNum:statisticsInfo.value.exactnessNum
      }
      defHttp.post('/student/wrongQuestion/saveConfig',params).then(data=>{
        if(data.Success){
          message.success('切换成功')
          getQuestionStatistics()
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const getExamineInfo=()=>{
      defHttp.get('/student/examine/statistics').then(data=>{
        if(data.Success){
          examineInfo.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const getExamineList=()=>{
      spinning.value=true
      defHttp.get('/student/examine/list').then(data=>{
        spinning.value=false
        if(data.Success){
          examineList.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleSeeDetail=(id)=>{
      detailVisible.value=true
      defHttp.get('/student/wrongQuestion/practiseList',{params:{isAll:false,id:id}}).then(data=>{
        if(data.Success){
          detailInfo.value=data.Data[0]
          detailInfo.value.Options.forEach((item,index)=>{
            item.TheContent=alphabet.value[index]+' '+item.TheContent
          })
          detailInfo.value.wrongAnswerId=JSON.parse(detailInfo.value.wrongAnswerId)
          detailInfo.value.ResumptionVideo=JSON.parse(detailInfo.value.ResumptionVideo)
          detailInfo.value.CorrectAlphabet=computeAnswer()
          detailInfo.value.wrongAlphabet=computeStuAnswer()
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const computeStuAnswer=()=>{
      let arr=detailInfo.value.wrongAnswerId
      let arr1=detailInfo.value.Options
      let name=''
      for(let i = 0;i < arr1.length;i++){
        for(let j = 0;j < arr.length;j++){
          if(arr1[i].Id === arr[j]){
            name+=alphabet.value[i]+' '
          }
        }
      }
      return name
    }

    const computeAnswer=()=>{
      let arr=JSON.parse(detailInfo.value.CorrectAnswerId)
      let arr1=detailInfo.value.Options
      let name=''
      for(let i = 0;i < arr1.length;i++){
        for(let j = 0;j < arr.length;j++){
          if(arr1[i].Id === arr[j]){
            name+=alphabet.value[i]+' '
          }
        }
      }
      return name
    }

    const handleRehearse=()=>{
      router.push({path:'/rehearse',query:{isAll:true,tab:activeTab.value}})
    }

    const handleSee=(id)=>{
      router.push({path:'/passed',query:{id:id,tab:activeTab.value}})
    }

    const handleSeeVideo=(url)=>{
      videoUrl.value=url
      videoVisible.value=true
    }

    return {
      handleDetail,
      checked,
      radioValue,
      activeTab,
      handleSelect,
      handleRehearse,
      getCollectList,
      spinning,
      collectList,
      statisticsInfo,
      getQuestionStatistics,
      searchValue,
      getQuestionList,
      questionList,
      handleOpen,
      getExamineInfo,
      examineInfo,
      examineList,
      userInfo,
      handleSee,
      handleSeeDetail,
      detailVisible,
      detailInfo,
      computeType,
      typeList,
      computeStuAnswer,
      alphabet,
      computeAnswer,
      videoUrl,
      videoVisible,
      handleSeeVideo,
      computeVideoName,
    };
  },
});
</script>

<style scoped>
.myCenter{
  width: 100%;
  background-color: #f0f2f5;
}
.center{
  background: linear-gradient(140deg, #EE574B 0%, #F87045 100%);
  box-shadow: 0px 4px 4px 0px rgba(217,217,217,0.16);
}
.infoBlock{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.userInfo{
  display: flex;
  padding: 47px 0 33px 0;
}
.userImg{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}
.baseInfo{
  padding-top: 2px;
  margin-left: 36px;
}
.userName{
  font-size: 24px;
  color: #FFFFFF;
  line-height: 28px;
}
.school{
  padding-top: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
}
.study{
  padding-top: 36px;
}
.total{
  font-size: 20px;
  color: #FFFFFF;
  line-height: 23px;
}
.duration{
  margin-top: 23px;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 28px;
  letter-spacing: 2px;
}
.courseItem{
  width: 263px;
  height: 300px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(199, 215, 225, 0.31);
  overflow: hidden;
  margin-bottom:20px;
  margin-left: 45px;
  cursor:pointer;
}
.courseImg{
  width: 100%;
  height: 149px;
}
.courseInfo{
  padding: 13px 12px 21px 12px;
}
.courseName{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.otherInfo{
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
}
.Learned{
  margin-top: 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--theme);
}
.NextLesson{
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
}
.content{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}
.functionList{
  width: 274px;
  max-height: 180px;
  min-width: 274px;
  background: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
}
.functionItem{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  background: #FFFFFF;
  cursor:pointer;
  border-top: 1px solid #f6f6f6;
} 
.funIcon{
  width: 20px;
  height: 20px;
}
.functionItem span{
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.courseList{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.questionBlock{
  flex: 1;
  margin-left: 10px;
}
.questionInfo{
  padding: 30px 40px 28px 40px;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 5px;
}
.questionShow{
  text-align: center;
}
.cutApart{
  height: 46px;
  opacity: 1;
  border: 1px solid #D8D8D8;
  margin: auto 40px;
}
.questionNum{
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}
.questionType{
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.openType{
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.operateRadio .ant-radio-wrapper{
  font-size: 10px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
}
.questionList{
  margin-top: 10px;
  padding: 20px 40px;
  background: #FFFFFF;
  border-radius: 5px;
}
.listHeader{
  display: flex;
  justify-content: space-between;
}
.listTitle{
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.questionTable{
  padding-top: 8px;
}
.tableHeader{
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #D8D8D8;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  text-align: center;
}
.tableHeaderTitle{
  font-size: 14px;
  line-height: 19px;
}
.num{
  width: 10%;
}
.questionTitle{
  width: 55%;
  text-align: left;
}
.errorsNum{
  width: 15%;
}
.tableOperate{
  width: 20%;
}
.tableOperate span{
  color: #55ABFF;
  cursor:pointer;
}
.examine{
  margin-left: 10px;
  flex: 1;
  padding: 30px 40px;
  background: #FFFFFF;
  border-radius: 5px;
}
.examineInfo{
  display: flex;
}
.examineInfoType{
  display: flex;
  margin-right: 57px;
}
.examineIcon{
  width: 42px;
  height: 42px;
  margin: auto 0;
}
.examineData{
  margin-left: 22px;
}
.examineNum{
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
}
.examineNum span{
  font-size: 22px;
}
.examineType{
  padding-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.certificate{
  width: 25%;
} 
.status{
  width: 10%;
}
.examinationNum{
  width: 15%;
}
.fraction{
  width: 10%;
}
.period{
  width: 20%;
}
.operateBlock{
  width: 10%;
}
.operateBlock span{
  color: #55ABFF;
  cursor:pointer;
}
.activeTab span{
  color:var(--theme)
}
:deep(.searchInput input){
  border-radius: 10px 0 0 10px !important;
}
:deep(.searchInput button){
  border-radius: 0 10px 10px 0 !important;
}
.emptyBlock{
  width: 100%;
  background-color: #FFFFFF;
  margin-left: 10px;
  border-radius: 5px;
  padding: 16px 0;
}
.exactness{
  color: #55ABFF;
  padding-top: 24px;
}
.wrong{
  color: #D9001B;
  padding-top: 8px;
}
.videoUrl{
  color: #55ABFF;
  cursor: pointer;
}
.videoStyle{
  width: 100%;
  height: 605px;
}
</style>