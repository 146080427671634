<template>
  <div class="courseBlock">
    <a-spin :spinning="spinning">
      <div class="courseInfo">
        <div class="courseCenter">
          <div class="tabs">
            <span class="returnHome" @click="handleReturn">课程学习> </span>
            <span>{{detail.name}}</span>
          </div>
          <div class="contentInfo">
            <div class="courseVideo">
              <!-- <img class="videoIcon" src="../../assets/image/videoIcon.png" alt=""> -->
              <video class="courseImg" controls v-if="detail.video" :src="detail.video"></video>
              <img class="courseImg" v-else :src="detail.img" alt="">
            </div>
            <div class="detailInfo">
              <div class="title">{{detail.name}}</div>
              <div class="introduce">
                <span class="introduceText">{{detail.synopsis}}{{isOverstep?'...':''}}</span>
                <span class="lookOver" v-if="allText.length>205&&isOverstep" @click="handleLookOver">查看</span>
              </div>
              <div class="funct">
                <div class="collect" v-if="!detail.collect" @click="handleCollect">收藏</div>
                <div class="collect cancel" v-else @click="handleCollect">取消收藏</div>
                <div v-if="detail.collect"></div>
                <div class="functBlock">
                  <div>授课老师：{{detail.teacherName}}</div>
                  <div class="margin-top-16">
                    <span>累计学习人数：</span>
                    <span class="people">{{detail.learnersNum}}人</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="courseUse">
        <a-tabs v-model:activeKey="activeKey" class="courseTab" >
          <!-- <a-tab-pane  tab="课程评价" >
            <div class="appraise" >
              <div class="appraiseClass">
                <div class="appraiseClassItem" v-for="(item,index) in appraiseClass" :key="index" @click="handleSelect(item.id)" :class="activeId==item.id?'activeTab':''">{{item.name}}</div>
              </div>
              <div class="individual">
                <img class="individualImg" src="../../assets/image/userHeader.png" alt="">
                <a-input-search
                  v-model:value="value"
                  size="large"
                  class="appraiseBlock"
                  placeholder="我也来说一句..."
                  enter-button="发送"
                  @search="onSearch"
                />
              </div>
              <div class="appraiseList" >
                <div class="appraiseItem" v-for="(item,index) in 3" :key="index">
                  <div class="appraiseInfo" >
                    <div class="userInfo">
                      <img class="userImg" src="../../assets/image/userHeader.png" alt="">
                      <div class="baseInfo">
                        <div class="userName">小豆子</div>
                        <div class="before">13小时前</div>
                      </div>
                    </div>
                    <div class="comeFrom">来自第一章-1.1课程引言</div>
                  </div>
                  <div class="appraiseContent">浅哥! 我来反馈一下，我了解到来看差多少大幅度和东方红客家话自己的说法，龙口粉丝第一方交换空间的时候第一方的可立即京刻就，还是等到收付款?丝第一方交换空间的时候第一</div>
                  <div class="function">
                    <div class="reply">回复</div>
                    <div class="report">举报</div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane> -->
          <a-tab-pane key="1" tab="课程介绍">
            <div class="appraise" style="padding:12px;">
              <div class="introduceContent" v-html="detail.introduce"></div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="课程目录">
            <div class="appraise">
              <div class="catalogue" v-for="(item,index) in catalogueList" :key="index">
                <div class="catalogueTitle">第{{index+1}}章 {{item.title}}</div>
                <div class="study" v-for="(itm,i) in item.catalogue" :key="i" @click="handleSee(item.id,itm.id)">
                  <CheckCircleOutlined v-if="itm.progress==1" class="accomplishIcon" />
                  <div class="studyIcon" v-else></div>
                  <div class="progress">
                    <div class="learned">已学</div>
                    <div class="accomplish" v-if="itm.progress==1">100%</div>
                    <div class="pace" v-else>{{itm.progress*100}}%</div>
                  </div>
                  <div class="type">{{itm.type}}</div>
                  <div class="catalogueName">{{i+1}}.{{itm.title}}</div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="3" tab="课程知识点">
            <div class="appraise">
              <a-table :data-source="catalogueList"
                        row-key="Id"
                        :pagination="false"
                        class="ant-table-striped"
                        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : 'table-striped')">
                <!-- <a-table-column data-index="key1" title="序号" align="center">
                  <template #default="{record}">
                    <div v-for="(item,index) in record.catalogue" :key="index">
                      <div class="knowledge" v-for="(itm,i) in item.knowledge" :key="i">{{computeNum(item.knowledge)}}</div>
                    </div>
                  </template>
                </a-table-column> -->
                <a-table-column data-index="title" title="章节" align="center">
                  <template #default="{record}">
                    <div class="chaptersTitle">{{record.title}}</div>
                  </template>
                </a-table-column>
                <a-table-column data-index="catalogue" title="课时" align="center">
                  <template #default="{record}">
                    <div class="catalogueTable" :style="{height:item.knowledge.length*60+'px',lineHeight:item.knowledge.length*60+'px'}" v-for="(item,index) in record.catalogue" :key="index">{{item.title}}</div>
                  </template>
                </a-table-column>
                <a-table-column data-index="knowledge" title="知识点" align="center">
                  <template #default="{record}">
                    <div v-for="(item,index) in record.catalogue" :key="index">
                      <div class="knowledge" v-if="!item.knowledge.length"></div>
                      <div class="knowledge" v-for="(itm,i) in item.knowledge" :key="i">{{itm.TagName}}</div>
                    </div>
                  </template>
                </a-table-column>
                <a-table-column data-index="time" title="出现时间" align="center">
                  <template #default="{record}">
                    <div v-for="(item,index) in record.catalogue" :key="index">
                      <div class="knowledge" v-if="!item.knowledge.length"></div>
                      <div class="knowledge" v-for="(itm,i) in item.knowledge" :key="i"> {{secondsToMinutes(itm.Start)}} ~ {{ secondsToMinutes(itm.End) }}</div>
                    </div>
                  </template>
                </a-table-column>
              </a-table>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,computed} from 'vue';
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import defHttp from "@/utils/http.js";
import {useRouter} from 'vue-router'
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    CheckCircleOutlined
  },
  setup() {
    const allText=ref('')
    const router=useRouter()
    let detailId=router.currentRoute.value.query.courseId
    const courseId=detailId
    const activeKey=ref('1')
    const spinning=ref(false)
    const isOverstep=ref(false)
    const detail=ref({})
    const catalogueList=ref([])
    const activeId=ref(0)
    const knowledgePoints=ref([])

    const computeNum = computed(() => {
      return (val) => {
      }
    })

    onMounted(() => {
      getCourseDetail()
      getCatalogue()
    })

    const handleReturn=()=>{
      router.push({path:'/course'})
    }

    const handleSelect=(id)=>{
      activeId.value=id
    }

    const getCourseDetail=()=>{
      spinning.value=true
      defHttp.get('/student/course/detail',{params:{id:courseId}}).then(data=>{
        spinning.value=false
        if(data.Success){
          detail.value=data.Data
          detail.value.introduce=data.Data.introduce.replace(/<img /ig,'<img style="width:100%" ')
          allText.value=data.Data.synopsis
          let imgUrl=JSON.parse(data.Data.img)
          detail.value.img=imgUrl[0].Url
          if(allText.value.length>205){
            isOverstep.value=true
            detail.value.synopsis=allText.value.substring(0,205)
          }else{
            detail.value.synopsis=allText.value
            isOverstep.value=false
          }
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleCollect=()=>{
      defHttp.get('/student/course/collect',{params:{id:courseId}}).then(data=>{
        if(data.Success){
          if(data.Data=='remove'){
            message.success('取消成功');
          }else{
            message.success('收藏成功');
          }
          getCourseDetail()
        }else{
          message.error(`${data.Message}`)
        }

      }).catch(err => {})
    }

    const handleLookOver=()=>{
      detail.value.synopsis=allText.value
      isOverstep.value=false
    }

    const getCatalogue=()=>{
      defHttp.get('/student/course/chapters',{params:{id:courseId}}).then(data=>{
        if(data.Success){
          catalogueList.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const Probation = ref(true) // true试用账号 false 正式账号
    let userInfo = {}
    const getIsTrial=()=>{
      defHttp.get('/trainer/School/schoolinfo?id=' + userInfo.SchoolId).then(data => {
        if (data.Success) {
          Probation.value = data.Data.Probation
        } 
      })
    }
    if (localStorage.getItem('userInfo')) {
      userInfo = JSON.parse(localStorage.getItem('userInfo'))
      getIsTrial()
    } else {
      defHttp.get('/system/account/userInfo').then(data => {
        if (data.Success) {
          userInfo = data.Data
          localStorage.setItem('userInfo',JSON.stringify(userInfo))
          getIsTrial()
        }
      }).catch(err => { })
    }

    const handleSee=(chaptersId,catalogueId)=>{
      let firstChapterId = catalogueList.value[0].catalogue[0].id
      if (Probation.value && firstChapterId != catalogueId) {
        message.success('试用账号无法查看此章节~')
        return 
      }
      router.push({path:'/videoLearning',query:{courseId:courseId,chaptersId:chaptersId,catalogueId:catalogueId,type:1}})
    }

    function secondsToMinutes(seconds) {
        let minutes = Math.floor(seconds / 60); // 获取分钟
        let remainingSeconds = seconds - minutes * 60; // 获取剩余秒数的百分比
        if(minutes > 0){
          return minutes.toFixed(0) + '分' + remainingSeconds.toFixed(0) + '秒'; // 返回总的分钟数加上剩余秒数的百分比
        } else {
          return remainingSeconds.toFixed(0) + '秒'; // 返回总的分钟数加上剩余秒数的百分比
        }
        
    }

    return {
      activeKey,
      handleSelect,
      activeId,
      spinning,
      detail,
      handleCollect,
      handleLookOver,
      allText,
      isOverstep,
      getCatalogue,
      catalogueList,
      handleReturn,
      knowledgePoints,
      computeNum,
      handleSee,
      secondsToMinutes
    };
  },
});
</script>

<style scoped>
.courseBlock{
  width: 100%;
  background-color: #f0f2f5;
}
.courseInfo{
  width: 100%;
  background: #FFFFFF;
}
.courseCenter{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 32px 0 68px 0;
}
.tabs{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.contentInfo{
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}
.courseVideo{
  width: 510px;
  min-width: 510px;
  height: 290px;
  border-radius: 10px;
  position: relative;
}
.videoIcon{
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.courseImg{
  width: 510px;
  min-width: 510px;
  height: 290px;
  border-radius: 10px;
  object-fit: cover;
}
.detailInfo{
  flex: 1;
  margin-left: 30px;
}
.title{
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}
.introduce{
  min-height: 167px;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  margin-top: 21px;
  padding: 17px 21px;
  box-sizing: border-box;
  background: #F6F6F6;
  border-radius: 10px;
  line-height: 24px;
  letter-spacing: 2px;
}
.lookOver{
  color: #115BA4;
  cursor: pointer;
}
.funct{
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}
.collect{
  margin-top: 4px;
  width: 139px;
  height: 47px;
  background-color: var(--theme);
  border-radius: 72px;
  font-weight: 700;
  line-height: 47px;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  cursor:pointer;
}
.functBlock{
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.margin-top-16{
  margin-top: 16px;
}
.people{
  color: #666666;
}
.courseUse{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 26px;
}
:deep(.courseTab .ant-tabs-nav::before){
  border-bottom: 0 !important;
}
:deep(.courseTab .ant-tabs-ink-bar){
  height: 6px !important;
}
.appraise{
  background-color: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 70px;
  margin-bottom: 200px;
}
.appraiseClass{
  display: flex;
  flex-wrap: wrap;
}
.appraiseClassItem{
  margin-right: 10px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #333333;
  cursor: pointer;
  margin-bottom: 18px;
}
.activeTab{
  color: #FFFFFF;
  background: var(--theme);
  border-radius: 10px;
}
.individual{
  display: flex;
  padding-top: 12px;
}
.individualImg{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
:deep(.appraiseBlock){
  margin: auto 0 auto 24px;
  flex: 1;
  border: 1px solid var(--theme);
  border-radius: 10px;
  overflow: hidden;
}
:deep(.appraiseBlock .ant-input){
  border: 0 !important;
  border-radius: 10px;
  height: 50px;
}
:deep(.appraiseBlock .ant-input:focus){
  border: 0 !important;
  box-shadow: none !important;
}
:deep(.appraiseBlock .ant-input-lg) {
  padding: 11px 11px;
}
:deep(.appraiseBlock .ant-btn-primary){
  width: 117px !important;
  height: 50px !important;
  border-radius: 10px 0 0 10px !important;
}
:deep(.appraiseBlock .ant-btn-primary:hover, .ant-btn-primary:focus) {
  color: #fff;
}
:deep(.appraiseBlock .ant-input-group-addon:last-child) {
  left:0px !important;
}
.appraiseList{
  padding-top: 11px;
}
.appraiseItem{
  margin-top: 24px;
  padding-bottom: 20px;
  border-bottom:2px solid #CCCCCC;
}
.appraiseItem:last-child{
  border-bottom: 0;
}
.appraiseInfo{
  display: flex;
  justify-content: space-between;
}
.userInfo{
  display: flex;
}
.userImg{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.baseInfo{
  margin-left: 20px;
}
.userName{
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.before{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.comeFrom{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.appraiseContent{
  margin-top: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  letter-spacing: 1px;
}
.function{
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
}
.reply{
  font-size: 13px;
  font-weight: 400;
  color:var(--theme);
  line-height: 15px;
}
.report{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.catalogue{
  margin-bottom: 50px;
}
.catalogueTitle{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
}
.study{
  padding-top: 20px;
  display: flex;
  cursor:pointer;
}
.studyIcon{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #999999;
  margin: auto 0;
}
.learned{
  padding-left: 14px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.accomplish{
  color: #28C445;
  font-size: 16px;
  line-height: 20px;
}
.pace{
  color: #666666;
  font-size: 16px;
  line-height: 20px;
}
.type{
  margin-left: 10px;
  padding:0 10px;
  height: 20px;
  border: 1px solid var(--theme);
  border-radius: 10px;
  font-size: 10px;
  font-weight: 400;
  color: var(--theme);
  line-height: 19px;
  box-sizing: border-box;
}
.catalogueName{
  margin-left: 11px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.accomplishIcon{
  display: block;
  margin: auto 0;
  font-size:16px;
  color:#28C445;
}
.progress{
  display: flex;
  /* width: 88px; */
}
:deep(.ant-table-content){
  border-radius: 8px !important;
  overflow: hidden !important;
}
:deep(.ant-table-thead tr th){
  background-color:var(--theme);
  color: #FFFFFF;
}
.ant-table-striped :deep(.table-striped) td {
  background-color: #f6f6f6 !important;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  border-color: #FFFFFF;
  padding: 0 !important;
}
:deep(.ant-table-cell){
  border-color: #f6f6f6 !important;
  border:1px solid #FFFFFF !important;
}
.returnHome{
  cursor:pointer;
}
.knowledge{
  padding:23px 0;
  height: 60px;
  /* line-height: 60px; */
  border-bottom: 2px solid #FFFFFF;
}

.catalogueTable{
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2px solid #FFFFFF;
}
.catalogueTable:last-child{
  border-bottom: 0;
}
.chaptersTitle{
  min-height:60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cancel{
  background-color: #FFFFFF;
  border: 1px solid #0960bd;
  color: var(--theme);
}
.introduceContent{
  width: 100%;
}
.introduceContent img{
  width: 100% !important;
}
</style>