<template>
  <div class="courseUse">
    <div class="worksContainer">
      <div class="aj-flex aj-align-center">
        <div class="title">作品信息</div>
        <div class="browse">
          <eye-outlined />
          <span class="aj-margin-left-xs">{{workDetail.ViewCount}}</span>
        </div>
      </div>
      <a-space :size="10" class="aj-margin-top">
        <div class="tag" v-for="(item,index) in workDetail.Tags" :key="index">{{item}}</div>
      </a-space>
      <a-row :gutter="[18]">
        <a-col :span="16">
          <div class="displayColumn">
            <div class="showTitle">
              <div>作品展示</div>
              <div class="decoration"></div>
            </div>
            <span class="createTime">创建时间：{{workDetail.CreateTime}}</span>
          </div>
          <a-carousel arrows autoplay :dots="false">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div v-for="(item,index) in workDetail.Img" :key="index" @click="selectWorkImg(item.url)">
              <img class="workImg" :src="item.url" @click="() => setVisible(true)" alt="">
            </div>
          </a-carousel>
          <div class="aj-margin-top-xl" v-html="workDetail.Desc"></div>
        </a-col>
        <a-col :span="8">
          <div class="userInfo">
            <img class="userImg" :src="workDetail.StudentHeader" alt="">
            <div>
              <div class="userName">{{workDetail.Customer}}</div>
              <div class="schoolName">
                <div class="schoolTitle">
                  <sketch-outlined />
                  <span>{{workDetail.SchoolName}}</span>
                </div>
              </div>
              <div class="worksNum">作品数：{{workDetail.StudentWallCount}}</div>
            </div>
          </div>
          <div class="infoBlock">
            <div class="infoTitle">版本详情</div>
            <div class="infoContent">
              <div class="infoItem" >
                <div class="itemTitle" style="padding-top:10px">模型分类</div>
                <div class="itemContent" style="padding-top:10px">{{workDetail.ModelName}}</div>
              </div>
              <div class="infoItem">
                <div class="itemTitle">风格类型</div>
                <div class="itemContent">{{workDetail.StyleName}}</div>
              </div>
              <div class="infoItem">
                <div class="itemTitle">浏览次数</div>
                <div class="itemContent">{{workDetail.ViewCount}}</div>
              </div>
              <div class="infoItem">
                <div class="itemTitle" style="padding-bottom:10px">分类标签</div>
                <div class="itemContent" style="padding-bottom:10px" v-if="TagsTitle">{{TagsTitle}}</div>
              </div>
            </div>
          </div>
          <div class="infoBlock">
            <div class="infoTitle">AI参数</div>
            <div class="infoContent">
              <div class="infoItem" >
                <div class="itemTitle" style="padding-top:10px">AI模型</div>
                <div class="itemContent" style="padding-top:10px">{{workDetail.AIModel}}</div>
              </div>
              <div class="infoItem">
                <div class="itemTitle">推荐权重</div>
                <div class="itemContent">{{workDetail.Weight}}</div>
              </div>
              <div class="infoItem">
                <div class="itemTitle" style="padding-bottom:10px">基础算法</div>
                <div class="itemContent" style="padding-bottom:10px">{{workDetail.Algorithm}}</div>
              </div>
            </div>
          </div>
          <div class="comment" v-if="workDetail.PositiveWord">
            <div class="commentTitle">正向词</div>
            <div class="commentContent">{{workDetail.PositiveWord}}</div>
          </div>
          <div class="comment" v-if="workDetail.ReverseWord">
            <div class="commentTitle">反向词</div>
            <div class="commentContent">{{workDetail.ReverseWord}}</div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-image :width="200" :style="{ display: 'none' }" :preview="{visible,onVisibleChange: setVisible,}" :src="worksImg" />
  </div>
</template>
<script>
  import { ref,onMounted,defineComponent} from 'vue';
  import defHttp from "@/utils/http.js";
  import { message} from 'ant-design-vue';
  import { LeftCircleOutlined, RightCircleOutlined,FileExcelFilled,EyeOutlined,SketchOutlined} from '@ant-design/icons-vue';
  import {useRouter,useRoute} from 'vue-router'
  export default defineComponent({
    components: {
      LeftCircleOutlined,
      RightCircleOutlined,
      FileExcelFilled,
      EyeOutlined,
      SketchOutlined
    },
    setup() {
      const router=useRouter()
      const route = useRoute()
      const workDetail=ref({})
      const TagsTitle=ref('')
      const visible=ref(false)
      const worksImg=ref('')

      onMounted(() => {
        getWorksDetail()
      })

      const getWorksDetail=()=>{
        defHttp.get('trainer/PhotoWall/Detail', { params: {id:route.query.id} }).then(data => {
          if (data.Success) {
            workDetail.value=data.Data
            workDetail.value.Img=JSON.parse(data.Data.Img)
            workDetail.value.ModelUrl=JSON.parse(data.Data.ModelUrl)
            workDetail.value.Desc=data.Data.Desc.replace(/<img /gi,'<img style="display:block" ');
            try {
              workDetail.value.Tags=JSON.parse(data.Data.Tags)
              if(workDetail.value.Tags.length){
                TagsTitle.value=workDetail.value.Tags.join(',')
              }
            } catch (err) {
              workDetail.value.Tags=[]
            }
          } else {
            message.error(`${data.Message}`)
          }
        })
      }

      const setVisible=(value)=>{
        visible.value = value;
      }

      const selectWorkImg=(url)=>{
        worksImg.value=url
      }

      return {
        router,
        getWorksDetail,
        route,
        workDetail,
        TagsTitle,
        setVisible,
        visible,
        worksImg,
        selectWorkImg
      };
    }
  })
</script>
<style lang="less" scoped>
.courseUse {
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 16px 0;
}
.worksContainer{
  padding: 16px;
  border-radius: 5px;
  background-color: #fff;
}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
  border-radius: 50%;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}
.workImg{
  height:450px;
  width:auto;
  margin:0 auto;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.title{
  font-size: 26px;
  line-height: 36px;
}
.browse{
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0 12px;
  background-color: #f4f5f9;
  border-radius: 25px;
  line-height: 25px;
  margin-left: 16px;
}
.tag{
  padding: 2px 8px;
  color: #557abf;
  background-color: #ebf2ff;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 4px;
}
.createTime{
  font-size: 12px;
  color: #999999;
}
.displayColumn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  margin: 16px 0;
}
.showTitle{
  font-size: 22px;
  font-weight: bold;
}
.decoration{
  width: auto;
  height: 4px;
  border-radius: 2px;
  background: linear-gradient(90deg, #173eff 0%, #1b7dff 100%);
}
.userInfo{
  padding: 16px;
  border-radius: 8px;
  display: flex;
  background-color: #f2f5f9;
  margin-top: 16px;
}
.userImg{
  width: 72px;
  min-width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 12px;
}
.userName{
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
}
.schoolName{
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 8px 0 5px;
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  color: #aa8645;
  font-weight: 400;
  background: linear-gradient(94.11deg, #efd6a9 10.52%, #f0dcbc 107.96%);
  border-radius: 4px;
  transform: skew(-10deg);
}
.schoolTitle{
  transform: skew(10deg);
}
.worksNum{
  margin-top: 11px;
  font-size: 12px;
  color: #999999;
}
.infoBlock{
  margin-top: 16px;
  overflow: hidden;
  border: 1px solid #e6e9ed;
  border-radius: 8px;
}
.infoTitle{
  padding: 0 9px;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid #e6e9ed;
}
.infoItem{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
}
.itemTitle{
  width: 92px;
  padding: 5px 10px;
  box-sizing: border-box;
  border-right: 1px solid #e6e9ed;
}
.itemContent{
  padding: 5px 10px;
}
.comment{
  padding:0 24px;
  margin-top: 12px;
}
.commentTitle{
  font-size: 16px;
  color: #666666;
}
.commentContent{
  font-size: 12px;
  margin-top: 8px;
  color: #999999;
}
</style>