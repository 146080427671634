<template>
  <div class="practice">
    <div v-if="!graduated">

      <a-spin :spinning="table.loading">
        <div class="practiceCore">
          <div class="tableBlock">
            <div v-if="table.list.length">
              <div class="studentItem" v-for="(item, index) in table.list" :key="index">
                <div class="studentInfo">
                  <div class="studentName">
                    <div>{{ item.SkuDesc }}</div>
                    <div class="StatusDesc">{{ item.StatusDesc }}</div>
                  </div>
                  <div class="integral" v-if="item.Integral >= 0">{{ item.Integral }}<span
                      class="integralText">积分</span>
                  </div>
                </div>
                <div class="otherInfo">
                  <a-space :size="30">
                    <div>编号：{{ item.Id }}</div>
                    <div>风格类型：{{ item.SkuDesc }}</div>
                    <div>合作商：{{ item.PartnerName }}</div>
                    <div>添加时间：{{ item.CreateDate }}</div>
                  </a-space>
                  <a-space :size="0">
                    <!-- <a-popconfirm title="您确定要接单吗？" v-if="item.Status != -1" ok-text="确定" cancel-text="取消"
                      @confirm="confirmReceiving(item)" placement="bottom">
                      <a-button type="text" size="small" :loading="table.operating" style="color:#55ABFF;">接单</a-button>
                    </a-popconfirm> -->
                    <a-button type="text" size="small" :loading="table.operating" style="color:#55ABFF;"
                      @click="handleDetail(item, 1)">详情</a-button>

                      <!-- 从创作者搬过来 -->
                        <template v-if="item.Status == 3 || item.Status == 5 || item.Status == 7 || item.Status == 9">
                          <a-button type="text"  size="small" style="color:#55ABFF" @click="showModal(1, item)">上传作品</a-button>
                          <a-button type="text"  size="small" style="color:#55ABFF" @click="showModal(2, item)">提交</a-button>
                        </template>
                        <a-popconfirm v-if="item.Status > 2 && item.Status < 6" title="确定撤回提交吗？" ok-text="确定" cancel-text="取消"
                          @confirm="showModal(3, item)">
                          <a-button type="text"  size="small" style="color:#55ABFF">撤回提交</a-button>
                        </a-popconfirm>
                      
                  </a-space>
                </div>
              </div>
              <a-pagination style="text-align: right;" v-model:current="table.pageConfig.current"
                :total="table.pageConfig.total" show-less-items size="small" @change="pageChange" />
            </div>
            <a-empty v-else description="暂无数据" />
          </div>
        </div>
      </a-spin>

    </div>
    <div v-else class="graduateState">
      <img class="graduateImg" src="../../assets/image/graduateImg.png" alt="">
      <div class="graduateText">您已毕业已经是一名合格的设计师，加入数字人创梦接单平台与我们一起见证元宇宙的发展<a style="color:#55ABFF;"
          href="http://niuniuer.cn/user/login/index">http://niuniuer.cn/user/login/index</a><br>（使用当前平台账号密码或手机号验证方式可直接登录系统）
      </div>
    </div>
    <a-modal title="提交工单" v-model:visible="workOrderModal.visible" :keyboard="false" width="30%" class="" centered
      :maskClosable="false" :destroyOnClose="true">
      <section class="aj-padding-top-lg">
        <a-form :model="workOrderModal.form" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }"
          :rules="workOrderModal.rules" ref="formIns">
          <a-form-item label="工单编号">
            <span>{{ workOrderModal.form.id }}</span>
          </a-form-item>
          <a-form-item label="反馈详情" name="feedback">
            <a-textarea v-model:value="workOrderModal.form.feedback" style="resize:none;" :rows="4" placeholder="请输入内容" />
          </a-form-item>
        </a-form>
      </section>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="preservation" :loading="workOrderModal.processing"> 保存 </a-button>
        </a-space>
      </template>
    </a-modal>

    <a-modal title="上传作品" v-model:visible="mouldModal.visible" :keyboard="false" width="700px" centered :maskClosable="false">
      <section class="aj-padding-top-lg">
        <a-form :model="mouldModal.form" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }" :rules="mouldModal.rules"
          ref="mouldFormIns">
          <a-form-item label="上传作品" name="models">
            <div>
              <a-upload v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequest"
                :before-upload="beforeUpload" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                <a-button type="primary">
                  <UploadOutlined />
                  点击上传
                </a-button>
              </a-upload>
              <div class="lineHeight32">
                <div v-for="(item, index) in mouldModal.form.models" :key="index">
                  <a-space>
                    <a style="color:#115BA4;" :href="item.url" :download="item.name">{{ item.name }}</a>
                    <a-button type="primary" ghost size="small" @click="handleDeleteModels(index)">删除</a-button>
                  </a-space>
                </div>
              </div>
            </div>
          </a-form-item>
          <a-form-item label="上传作品截图" name="thumbnails">
            <div>
              <a-upload v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequestImg"
                :before-upload="beforeUploadImg" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                <a-button type="primary">
                  <UploadOutlined />
                  点击上传
                </a-button>
              </a-upload>
              <div class="aj-flex">
                <div v-for="(item, index) in mouldModal.form.thumbnails" :key="index">
                  <div class="imageBlock aj-margin-right-sm">
                    <img class="aj-padding-top uploadImg" :src="item.url" alt="">
                    <div class="deleteImg" @click="handleDeleteImg(index)">删除</div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
        </a-form>
      </section>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="handleSubmitMould" :loading="mouldModal.processing"> 保存 </a-button>
        </a-space>
      </template>
    </a-modal>

    <a-modal title="高级查询" v-model:visible="searchModalVisible" :keyboard="false" width="30%" centered
      :maskClosable="false" :destroyOnClose="true">
      <section class="aj-padding-top-lg">
        <a-form :model="searchForm" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
          <a-form-item label="编号">
            <a-input v-model:value="searchForm.encoded" placeholder="请输入编号"></a-input>
          </a-form-item>
          <a-form-item label="风格类型">
            <a-input v-model:value="searchForm.model" placeholder="请输入风格类型"></a-input>
          </a-form-item>
          <a-form-item label="合作商">
            <a-input v-model:value="searchForm.partner" placeholder="请输入合作商"></a-input>
          </a-form-item>
          <a-form-item label="状态">
            <a-select v-model:value="searchForm.status" placeholder="状态" allowClear style="width:200px;">
              <a-select-option :value="null">全部状态</a-select-option>
              <a-select-option :value="item.id" v-for="(item, index) in stateList" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </section>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="handleDefine">确定</a-button>
        </a-space>
      </template>
    </a-modal>


    <!-- 提交审核 -->
    <a-modal v-model:visible="modalData.visible" width="600px" :footer="null" style="top: 200px" :maskClosable="false">
      <template #title>
        <div class="title-tip2">提交审核</div>
      </template>
      <a-form  :model="modalData.formData" :labelCol="{ span: 7 }"
        :wrapperCol="{ span: 14 }" :rules="modalData.rules" ref="formRef" style="color: #333;">
        <a-form-item label="工单编号">
          <span style="color: #333;">{{ modalData.formData.id }}</span>
        </a-form-item>
        <a-form-item>
          <template #label>
            <div><span class="redXin">*</span> 品控标准</div>
          </template>
          <a-button type="primary" size="small" @click="modalData.standardShow = true"><span
              style="padding: 0 3px 0 5px;">核验</span></a-button>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea class="iptBg" v-model:value="modalData.formData.remark" placeholder="请输入"
            style="width:100%;height: 100px;border-radius: 10px;" />
        </a-form-item>
      </a-form>
      <div style="display:flex;align-items: center;justify-content: flex-end;margin-top: 45px;">
        <a-button danger ghost @click="modalData.visible = false" style="margin-right: 0 20px;">取消</a-button>
        <a-button type="primary" style="margin: 0 20px;" @click="confirmHandle" :loading="modalData.loading">确定</a-button>
      </div>
    </a-modal>

    <!-- 品控标准 -->
    <a-modal v-model:visible="modalData.standardShow" width="900px" style="left: 80px;top:140px" :maskClosable="false">
      <template #title>
        <div class="title-tip2">品控标准</div>
      </template>
      <a-table class="noscrollTable" :data-source="modalData.formData.standards">
        <a-table-column data-index="SortNo" title="序号" :width="80"></a-table-column>
        <a-table-column data-index="ConditionDesc" title="交付条件" :width="220">
          <template #default="{ record }">
            <div style="display:flex">
              <div style="flex:1">{{ record.ConditionDesc }}</div>
              <a-tooltip title="点击查看附件"
                v-if="(record.Images && JSON.parse(record.Images).length) || (record.Videos && JSON.parse(record.Videos).length)">
                <div @click="privewFJ(record)"
                  style="color: #E34D4E;cursor: pointer;margin-left: 10px;;text-align: center;">附件</div>
              </a-tooltip>
            </div>
          </template>
        </a-table-column>
        <a-table-column data-index="CustomerCheck" title="委托方审核" :width="150">
          <template #default="{ record }">
            <a-checkbox disabled v-model:checked="record.CustomerCheck"></a-checkbox>
          </template>
        </a-table-column>
        <a-table-column data-index="SystemCheck" title="数字人审核" :width="150">
          <template #default="{ record }">
            <a-checkbox disabled v-model:checked="record.SystemCheck"></a-checkbox>
          </template>
        </a-table-column>
        <a-table-column data-index="PartnerCheck" title="合作商审核" :width="150">
          <template #default="{ record }">
            <a-checkbox disabled v-model:checked="record.PartnerCheck"></a-checkbox>
          </template>
        </a-table-column>
        <a-table-column data-index="CreatorCheck" title="创作者审核" :width="150">
          <template #default="{ record }">
            <a-checkbox v-model:checked="record.CreatorCheck"></a-checkbox>
          </template>
        </a-table-column>
      </a-table>
      <template #footer>
        <div style="display:flex;align-items: center;justify-content: flex-end;margin-bottom: 8px;">
          <a-button danger ghost @click="modalData.standardShow = false" style="margin-right: 0 20px;">取消</a-button>
          <a-button type="primary" style="margin: 0 20px;" @click="modalData.standardShow = false"
            :loading="modalData.loading">确定</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 查看品控标准附件 -->
    <a-modal v-model:visible="privewFJModel.visible" width="850px" :footer="null" style="left: 80px;top:140px" :maskClosable="false">
      <template #title>
        <div class="title-tip2">查看附件</div>
      </template>
      <div style="max-height: 60vh;overflow-y: auto;">
        <a-form>
          <a-form-item :labelCol="{ style: 'width: 100px' }" v-if="privewFJModel.imgList.length">
            <template #label>
              <div> 图片 </div>
            </template>
            <div style="display:flex;flex-wrap: wrap;">
              <div v-for="item in privewFJModel.imgList" :key="item.uid"
                style="display: flex;align-items: center;color: #333;">
                <a-image :src="item.url" alt=""
                  style="width: 100px;height: 100px;border-radius: 10px;margin: 0 12px 12px 0;" />
              </div>
            </div>
          </a-form-item>
          <a-form-item :labelCol="{ style: 'width: 100px' }" v-if="privewFJModel.videoList.length">
            <template #label>
              <div> 视频 </div>
            </template>
            <div style="display:flex;flex-wrap: wrap;">
              <div v-for="item in privewFJModel.videoList" :key="item.uid"
                style="display: flex;align-items: center;color: #333;">
                <video v-if="item.type?.search('video') > -1"
                  style="border-radius: 10px; height: 100px; background-color: black" controls :src="item.url"></video>
              </div>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>


  </div>
</template>

<script>
import { defineComponent, ref, onMounted, unref, toRaw } from 'vue';
import { DownOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router'
import {instance2 as defHttp} from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    DownOutlined,
    UploadOutlined
  },
  setup() {
    const activeKey = ref(1)
    const fileList = ref([])
    const router = useRouter()
    const formIns = ref()
    const stateList = ref([])
    const mouldFormIns = ref()
    const searchForm = ref({
      search: '',
      encoded: '',
      model: '',
      partner: '',
      status: null,
    })
    const graduated = ref(false)
    const searchModalVisible = ref(false)
    const table = ref({
      list: [],
      pageConfig: {
        size: 'small',
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => { return `共 ${total}` }
      },
      loading: false,
      operating: false
    })
    const workOrderModal = ref({
      visible: false,
      processing: false,
      form: {
        id: 0,
        feedback: '',
      },
      rules: {
        feedback: [{
          required: true,
          trigger: 'blur',
          message: '该项必须填写',
          type: 'string'
        }],
      }
    })

    const mouldModal = ref({
      visible: false,
      processing: false,
      form: {
        id: 0,
        models: [],
        thumbnails: [],
      },
      rules: {
        models: [{
          required: true,
          trigger: 'blur',
          message: '该项必须选择',
          type: 'array'
        }],
        thumbnails: [{
          required: true,
          trigger: 'blur',
          message: '该项必须选择',
          type: 'array'
        }],
      }
    })

    onMounted(() => {
      getPendingOrders()
      getStateList()
      let info = JSON.parse(localStorage.getItem('userInfo'))
      graduated.value = info.graduated
    })

    const pageChange = (current, pageSize) => {
      table.value.pageConfig.current = current
      table.value.pageConfig.pageSize = pageSize
      if (activeKey.value == 1) {
        getPendingOrders()
      } else {
        getList()
      }
    }

    const getList = () => {
      table.value.loading = true
      defHttp.get('/student/Practice/list', { params: { ...searchForm.value, page: table.value.pageConfig.current, limit: table.value.pageConfig.pageSize, } }).then(data => {
        table.value.loading = false
        if (data.Success) {
          table.value.list = data.Data.Rows
          table.value.pageConfig.total = data.Data.TotalItemCount
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.loading = false })
    }

    const getPendingOrders = () => {
      table.value.loading = true
      defHttp.post('/system/order/list', {
        search: searchForm.value.search,
        page: table.value.pageConfig.current,
        limit: table.value.pageConfig.pageSize,
      }).then(data => {
        table.value.loading = false
        if (data.Success) {
          table.value.list = data.Data.Rows
          table.value.pageConfig.total = data.Data.TotalItemCount
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.loading = false })
    }

    const confirmCancel = (record) => {
      table.value.operating = true
      defHttp.post(`/student/Practice/Cancel?id=${record.id}`).then(data => {
        table.value.operating = false
        if (data.Success) {
          message.success('取消成功')
          table.value.pageConfig.current = 1
          table.value.list = []
          getList()
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.operating = false })
    }

    const confirmReceiving = (record) => {
      table.value.operating = true
      defHttp.get('student/practice/receiving', { params: { id: record.Id } }).then(data => {
        table.value.operating = false
        if (data.Success) {
          message.success('接单成功')
          if (activeKey.value == 1) {
            getPendingOrders()
          } else {
            getList()
          }
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.operating = false })
    }

    const handleSubmit = (record) => {
      workOrderModal.value.form.feedback = ''
      workOrderModal.value.form.id = record.id || record.Id
      workOrderModal.value.visible = true
    }

    const handleUpload = (record) => {
      mouldModal.value.form.id = record.id || record.Id
      mouldModal.value.form.models = []
      mouldModal.value.form.thumbnails = []

      mouldModal.value.visible = true
    }

    const preservation = () => {
      formIns.value.validate().then(() => {
        workOrderModal.value.processing = true
        const formData = { ...toRaw(unref(workOrderModal.value.form)) }
        delete formData.code
        defHttp.get('student/practice/submit', { params: { ...formData } }).then(data => {
          workOrderModal.value.processing = false
          if (data.Success) {
            message.success('提交成功');
            workOrderModal.value.visible = false
            if (activeKey.value == 1) {
              getPendingOrders()
            } else {
              getList()
            }
          } else {
            message.error(`${data.Message}`)
            workOrderModal.value.visible = false
          }
        }).catch(err => { table.value.operating = false })
      }).catch(() => { workOrderModal.value.processing = false })
    }

    const getStateList = () => {
      defHttp.get('platform/Common/Enum', { params: { name: 'Orderstatus' } }).then(data => {
        if (data.Success) {
          stateList.value = data.Data
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const handleDefine = () => {
      searchModalVisible.value = false
      if (activeKey.value == 1) {
        getPendingOrders()
      } else {
        getList()
      }
    }

    const handleDetail = (record, type) => {
      if (type == 1) {
        router.push({ path: '/workOrderDetail', query: { id: record.Id } })
      } else {
        router.push({ path: '/workOrderDetail', query: { id: record.id } })
      }
    }

    const handleChange = (value) => {
      table.value.pageConfig.current = 1
      table.value.list = []
      if (value == 1) {
        getPendingOrders()
      } else {
        getList()
      }
    }

    const customRequest = (file) => {
      message.loading({
        content: '正在上传...',
        key: 'upload',
        duration: 0
      })
      const formData = new FormData();
      formData.append("file", file.file);
      defHttp.post('/platform/Common/Upload', formData).then(data => {
        if (data.Success) {
          let list = {
            url: data.Data[0],
            id: file.file.uid,
            name: file.file.name,
            percent: 100,
            status: 'done'
          }
          mouldModal.value.form.models.push(list)
          message.success({
            key: 'upload',
            content: '上传成功!',
            duration: 2
          })
        } else {
          message.error({
            key: 'upload',
            content: data.Message,
            duration: 2
          })
        }
      }).catch(() => {
        message.error({
          key: 'upload',
          content: '上传失败!',
          duration: 2
        })
      })
    }

    const beforeUpload = (file) => {
      let size = file.size / 1024 / 1024
      // if (size > 20) {
      //     createMessage.error('上传文件太大',1.5,)
      //     return false
      // }
      return true;
    }

    const customRequestImg = (file) => {
      const formData = new FormData();

      formData.append("file", file.file);
      defHttp.post('/platform/Common/Upload', formData).then(data => {
        if (data.Success) {
          let list = {
            url: data.Data[0],
            id: file.file.uid,
            name: file.file.name,
            percent: 100,
            status: 'done'
          }
          mouldModal.value.form.thumbnails.push(list)
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const beforeUploadImg = (file) => {
      let size = file.size / 1024 / 1024
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
        return false
      }
      if (size > 20) {
        createMessage.error('上传文件太大', 1.5,)
        return false
      }
      return true;
    }

    const handleDeleteImg = (index) => {
      mouldModal.value.form.thumbnails.splice(index, 1)
    }

    const handleDeleteModels = (index) => {
      mouldModal.value.form.models.splice(index, 1)
    }

    const handleSubmitMould = () => {
      mouldFormIns.value.validate().then(() => {
        mouldModal.value.processing = true
        const formData = { ...toRaw(unref(mouldModal.value.form)) }
        formData.models = JSON.stringify(formData.models)
        formData.thumbnails = JSON.stringify(formData.thumbnails)
        defHttp.post('/system/Order/SaveModels', formData).then(data => {
          if (data.Success) {
            message.success('上传成功',() => {
              getPendingOrders()
            });
            mouldModal.value.visible = false
            mouldModal.value.processing = false
          } else {
            message.error(`${data.Message}`)
          }
        }).catch(err => { mouldModal.value.processing = false })
      }).catch(() => { mouldModal.value.processing = false })
    }


    // 从创作者端搬
    const modalData = ref({
        loading: false,
        visible: false,
        title: '',
        formData: {
          id: 0,
          remark: '',
          standards: []
        },
        rules: {
          remark: [{ required: true, trigger: 'blur', message: '该项必须填写', type: 'string' }],
        },
        //上传作品
        visible2: false,
        formData2: {
          id: 0,
          models: '',
          thumbnails: ''
        },
        standardShow: false,

    })
    
    const privewFJModel = ref({
      visible: false,
      imgList: [],
      videoList: []
    })

    const privewFJ = (item) => {
      if (item.Images && JSON.parse(item.Images).length) {
        privewFJModel.value.imgList = JSON.parse(item.Images)
      } else {
        privewFJModel.value.imgList = []
      }
      if (item.Videos && JSON.parse(item.Videos).length) {
        privewFJModel.value.videoList = JSON.parse(item.Videos)
      } else {
        privewFJModel.value.videoList = []
      }
      privewFJModel.value.visible = true
    }

    const showModal = (type, item) => {
      if (type == 1) {
        //上传作品
       handleUpload(item)

      } else if (type == 2) {
        if (item.Status != 3) {
          message.warn('请先上传作品', 2);
          return
        }
        //提交审核
        if (item?.Id) {
          table.value.loading = true
          defHttp.get('/system/Order/Detail',  { params: { id: item?.Id } }).then((response) => {
            table.value.loading  = false
            let res = response.Data
            if (res.order.Flows[res.order.Flows.length - 1].Models) {
              modalData.value.formData = {
                id: item.Id,
                standards: res.order.OrderSubmits,
                remark: ''
              }
              modalData.value.visible = true
            } else {
              message.warn('请先上传作品');
              return
            }
          }).catch(() => {
            table.value.loading = false
          })
          
        }

      } else if (type == 3) {
        //撤回
        let params = {
          id: item.Id,
          pass: false
        }
        table.value.loading = true
        defHttp.post('/system/Order/CreatorSubmit', params).then(() => {
          table.value.loading  = false
          message.success('撤回成功',() => {
            getPendingOrders()
          })

        }).catch(() => {
          table.value.loading = false
        })
      }

    }
    const formRef = ref()
    const confirmHandle = () => {
      let params = { ...modalData.value.formData }
      if (params.standards.some(el => !el.CreatorCheck)) {
        message.warn('请核验品控标准,确认勾选');
        return
      }
      params.standards.forEach(el => {
        el.CreatorCheck = true
      })
      params.pass = true
      modalData.value.loading = true
      defHttp.post('/system/Order/CreatorSubmit', params).then(() => {
        modalData.value.loading = false
        modalData.value.visible = false
        message.success('提交成功', () => {
          getPendingOrders()
        });

      }).catch(() => { modalData.value.loading = false })
    }

    return {
      table,
      searchForm,
      pageChange,
      getList,
      handleDetail,
      confirmReceiving,
      workOrderModal,
      handleSubmit,
      preservation,
      formIns,
      searchModalVisible,
      getStateList,
      stateList,
      handleDefine,
      activeKey,
      handleChange,
      getPendingOrders,
      graduated,
      handleUpload,
      fileList,
      customRequest,
      beforeUpload,
      mouldModal,
      customRequestImg,
      beforeUploadImg,
      handleDeleteImg,
      handleDeleteModels,
      handleSubmitMould,
      mouldFormIns,
      confirmCancel,
      //
      showModal,
      modalData,
      privewFJModel,
      confirmHandle,
      formRef,
      privewFJ
    };
  },
});
</script>
<style scoped>
.practice {
  width: 100%;
  background-color: #f0f2f5;
}

.practiceCore {
  padding: 28px 0;
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
}

:deep(.ant-table-thead tr th) {
  background-color: rgba(0, 0, 0, 0.09);
  /* border-radius: 15px; */
}

.searchContent {
  width: 100%;
  background-color: #fff;
  padding: 26px 0;
}

.search {
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
}

.tableBlock {
  padding: 30px 44px;
  border-radius: 8px;
  background-color: #fff;
}

.studentItem {
  padding-bottom: 16px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 16px;
}

.studentInfo {
  display: flex;
  justify-content: space-between;
}

.studentName {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  line-height: 24px;
  display: flex;
}

.StatusDesc {
  padding: 0px 6px;
  border-radius: 2px;
  border: 1px solid #55ABFF;
  font-size: 10px;
  font-weight: 400;
  color: #55ABFF;
  height: 19px;
  line-height: 19px;
  margin: auto 0 auto 14px;
}

.otherInfo {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.graduateState {
  padding-top: 135px;
  text-align: center;
}

.graduateImg {
  width: 286px;
  height: 220px;
}

.graduateText {
  padding-top: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.lineHeight32 {
  line-height: 32px;
}

.formContent {
  padding-left: 24px;
}

.imageBlock {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
}

.uploadImg {
  width: 100%;
  height: 100%;
}

.deleteImg {
  width: 100%;
  height: 28px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

:deep(.ant-tabs-nav::before) {
  border-bottom: 0 !important;
}

.integral {
  font-size: 20px;
  font-weight: 900;
  color: #0960bd;
  line-height: 27px;
  letter-spacing: 2px;
}

.integralText {
  font-size: 14px;
}
</style>