<template>
  <div class="exam">
    <a-spin :spinning="spinning">
      <div class="examHeader">
        <div class="examHeaderCore">
          <div class="examName">{{examinationInfo.CareerPathName}}</div>
          <div class="flex">
            <span class="examExplain">题数:{{examinationInfo.QuestionCount}}题 满分:{{examinationInfo.TotalPoints}}分 合格:{{explainModal.PassLine}}分</span>
            <a-button class="submitBtn" type="primary" size="large" @click="handleSubmit" :loading="loading">交卷</a-button>
          </div>
        </div>
      </div>
      <div class="examContent">
        <div class="circumstances">
          <div class="examinationTime">
            <div class="countDown">{{countDownTime}}</div>
            <div class="countdownTitle">考试倒计时</div>
          </div>
          <div class="excuseList">
            <div class="subjectList">
              <div class="subjectTotal">
                <div>试卷总分:100分</div>
                <div>总题数:100题</div>
              </div>
              <div class="subjectType">理论题:</div>
              <div class="subjectBlock">
                <div v-for="(item,index) in questionsList" :key="index" @click="handleSelect(index)">
                  <div class="subjectItem" :class="{'accomplish':item.SutdentAnswers&&item.SutdentAnswers.length||item.remark||item.uploadAttachments.length,'notDone':!item.SutdentAnswers,'mark':item.isMarked,'active':questionIndex==index}" v-if="item.Type!=4">{{computeIndex(item)}}</div>
                </div>
              </div>
              <div style="padding-top:20px;">实训题:</div>
              <div class="subjectBlock">
                <div v-for="(item,index) in questionsList" :key="index" @click="handleSelect(index)">
                  <div class="subjectItem" :class="{'accomplish':item.SutdentAnswers&&item.SutdentAnswers.length||item.remark||item.uploadAttachments.length,'notDone':!item.SutdentAnswers,'mark':item.isMarked,'active':questionIndex==index}" v-if="item.Type==4">{{computeIndex(item)}}</div>
                </div>
              </div>
            </div>
            <div class="subjectTypeTips">
              <div class="tipsItem">
                <div class="TipsColor accomplish"></div>
                <div class="tipsType">已做</div>
              </div>
              <div class="tipsItem">
                <div class="TipsColor notDone"></div>
                <div class="tipsType">未做</div>
              </div>
              <div class="tipsItem">
                <div class="TipsColor mark"></div>
                <div class="tipsType">标记</div>
              </div>
            </div>
          </div>
        </div>
        <div class="answerArea">
          <div class="title">
            <span>{{questionIndex+1}}.（{{computeType(currentQuestion.Type)}}）{{currentQuestion.TheContent}}</span>
            <span class="fraction">（{{currentQuestion.QuestionPoints}}分）</span>
            <div class="markBtn" :class="currentQuestion.isMarked?'activeMark':''" @click="handleMarked">
              <StarFilled />
              <span class="aj-margin-left-sm">标记</span>
            </div>
          </div>
          <div class="aj-margin-top" v-if="currentQuestion.ImageUrls!=null">
            <a-space>
              <a-image :width="80" v-for="(item,index) in JSON.parse(currentQuestion.ImageUrls)" :key="index" :src="item.url" />
            </a-space>
          </div>
          <div class="questions">
            <!-- 单选题 -->
            <div class="groupBlock" v-if="currentQuestion.Type==0">
              <a-radio-group v-model:value="currentQuestion.SutdentAnswers" @change="hangdeRadio">
                <a-radio :style="radioStyle" :value="String(item.Id)" v-for="(item,index) in currentQuestion.Options" :key="index">
                  <span>{{item.TheContent}}</span>
                  <span class="aj-margin-left">
                    <a-space v-if="item.Images">
                      <a-image :width="40" v-for="(itm,i) in JSON.parse(item.Images)" :key="i" :src="itm.Url" />
                    </a-space>
                  </span>
                </a-radio>
              </a-radio-group>
            </div>
            <!-- 多选题 -->
            <div class="groupBlock" v-if="currentQuestion.Type==1||currentQuestion.Type==2">
              <a-checkbox-group v-model:value="currentQuestion.SutdentAnswers">
                <a-checkbox :style="radioStyle" :value="item.Id" v-for="(item,index) in currentQuestion.Options" :key="index">
                  <span>{{item.TheContent}}</span>
                  <span class="aj-margin-left">
                    <a-space v-if="item.Images">
                      <a-image :width="40" v-for="(itm,i) in JSON.parse(item.Images)" :key="i" :src="itm.Url" />
                    </a-space>
                  </span>
                </a-checkbox>
              </a-checkbox-group>
            </div>
            <!-- 判断题 -->
            <div class="groupBlock" v-if="currentQuestion.Type==3">
              <a-radio-group v-model:value="currentQuestion.SutdentAnswers" @change="hangdeRadio">
                <a-radio :style="radioStyle" :value="String(item.Id)" v-for="(item,index) in currentQuestion.Options" :key="index">
                  <span>{{item.TheContent}}</span>
                  <span class="aj-margin-left">
                    <a-space v-if="item.Images">
                      <a-image :width="40" v-for="(itm,i) in JSON.parse(item.Images)" :key="i" :src="itm.Url" />
                    </a-space>
                  </span>
                </a-radio>
              </a-radio-group>
            </div>
            <!-- 实操题 -->
            <div class="groupBlock" v-if="currentQuestion.Type==4">
              <!-- <div class="richText">富文本</div>
              <div class="aj-margin-top" v-if="currentQuestion.ImageUrls!=null">
                <a-space>
                  <a-image :width="80" v-for="(item,index) in JSON.parse(currentQuestion.ImageUrls)" :src="item.url" />
                </a-space>
              </div> -->
              <div class="appendix flex aj-padding-tb-xl">
                <span class="aj-padding-top-sm">附件：</span>
                <div>
                  <div class="flex aj-margin-bottom-sm appendixList" v-for="(item,index) in currentQuestion.Attachments" :key="index">
                    <div><FileExcelFilled class="aj-margin-right-sm" style="color:#5ACC9B;" />{{item.name}}</div>
                    <div class="downloadBtn" @click="directlyDownload(item.url,item.name)">下载</div>
                  </div>
                </div>
              </div>
              <div class="titleType">答案：</div>
              <div class="appendix aj-padding-top">
                <span>附件：</span>
                <a-upload :customRequest="customRequest" :before-upload="beforeUpload" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                  <div class="uploadBtn">
                    <UploadOutlined />
                    上传文件
                  </div>
                  <template #itemRender="{ file}"></template>
                </a-upload>
                <div class="uploadList">
                  <div v-for="(item,index) in currentQuestion.uploadAttachments" :key="index" style="margin-bottom:8px;">
                    <a-space>
                      <div><FileExcelFilled class="aj-margin-right-sm" style="color:#5ACC9B;" />{{ item.name }}</div>
                      <div class="downloadBtn" @click="directlyDownload(item.url,item.name)">查看</div>
                      <div class="downloadBtn" @click="handleRemove(index)">删除</div>
                    </a-space>
                  </div>
                </div>
              </div>
              <div class="appendix flex aj-padding-top" style="padding-bottom:60px;">
                <div class="infoTitle">备注：</div>
                <a-textarea class="remarks" v-model:value="currentQuestion.remark" :rows="4" style="resize:none;" :bordered="false" placeholder="不超过80个字" />
              </div>
            </div>
          </div>
          <div class="NextQuestion">
            <a-button class="NextQuestionBtn" type="primary" @click="nextQuestion" v-if="questionIndex+1<questionsList.length">下一题</a-button>
            <a-button class="NextQuestionBtn" type="primary" @click="PreviousQuestion" v-if="questionIndex+1==questionsList.length">上一题</a-button>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted,reactive,computed} from 'vue';
import { StarFilled,UploadOutlined,FileExcelFilled} from '@ant-design/icons-vue';
import {useRouter} from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    StarFilled,
    UploadOutlined,
    FileExcelFilled
  },
  setup() {
    const router=useRouter()
    let examinationId=router.currentRoute.value.query.examinationId
    let examineId=router.currentRoute.value.query.examineId
    const examinationInfo=ref({})
    const questionsList=ref([])
    const typeList=ref([])
    const checkboxValue=ref([])
    const spinning=ref(false)
    const loading=ref(false)
    const currentQuestion=ref({})
    const questionIndex=ref(0)
    const countDownTime=ref('')
    const fileList=ref([])
    const finishTime=ref('')
    const examinationConfig=ref({})
    const alphabet=ref(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R',])
    const explainModal=ref({
      title:'',
      TimeSpan:0,
      PassLine:0,
      second:0,
    })
    const radioStyle = reactive({
      display: 'flex',
      paddingTop:'15px',
      paddingBottom:'15px',
    });

    onMounted(() => {
      if (navigator.keyboard && navigator.keyboard.lock) {
        navigator.keyboard.lock(['Escape'])
      }
      allScreen()
      getTypeList()
      getExaminationInfo()
      getExaminationConfig()
    })

    const computeIndex=computed(() => {
      return (item) => {
        if(item.Type==4){
          let list=questionsList.value.filter(p=>p.Type==4)
          let index=list.findIndex(p=>p.Id==item.Id)
          return index+1
        }else{
          let list=questionsList.value.filter(p=>p.Type!=4)
          let index=list.findIndex(p=>p.Id==item.Id)
          return index+1
        }
      }
    })

    const allScreen = ()=>{
      let domElement = document.documentElement;
      if (domElement.requestFullscreen) {
        domElement.requestFullscreen();
      } else if (domElement.mozRequestFullScreen) {
        domElement.mozRequestFullScreen();
      } else if (domElement.webkitRequestFullScreen) {
        domElement.webkitRequestFullScreen();
      }
    }

    const getExaminationInfo=()=>{
      defHttp.get('/trainer/System/Paper',{params:{id:examineId}}).then(data=>{
        if(data.Success){
          explainModal.value.title=data.Data.CareerPathName
          explainModal.value.TimeSpan=data.Data.TimeSpan
          explainModal.value.PassLine=data.Data.PassLine
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const changeDate=(dateTime)=>{
      let second = dateTime
      function getTime(time) {
        let h = parseInt(time / 60 / 60 % 24)
        h = h < 10 ? '0' + h : h
        let m = parseInt(time / 60 % 60)
          m = m < 10 ? '0' + m : m
        let s = parseInt(time % 60)
          s = s < 10 ? '0' + s : s
        return [h, m, s]
      }
      let res = getTime(second)
      countDownTime.value=res[0]+':'+res[1]+':'+res[2]
    }

    const countDown = setInterval(() => {
      if (explainModal.value.second === 0) {
        clearInterval(countDown);
        return;
      }{
        explainModal.value.second--;
        changeDate(explainModal.value.second)
      }
    }, 1000);

    const directlyDownload = (url) => {
      var fileLink = document.createElement('a')
      fileLink.href = url
      fileLink.setAttribute('download','下载')
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
    }

    const getTypeList=()=>{
      defHttp.get('platform/Common/Enum',{params:{name:'QuestionType'}}).then(data=>{
        if(data.Success){
          typeList.value=data.Data
          getExaminationDetail()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const getExaminationDetail=()=>{
      spinning.value=true
      defHttp.get('/student/examination/paper',{params:{id:examinationId}}).then(data=>{
        spinning.value=false
        if(data.Success){
          examinationInfo.value=data.Data.paperInfo
          // questionsList.value=data.Data.questions
          let theory=data.Data.questions.filter(p=>p.Type!=4)
          let training=data.Data.questions.filter(p=>p.Type==4)
          questionsList.value=theory.concat(training)
          questionsList.value.forEach(item=>{
            item.isMarked=false
            item.remark=''
            item.uploadAttachments=[]
          })
          currentQuestion.value=questionsList.value[questionIndex.value]
          if(currentQuestion.value.Attachments!=''){
            currentQuestion.value.Attachments=JSON.parse(currentQuestion.value.Attachments)
          }
          finishTime.value=getFinishTime()
          var time = new Date(finishTime.value) - new Date();
          var minute=Math.floor(time/(60*1000))
          explainModal.value.second=minute*60
          changeDate(explainModal.value.second)
          let paperInfoDetail=JSON.parse(localStorage.getItem('paperInfo'))
          let index=-1
          if(paperInfoDetail.length){
            index=paperInfoDetail.findIndex(p=>p.id==examinationInfo.value.Id)
            if(index>=0){
              questionsList.value=paperInfoDetail[index].questions
              currentQuestion.value=questionsList.value[questionIndex.value]
              if(currentQuestion.value.Attachments!=''){
                currentQuestion.value.Attachments=JSON.parse(currentQuestion.value.Attachments)
              }
            }
          }
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const getFinishTime=()=>{
      var time = new Date(examinationInfo.value.CreateDate)
      var b = explainModal.value.TimeSpan
      time.setMinutes(time.getMinutes() + b, time.getSeconds(), 0)
      var date = time
      var year = date.getFullYear()
      var month =date.getMonth() + 1 > 9? date.getMonth() + 1: '0' + (date.getMonth() + 1)
      var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
      var hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
      var min =date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      var sec =date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
      var todayDate =year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec
      return todayDate
    }

    const getExaminationConfig=()=>{
      defHttp.get('/trainer/System/ExaminationConfig').then(data=>{
        if(data.Success){
          examinationConfig.value=data.Data
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const computeType=(val)=>{
      if(val!=undefined){
        return typeList.value.find(p=>p.id==val).name
      }
    }

    const handleSelect=(index)=>{
      if(examinationConfig.value.EnableBackAnswer){
        questionIndex.value=index
        currentQuestion.value=questionsList.value[questionIndex.value]
        if(currentQuestion.value.Attachments!=''){
          currentQuestion.value.Attachments=JSON.parse(currentQuestion.value.Attachments)
        }
        handleLayIn()
      }
    }

    // 下一题
    const nextQuestion=()=>{
      if(examinationConfig.value.EnableSkipAnswer){
        if(questionsList.value[questionIndex.value].SutdentAnswers){
          if(questionIndex.value+1<questionsList.value.length){
            questionIndex.value=questionIndex.value+1
            currentQuestion.value=questionsList.value[questionIndex.value]
            if(currentQuestion.value.Attachments!=''){
              currentQuestion.value.Attachments=JSON.parse(currentQuestion.value.Attachments)
            }
          }
        }else{
          message.error('答完此题才可去到下一题')
        }
      }else{
        if(questionIndex.value+1<questionsList.value.length){
          questionIndex.value=questionIndex.value+1
          currentQuestion.value=questionsList.value[questionIndex.value]
          if(currentQuestion.value.Attachments!=''){
            currentQuestion.value.Attachments=JSON.parse(currentQuestion.value.Attachments)
          }
        }
      }
      handleLayIn()
    }

    const handleLayIn=()=>{
      let paperInfoDetail=JSON.parse(localStorage.getItem('paperInfo'))
      let index=-1
      if(paperInfoDetail){
        index=paperInfoDetail.findIndex(p=>p.id==examinationInfo.value.Id)
        if(index>=0){
          let list={
            id:examinationInfo.value.Id,
            questions:questionsList.value
          }
          paperInfoDetail[index]=list
          localStorage.setItem('paperInfo',JSON.stringify(paperInfoDetail))
        }else{
          let list=[{
            id:examinationInfo.value.Id,
            questions:questionsList.value
          }]
          localStorage.setItem('paperInfo',JSON.stringify(list))
        }
      }else{
        let list=[{
          id:examinationInfo.value.Id,
          questions:questionsList.value
        }]
        localStorage.setItem('paperInfo',JSON.stringify(list))
      }
    }

    // 上一题
    const PreviousQuestion=()=>{
      questionIndex.value=questionIndex.value-1
      currentQuestion.value=questionsList.value[questionIndex.value]
      if(currentQuestion.value.Attachments!=''){
        currentQuestion.value.Attachments=JSON.parse(currentQuestion.value.Attachments)
      }
      handleLayIn()
    }

    const handleMarked=()=>{
      if(questionsList.value[questionIndex.value].isMarked){
        questionsList.value[questionIndex.value].isMarked=false
      }else{
        questionsList.value[questionIndex.value].isMarked=true
      }
    }

    const beforeUpload = (file) => {
      let size = file.size / 1024 / 1024
      if (size > 20) {
          createMessage.error('上传文件太大',1.5,)
          return false
      }
      return true;
    }

    const customRequest = (file) => {
      const formData = new FormData();
      formData.append("file",file.file);
      defHttp.post('/platform/Common/Upload',formData).then(data=>{
        if(data.Success){
          let list={
            url:data.Data[0],
            uid:file.file.uid,
            name:file.file.name,
          }
          questionsList.value[questionIndex.value].uploadAttachments.push(list)
          handleLayIn()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handleRemove=(index)=>{
      questionsList.value[questionIndex.value].uploadAttachments.splice(index,1)
      handleLayIn()
    }

    const handleSubmit=()=>{
      questionsList.value.forEach(item=>{
        if(item.Type==0||item.Type==3){
          if(item.SutdentAnswers){
            item.SutdentAnswers=[item.SutdentAnswers]
          }
        }else if(item.Type==4){
          if(item.SutdentAnswers){
            item.SutdentAnswers=[]
          }
        }
      })
      let answers=[]
      questionsList.value.forEach(item=>{
        let answersObj={
          id:item.Id,
          answerOpts:item.SutdentAnswers,
          attachments:JSON.stringify(item.uploadAttachments),
          remark:item.remark
        }
        answers.push(answersObj)
      })
      let detail={
        paperId:examinationInfo.value.Id,
        answers:answers
      }
      loading.value=true
      defHttp.post('/student/Examination/Submit',detail).then(data=>{
        loading.value=false
        if(data.Success){
          message.success('交卷成功')
          setTimeout(()=>{
            router.push({path:'/evaluation'})
          },2000)
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {loading.value=false})
    }

    const hangdeRadio=()=>{
      currentQuestion.value.SutdentAnswers=String(currentQuestion.value.SutdentAnswers)
    }

    return {
      radioStyle,
      getExaminationDetail,
      examinationInfo,
      questionsList,
      getTypeList,
      typeList,
      checkboxValue,
      getExaminationConfig,
      spinning,
      currentQuestion,
      questionIndex,
      computeType,
      alphabet,
      nextQuestion,
      PreviousQuestion,
      handleSubmit,
      loading,
      handleMarked,
      explainModal,
      changeDate,
      countDown,
      countDownTime,
      directlyDownload,
      beforeUpload,
      customRequest,
      fileList,
      handleRemove,
      finishTime,
      getFinishTime,
      handleSelect,
      examinationConfig,
      handleLayIn,
      hangdeRadio,
      computeIndex
    };
  },
});
</script>
<style scoped>
.examHeader{
  width: 100%;
  height: 88px;
  background: #FFFFFF;
  border-bottom: 2px solid #CCCCCC;
}
.examHeaderCore{
  width: 62.5%;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.examName{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
}
.examExplain{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 88px;
}
.submitBtn{
  margin: auto 0 auto 20px;
}
:deep(.submitBtn,.ant-btn-lg) {
  padding: 6.4px 30px !important;
}
.examContent{
  padding-top: 50px;
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.circumstances{
  width: 23%;
  height: 652px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  border-radius: 10px;
  box-sizing: border-box;
}
.answerArea{
  width: 75.5%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  border-radius: 10px;
  padding: 30px 50px 60px 50px;
  box-sizing: border-box;
  position: relative;
}
.examinationTime{
  padding: 24px 0 17px 0;
  text-align: center;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  border-radius: 10px 10px 0px 0px;
}
.countDown{
  font-size: 32px;
  font-weight: 400;
  color: #333333;
  line-height: 37px;
}
.countdownTitle{
  padding-top: 22px;
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
}
.excuseList{
  height: 529px;
  padding: 20px 15px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subjectTotal{
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.subjectType{
  padding-top: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.subjectBlock{
  display: flex;
  flex-wrap: wrap;
}
.subjectItem{
  margin: 10px 15px 0 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 1px solid #CCCCCC;
}
.accomplish{
  background: #55ABFF;
  border: 1px solid #55ABFF;
  color: #FFFFFF !important;
}
.notDone{
  border: 1px solid #CCCCCC;
  color: #333333;
}
.mark{
  background: #0960bd;
  border: 1px solid #0960bd !important;
  color: #FFFFFF !important;
}
.active{
  border: 1px solid #55ABFF;
  color: #333333;
}
.subjectTypeTips{
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
}
.tipsItem{
  display: flex;
}
.TipsColor{
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.tipsType{
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
.title{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.fraction{
  color: #999999;
  min-width: 58px;
}
.markBtn{
  margin-left: 4px;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #999999;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.groupBlock{
  padding-top: 30px;
}
.NextQuestion{
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align:center;
  padding-top: 20px;
  background-color: #FFFFFF;
}
:deep(.NextQuestion .ant-btn) {
  padding: 4px 150px !important;
}
.questions{
  max-height: 500px;
  overflow-y: scroll;
}
.questions::-webkit-scrollbar { 
  width: 0; 
}
:deep(.questions .ant-radio-wrapper-checked span){
  color: var(--theme) !important;
}
.activeMark{
  border: 1px solid var(--theme);
  color: var(--theme) !important;
}
:deep(.ant-checkbox-wrapper){
  margin-left: 0 !important;
}
.remarks{
  width: 80%;
  background: #F6F6F6 !important;
  border-radius: 10px;
}
.remarks:hover{
  background: #F6F6F6;
}
.titleType{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.appendix{
  padding-left: 74px;
}
.uploadBtn{
  width: 106px;
  height: 32px;
  background: #0A5AFF;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 32px;
  cursor: pointer;
}
.downloadBtn{
  width: 60px;
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgba(10,90,255,0.12);
  font-size: 14px;
  font-weight: 500;
  color: #0A5AFF;
  line-height: 32px;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
}
.appendixList{
  line-height: 32px;
}
.uploadList{
  padding-left: 40px;
  padding-top: 16px;
}
</style>
