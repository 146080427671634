<template>
  <div class="practice">
    <div v-if="!graduated">
      <!-- <div class="searchContent">
        <div class="search">
          <a-space :size='30'>
            <a-input-search v-model:value="searchForm.search" size="large" class="searchBlock" placeholder="模糊查询"
              enter-button @search="getList" />
            <a-button type="primary" size="large" :loading="table.operating" @click="getList">查询</a-button>
            <a-button type="primary" ghost size="large" :loading="table.operating"
              @click="searchModalVisible = true">高级查询</a-button>
          </a-space>
        </div>
      </div> -->
      <div class="practiceCore">
        <a-spin :spinning="table.operating">
          <a-tabs v-model:activeKey="activeKey" size="large" @change="handleChange">
            <a-tab-pane :key="1" tab="工单列表">
              <!-- <div class="tableBlock">
                <a-table :data-source="table.list"
                        row-key="ID"
                        :loading="table.loading"
                        class="ant-table-striped border"
                        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                        :pagination="table.pageConfig"
                        @change="pageChange">
                    <a-table-column data-index="id" title="序号">
                      <template #default="{record,index}">
                        <span>{{index+1}}</span>
                      </template>
                    </a-table-column>
                    <a-table-column data-index="Id" title="编号"></a-table-column>
                    <a-table-column data-index="SkuDesc" title="风格类型"></a-table-column>
                    <a-table-column data-index="PartnerName" title="合作商"></a-table-column>
                    <a-table-column data-index="CreateDate" title="添加时间"></a-table-column>
                    <a-table-column data-index="periodicAlarm" title="周期警告">
                      <template #default="{record}">
                        <span>{{ record.PassedDays }}/{{ record.DaySpan }}</span>
                      </template>
                    </a-table-column>
                    <a-table-column data-index="StatusDesc" title="状态"></a-table-column>
                    <a-table-column data-index="Id" title="操作" fixed="right" align="center">
                      <template #default="{record}">
                        <a-space>
                            <a-popconfirm title="您确定要接单吗？"
                                          v-if="record.Status!=-1"
                                          ok-text="确定"
                                          cancel-text="取消"
                                          @confirm="confirmReceiving(record)"
                                          placement="bottom">
                              <a-button type="text" size="small" :loading="table.operating">接单</a-button>
                            </a-popconfirm>
                            <a-button type="text" size="small" :loading="table.operating" @click="handleDetail(record,1)">详情</a-button>
                        </a-space>
                      </template>
                    </a-table-column>
                </a-table>
              </div> -->
              <div class="tableBlock">
                <a-space style="margin-bottom: 16px;">
                  <a-select  @change="getPendingOrders(true)" style="width: 200px;" v-model:value="searchForm.status" allow-clear placeholder="选择状态">
                      <a-select-option :value="null">全部状态</a-select-option>
                      <a-select-option :value="item.id" v-for="(item, index) in stateList" :key="index">{{ item.name }}</a-select-option>
                  </a-select>
                  <a-button type="primary"  @click="getPendingOrders(true)">搜索</a-button>
                </a-space>
                <div v-if="table.list.length">
                  <div class="studentItem" v-for="(item, index) in table.list" :key="index">
                    <div class="studentInfo">
                      <div class="studentName">
                        <div>{{ item.SkuDesc }}</div>
                        <div class="StatusDesc">{{ item.StatusDesc }}</div>
                      </div>
                      <div class="integral" v-if="item.Integral >= 0">{{ item.Integral }}<span
                          class="integralText">积分</span>
                      </div>
                    </div>
                    <div class="otherInfo">
                      <a-space :size="30">
                        <div>编号：{{ item.Id }}</div>
                        <div>风格类型：{{ item.SkuDesc }}</div>
                        <div>合作商：{{ item.PartnerName }}</div>
                        <div>添加时间：{{ item.CreateDate }}</div>
                      </a-space>
                      <a-space :size="0">
                        <a-popconfirm title="您确定要接单吗？" v-if="item.Status != -1" ok-text="确定" cancel-text="取消"
                          @confirm="confirmReceiving(item)" placement="bottom">
                          <a-button type="text" size="small" style="color:#55ABFF;">接单</a-button>
                        </a-popconfirm>
                        <a-button type="text" size="small"  style="color:#55ABFF;"
                          @click="handleDetail(item, 1)">详情</a-button>
                      </a-space>
                    </div>
                  </div>
                  <a-pagination style="text-align: right;" v-model:current="table.pageConfig.current"
                    :total="table.pageConfig.total" show-less-items size="small" @change="pageChange" />
                </div>
                <a-empty v-else description="暂无数据" />
              </div>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="我的工单">
              <!-- <div class="tableBlock">
                <a-table :data-source="table.list"
                        row-key="ID"
                        :loading="table.loading"
                        class="ant-table-striped border"
                        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                        :pagination="table.pageConfig"
                        @change="pageChange">
                    <a-table-column data-index="id" title="序号">
                      <template #default="{record,index}">
                        <span>{{index+1}}</span>
                      </template>
                    </a-table-column>
                    <a-table-column data-index="id" title="编号"></a-table-column>
                    <a-table-column data-index="modelName" title="风格类型"></a-table-column>
                    <a-table-column data-index="partner" title="合作商"></a-table-column>
                    <a-table-column data-index="addTime" title="添加时间"></a-table-column>
                    <a-table-column data-index="periodicAlarm" title="周期警告"></a-table-column>
                    <a-table-column data-index="status" title="状态"></a-table-column>
                    <a-table-column data-index="Id" title="操作" fixed="right" align="center">
                      <template #default="{record}">
                        <a-space>
                          <a-button type="text" size="small" :loading="table.operating" @click="handleDetail(record,2)">详情</a-button>
                          <a-button type="text" size="small" :loading="table.operating" @click="handleSubmit(record)">提交</a-button>
                        </a-space>
                      </template>
                    </a-table-column>
                </a-table>
              </div> -->
              <div class="tableBlock">
                <a-space style="margin-bottom: 16px;">
                  <a-select  @change="getList(true)" style="width: 200px;" v-model:value="searchForm.status" allow-clear placeholder="选择状态">
                      <a-select-option :value="null">全部状态</a-select-option>
                      <a-select-option :value="item.id" v-for="(item, index) in stateList" :key="index">{{ item.name }}</a-select-option>
                  </a-select>
                  <a-button type="primary"  @click="getList(true)">搜索</a-button>
                </a-space>
                <div v-if="table.list.length">
                  <div class="studentItem" v-for="(item, index) in table.list" :key="index">
                    <div class="studentInfo">
                      <div class="studentName">
                        <div>{{ item.modelName }}</div>
                        <div class="StatusDesc">{{ item.status }}</div>
                      </div>
                      <div class="integral" v-if="item.integral >= 0">{{ item.integral }}<span
                          class="integralText">积分</span>
                      </div>
                    </div>
                    <div class="otherInfo">
                      <a-space :size="30">
                        <div>编号：{{ item.id }}</div>
                        <div>风格类型：{{ item.modelName }}</div>
                        <div>合作商：{{ item.partner }}</div>
                        <div>添加时间：{{ item.addTime }}</div>
                      </a-space>
                      <a-space :size="0">
                        <a-button type="text" size="small" :loading="table.operating" style="color:#55ABFF;"
                          @click="handleDetail(item, 2)">详情</a-button>
                        <a-button type="text" size="small" v-if="item.status == '制作中'" :loading="table.operating"
                          style="color:#55ABFF;" @click="handleSubmit(item)">提交</a-button>
                        <a-button type="text" size="small" v-if="item.status == '制作中'" :loading="table.operating"
                          style="color:#55ABFF;" @click="handleUpload(item)">上传作品</a-button>
                        <a-popconfirm title="您确定要取消吗？" @confirm="confirmCancel(item)" placement="bottom">
                          <a-button type="text" size="small" :loading="table.operating" style="color:#55ABFF;">取消</a-button>
                        </a-popconfirm>
                      </a-space>
                    </div>
                  </div>
                  <a-pagination style="text-align: right;" v-model:current="table.pageConfig.current"
                    :total="table.pageConfig.total" show-less-items size="small" @change="pageChange" />
                </div>
                <a-empty v-else description="暂无数据" />
              </div>
            </a-tab-pane>
            <!-- <a-tab-pane :key="3" tab="智能工具">
              <div class="tableBlock">
                下载智能自检工具安卓版本: <a href="https://yscm2022.oss-cn-beijing.aliyuncs.com/zhinenggongju-apk.zip">点击下载</a>
              </div>
            </a-tab-pane> -->
          </a-tabs>
        </a-spin>
      </div>
    </div>
    <div v-else class="graduateState">
      <img class="graduateImg" src="../../assets/image/graduateImg.png" alt="">
      <div class="graduateText">您已毕业已经是一名合格的设计师，加入数字人创梦接单平台与我们一起见证元宇宙的发展<a style="color:#55ABFF;"
          href="http://niuniuer.cn/user/login/index">http://niuniuer.cn/user/login/index</a><br>（使用当前平台账号密码或手机号验证方式可直接登录系统）
      </div>
    </div>
    <a-modal title="提交工单" v-model:visible="workOrderModal.visible" :keyboard="false" width="750px" class="" centered
      :maskClosable="false" :destroyOnClose="true" >
      <section class="aj-padding-top-lg">
        <a-form :model="workOrderModal.form" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }"
          :rules="workOrderModal.rules" ref="formIns">
          <a-form-item label="工单编号">
            <span>{{ workOrderModal.form.id }}</span>
          </a-form-item>
          <a-form-item label="反馈详情" name="feedback">
            <a-textarea v-model:value="workOrderModal.form.feedback" style="resize:none;" :rows="4" placeholder="请输入内容" />
          </a-form-item>
        </a-form>
      </section>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="preservation" :loading="workOrderModal.processing"> 保存 </a-button>
        </a-space>
      </template>
    </a-modal>

    <a-modal title="上传作品" v-model:visible="mouldModal.visible" :keyboard="false" width="800px" centered :maskClosable="false">
      <section class="aj-padding-top-lg" v-if="mouldModal.visible">
        <a-form :model="mouldModal.form" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }" :rules="mouldModal.rules"
          ref="mouldFormIns">
          <a-form-item label="上传作品" name="models">
            <div>
              <a-upload v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequest"
                :before-upload="beforeUpload" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                <a-button type="primary">
                  <UploadOutlined />
                  点击上传
                </a-button>
              </a-upload>
              <div class="lineHeight32">
                <div v-for="(item, index) in mouldModal.form.models" :key="index">
                  <a-space>
                    <div style="max-width: 500px;" >
                      <a style="color:#115BA4;" :href="item.url" :download="item.name">{{ item.name }}</a>
                    </div>
                    <a-button type="primary" ghost size="small" @click="handleDeleteModels(index)">删除</a-button>
                  </a-space>
                </div>
              </div>
            </div>
          </a-form-item>
          <a-form-item label="上传作品截图" name="thumbnails">
            <div>
              <a-upload v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequestImg"
                :before-upload="beforeUploadImg" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                <a-button type="primary">
                  <UploadOutlined />
                  点击上传
                </a-button>
              </a-upload>
              <div class="aj-flex aj-flex-wrap">
                <div v-for="(item, index) in mouldModal.form.thumbnails" :key="index">
                  <div class="imageBlock aj-margin-right-sm">
                    <img class="aj-padding-top uploadImg" :src="item.url" alt="">
                    <div class="deleteImg" @click="handleDeleteImg(index)">删除</div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
          <a-form-item label="是否上传到作品墙" name="photoWall">
            <a-radio-group v-model:value="mouldModal.form.photoWall">
              <a-radio :value="true">是</a-radio>
              <a-radio :value="false">否</a-radio>
            </a-radio-group>
          </a-form-item>
          <div v-if="mouldModal.form.photoWall">
            <a-form-item label="模型类型" name="modelId" :wrapper-col="{ span: 12}">
              <a-select v-model:value="mouldModal.form.modelId" placeholder="选择模型类型">
                <a-select-option :value="item.Id" v-for="(item,index) in modelList" :key="index">{{item.Name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="风格类型" name="styleId" :wrapper-col="{ span: 12}">
              <a-select v-model:value="mouldModal.form.styleId" placeholder="选择风格类型">
                <a-select-option :value="item.Id" v-for="(item,index) in styleList" :key="index">{{item.Name}}</a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </a-form>
      </section>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="handleSubmitMould" :loading="mouldModal.processing"> 保存 </a-button>
        </a-space>
      </template>
    </a-modal>

    <a-modal title="高级查询" v-model:visible="searchModalVisible" :keyboard="false" width="750px" centered
      :maskClosable="false" :destroyOnClose="true">
      <section class="aj-padding-top-lg">
        <a-form :model="searchForm" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
          <a-form-item label="编号">
            <a-input v-model:value="searchForm.encoded" placeholder="请输入编号"></a-input>
          </a-form-item>
          <a-form-item label="风格类型">
            <a-input v-model:value="searchForm.model" placeholder="请输入风格类型"></a-input>
          </a-form-item>
          <a-form-item label="合作商">
            <a-input v-model:value="searchForm.partner" placeholder="请输入合作商"></a-input>
          </a-form-item>
          <a-form-item label="状态">
            <a-select v-model:value="searchForm.status" placeholder="状态" allowClear style="width:200px;">
              <a-select-option :value="null">全部状态</a-select-option>
              <a-select-option :value="item.id" v-for="(item, index) in stateList" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </section>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="handleDefine">确定</a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, unref, toRaw } from 'vue';
import { DownOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  name: 'practice',
  components: {
    DownOutlined,
    UploadOutlined
  },
  setup() {
    const activeKey = ref(1)
    const fileList = ref([])
    const router = useRouter()
    const formIns = ref()
    const stateList = ref([])
    const modelList=ref([])
    const styleList=ref([])
    const mouldFormIns = ref()
    const searchForm = ref({
      search: '',
      encoded: '',
      model: '',
      partner: '',
      status: null,
    })
    const graduated = ref(false)
    const searchModalVisible = ref(false)
    const table = ref({
      list: [],
      pageConfig: {
        size: 'small',
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => { return `共 ${total}` }
      },
      loading: false,
      operating: false
    })
    const workOrderModal = ref({
      visible: false,
      processing: false,
      form: {
        id: 0,
        feedback: '',
      },
      rules: {
        feedback: [{
          required: true,
          trigger: 'blur',
          message: '该项必须填写',
          type: 'string'
        }],
      }
    })

    const mouldModal = ref({
      visible: false,
      processing: false,
      form: {
        id: 0,
        models: [],
        thumbnails: [],
        photoWall:true,
        modelId:null,
        styleId:null,
      },
      rules: {
        models: [{
          required: true,
          trigger: 'blur',
          message: '该项必须选择',
          type: 'array'
        }],
        thumbnails: [{
          required: true,
          trigger: 'blur',
          message: '该项必须选择',
          type: 'array'
        }],
        modelId: [{
          required: true,
          trigger: 'change',
          message: '该项必须选择',
          type: 'string'
        }],
        styleId: [{
          required: true,
          trigger: 'change',
          message: '该项必须选择',
          type: 'string'
        }],
      }
    })

    onMounted(() => {
      getPendingOrders()
      getStateList()
      let info = JSON.parse(localStorage.getItem('userInfo'))
      graduated.value = info.graduated
      getClassifyList()
    })

    const pageChange = (current, pageSize) => {
      table.value.pageConfig.current = current
      table.value.pageConfig.pageSize = pageSize
      if (activeKey.value == 1) {
        getPendingOrders()
      } else {
        getList()
      }
    }

    const getClassifyList=()=>{
      defHttp.get('/trainer/PhotoWall/CategoryList', { params: {type:0,page:1,limit:999} }).then(data => {
        if (data.Success) {
          modelList.value=data.Data.Rows
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
      defHttp.get('/trainer/PhotoWall/CategoryList', { params: {type:1,page:1,limit:999} }).then(data => {
        if (data.Success) {
          styleList.value=data.Data.Rows
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }

    const getList = (isSearch = false) => {
      if (isSearch) {
        table.value.pageConfig.current = 1
      }
      table.value.operating = true
      defHttp.get('/student/Practice/list', { params: { ...searchForm.value, page: table.value.pageConfig.current, limit: table.value.pageConfig.pageSize, } }).then(data => {
        table.value.operating = false
        if (data.Success) {
          table.value.list = data.Data.Rows
          table.value.pageConfig.total = data.Data.TotalItemCount
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.operating = false })
    }

    const getPendingOrders = (isSearch = false) => {
      if (isSearch) {
        table.value.pageConfig.current = 1
      }
      let status = searchForm.value.status
      if (typeof searchForm.value.status === 'number') {
          status = [searchForm.value.status]
      }
      table.value.operating = true
      defHttp.post('/system/order/list', {
        search: searchForm.value.search,
        status,
        page: table.value.pageConfig.current,
        limit: table.value.pageConfig.pageSize,
      }).then(data => {
        table.value.operating = false
        if (data.Success) {
          table.value.list = data.Data.Rows
          table.value.pageConfig.total = data.Data.TotalItemCount
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.operating = false })
    }

    const confirmCancel = (record) => {
      table.value.operating = true
      defHttp.post(`/student/Practice/Cancel?id=${record.id}`).then(data => {
        table.value.operating = false
        if (data.Success) {
          message.success('取消成功')
          table.value.pageConfig.current = 1
          table.value.list = []
          getList()
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.operating = false })
    }

    const confirmReceiving = (record) => {
      table.value.operating = true
      defHttp.get('student/practice/receiving', { params: { id: record.Id } }).then(data => {
        table.value.operating = false
        if (data.Success) {
          message.success('接单成功')
          if (activeKey.value == 1) {
            getPendingOrders()
          } else {
            getList()
          }
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { table.value.operating = false })
    }

    const handleSubmit = (record) => {
      workOrderModal.value.form.feedback = ''
      workOrderModal.value.form.id = record.id
      workOrderModal.value.visible = true
    }

    const handleUpload = (record) => {
      mouldModal.value.form = {
        id: record.id,
        models: [],
        thumbnails: [],
        photoWall:true,
        modelId:null,
        styleId:null,
      }
      mouldModal.value.visible = true
    }

    const preservation = () => {
      formIns.value.validate().then(() => {
        workOrderModal.value.processing = true
        const formData = { ...toRaw(unref(workOrderModal.value.form)) }
        delete formData.code
        defHttp.get('student/practice/submit', { params: { ...formData } }).then(data => {
          workOrderModal.value.processing = false
          if (data.Success) {
            message.success('提交成功');
            workOrderModal.value.visible = false
            if (activeKey.value == 1) {
              getPendingOrders()
            } else {
              getList()
            }
          } else {
            message.error(`${data.Message}`)
            workOrderModal.value.visible = false
          }
        }).catch(err => { table.value.operating = false })
      }).catch(() => { workOrderModal.value.processing = false })
    }

    const getStateList = () => {
      defHttp.get('platform/Common/Enum', { params: { name: 'Orderstatus' } }).then(data => {
        if (data.Success) {
          stateList.value = data.Data
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const handleDefine = () => {
      searchModalVisible.value = false
      if (activeKey.value == 1) {
        getPendingOrders()
      } else {
        getList()
      }
    }

    const handleDetail = (record, type) => {
      if (type == 1) {
        router.push({ path: '/practiceDetail', query: { id: record.Id } })
      } else {
        router.push({ path: '/practiceDetail', query: { id: record.id } })
      }
    }

    const handleChange = (value) => {
      table.value.pageConfig.current = 1
      searchForm.value.status = null
      table.value.list = []
      if (value == 1) {
        getPendingOrders()
      } else {
        getList()
      }
    }

    const customRequest = (file) => {
      message.loading({
        content: '正在上传...',
        key: 'upload',
        duration: 0
      })
      const formData = new FormData();
      formData.append("file", file.file);
      defHttp.post('/platform/Common/Upload', formData).then(data => {
        if (data.Success) {
          let list = {
            url: data.Data[0],
            id: file.file.uid,
            name: file.file.name,
            percent: 100,
            status: 'done'
          }
          mouldModal.value.form.models.push(list)
          message.success({
            key: 'upload',
            content: '上传成功!',
            duration: 2
          })
        } else {
          message.error({
            key: 'upload',
            content: data.Message,
            duration: 2
          })
        }
      }).catch(() => {
        message.error({
          key: 'upload',
          content: '上传失败!',
          duration: 2
        })
      })
    }

    const beforeUpload = (file) => {
      let size = file.size / 1024 / 1024
      // if (size > 20) {
      //     createMessage.error('上传文件太大',1.5,)
      //     return false
      // }
      return true;
    }

    const customRequestImg = (file) => {
      const formData = new FormData();

      formData.append("file", file.file);
      defHttp.post('/platform/Common/Upload', formData).then(data => {
        if (data.Success) {
          let list = {
            url: data.Data[0],
            id: file.file.uid,
            name: file.file.name,
            percent: 100,
            status: 'done'
          }
          mouldModal.value.form.thumbnails.push(list)
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const beforeUploadImg = (file) => {
      let size = file.size / 1024 / 1024
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
        return false
      }
      if (size > 20) {
        createMessage.error('上传文件太大', 1.5,)
        return false
      }
      return true;
    }

    const handleDeleteImg = (index) => {
      mouldModal.value.form.thumbnails.splice(index, 1)
    }

    const handleDeleteModels = (index) => {
      mouldModal.value.form.models.splice(index, 1)
    }

    const handleSubmitMould = () => {
      mouldFormIns.value.validate().then(() => {
        mouldModal.value.processing = true
        const formData = { ...toRaw(unref(mouldModal.value.form)) }
        formData.models = JSON.stringify(formData.models)
        formData.thumbnails = JSON.stringify(formData.thumbnails)
        defHttp.post('/system/Order/SaveModels', formData).then(data => {
          if (data.Success) {
            message.success('上传成功');
            mouldModal.value.visible = false
            mouldModal.value.processing = false
          } else {
            message.error(`${data.Message}`)
          }
        }).catch(err => { mouldModal.value.processing = false })
      }).catch(() => { mouldModal.value.processing = false })
    }

    return {
      table,
      searchForm,
      pageChange,
      getList,
      handleDetail,
      confirmReceiving,
      workOrderModal,
      handleSubmit,
      preservation,
      formIns,
      searchModalVisible,
      getStateList,
      stateList,
      handleDefine,
      activeKey,
      handleChange,
      getPendingOrders,
      graduated,
      handleUpload,
      fileList,
      customRequest,
      beforeUpload,
      mouldModal,
      customRequestImg,
      beforeUploadImg,
      handleDeleteImg,
      handleDeleteModels,
      handleSubmitMould,
      mouldFormIns,
      confirmCancel,
      styleList,
      modelList
    };
  },
});
</script>
<style scoped>
.practice {
  width: 100%;
  background-color: #f0f2f5;
}

.practiceCore {
  padding: 28px 0;
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
}

:deep(.ant-table-thead tr th) {
  background-color: rgba(0, 0, 0, 0.09);
  /* border-radius: 15px; */
}

.searchContent {
  width: 100%;
  background-color: #fff;
  padding: 26px 0;
}

.search {
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
}

.tableBlock {
  padding: 30px 44px;
  border-radius: 8px;
  background-color: #fff;
}

.studentItem {
  padding-bottom: 16px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 16px;
}

.studentInfo {
  display: flex;
  justify-content: space-between;
}

.studentName {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  line-height: 24px;
  display: flex;
}

.StatusDesc {
  padding: 0px 6px;
  border-radius: 2px;
  border: 1px solid #55ABFF;
  font-size: 10px;
  font-weight: 400;
  color: #55ABFF;
  height: 19px;
  line-height: 19px;
  margin: auto 0 auto 14px;
}

.otherInfo {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.graduateState {
  padding-top: 135px;
  text-align: center;
}

.graduateImg {
  width: 286px;
  height: 220px;
}

.graduateText {
  padding-top: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.lineHeight32 {
  line-height: 32px;
}

.formContent {
  padding-left: 24px;
}

.imageBlock {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
}

.uploadImg {
  width: 100%;
  height: 100%;
}

.deleteImg {
  width: 100%;
  height: 28px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

:deep(.ant-tabs-nav::before) {
  border-bottom: 0 !important;
}

.integral {
  font-size: 20px;
  font-weight: 900;
  color: #0960bd;
  line-height: 27px;
  letter-spacing: 2px;
}

.integralText {
  font-size: 14px;
}
</style>