<template>
  <div class="baseContent">
    <a-spin :spinning="spinning">
      <div class="baseInfo">
        <div class="base">
          <div class="baseBlock">
            <img class="userImg" v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
            <img class="userImg" v-else src="../../assets/image/defaultHaderImg.png" alt="">
            <div class="info">
              <div class="name">{{userInfo.username}}</div>
              <WomanOutlined v-if="userInfo.gender==1" class="womanIcon" :rotate="45" />
              <ManOutlined v-if="userInfo.gender==0" class="manIcon" />
            </div>
          </div>
          <div class="baseMenuList">
            <div class="baseMenuItem" :class="activeTab==1?'activeTab':''" @click="handleTab(1)">
              <img class="baseMenuIcon" src="../../assets/image/menuIcon4.png" alt="">
              <div class="baseMenuName">账号管理</div>
            </div>
            <div class="baseMenuItem" :class="activeTab==2?'activeTab':''" @click="handleTab(2)">
              <img class="baseMenuIcon" src="../../assets/image/menuIcon5.png" alt="">
              <div class="baseMenuName">密码修改</div>
            </div>
          </div>
        </div>
        <div class="userInfo" v-if="activeTab==1">
          <a-upload
              v-model:file-list="fileList"
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :customRequest="customRequest"
          >
            <div class="headSculpture">
              <img class="headerImg" v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
              <img class="headerImg" v-else src="../../assets/image/defaultHaderImg.png" alt="">
              <div class="shade">修改头像</div>
            </div>
          </a-upload>
          <div class="info infoBlock">
            <div class="name">{{userInfo.username}}</div>
            <WomanOutlined v-if="userInfo.gender==1" class="womanIcon" :rotate="45" />
            <ManOutlined v-if="userInfo.gender==0" class="manIcon" />
          </div>
          <div class="baseInfoTitle">基本信息</div>
          <div class="infoContent">
            <div class="flex">
              <div class="infoItem">
                <div class="infoTitle">账户：</div>
                <div class="infoDetail">{{userInfo.account}}</div>
              </div>
              <div class="infoItem">
                <div class="infoTitle">专业：</div>
                <div class="infoDetail">{{userInfo.specializedSubject}}</div>
              </div>
            </div>
            <div class="flex">
              <div class="infoItem">
                <div class="infoTitle">性别：</div>
                <div class="infoDetail">{{userInfo.gender==0?'男':userInfo.gender==1?'女':'未知'}}</div>
              </div>
              <div class="infoItem">
                <div class="infoTitle">年级：</div>
                <div class="infoDetail">{{userInfo.grade}}级</div>
              </div>
            </div>
            <div class="flex">
              <div class="infoItem">
                <div class="infoTitle">学院：</div>
                <div class="infoDetail">{{userInfo.college}}</div>
              </div>
              <div class="infoItem">
                <div class="infoTitle">班级：</div>
                <div class="infoDetail">{{userInfo.className}}</div>
              </div>
            </div>
            <div class="infoItem">
              <div class="infoTitle">备注：</div>
              <a-textarea class="remarks" v-model:value="userInfo.remarks" :rows="4" style="resize:none;" :bordered="false" placeholder="不超过80个字" />
            </div>
            <div class="infoItem">
              <div class="infoTitle">手机号：</div>
              <div class="" v-if="userInfo.phone">
                <span>{{changePhone(userInfo.phone)}}</span> 
                <span class="aj-margin-left-sm clickBlock" @click="Unbinding" v-if="userInfo.phone">解绑</span>
                <!-- <span class="aj-margin-left-sm clickBlock" v-if="userInfo.phone">更换</span> -->
              </div>
              <div class="clickBlock" v-if="binding&&!userInfo.phone" @click="binding=false">绑定手机</div>
              <div class="flex aj-justify-between aj-flex-sub" v-if="!binding">
                <div class="flex">
                  <a-input v-model:value="newForm.phone" class="postbox" :bordered="false" style="width:200px"></a-input>
                  <div class="aj-margin-left-xl">
                    <span>短信验证码：</span>
                    <a-input v-model:value="newForm.code" class="postbox" :bordered="false" style="width:200px">
                      <template #suffix><a-button class="checkingBtn" :disabled="verifyCode.disabled" type="link" @click="handleSend">{{verifyCode.text}}</a-button></template>
                    </a-input>
                  </div>
                </div>
                <a-button type="primary" @click="handleNext">确定</a-button>
              </div>
            </div>
            <div class="infoItem">
              <div class="infoTitle" style="line-height:30px;">邮箱：</div>
              <a-input style="width:85%;" class="postbox" v-model:value="userInfo.postbox" :bordered="false" placeholder="请输入邮箱" />
            </div>
            <!-- <div class="infoItem">
              <div class="infoTitle">课程分类：</div>
              <div class="direction" v-if="editDirection">{{isEditDirection?editDirectionName:userInfo.careerOrientation}} 
                <span class="clickBlock aj-margin-left-xs" @click="editDirection=false">编辑</span>
              </div>
              <div class="directionSelect" v-if="!editDirection">
                <a-select v-model:value="careerOrientation" style="width: 200px;" @change="selectCareer">
                  <a-select-option :value="item.id" v-for="(item,index) in careerOrientationList" :key="index">{{item.name}}</a-select-option>
                </a-select>
              </div>
            </div> -->
            <div class="preserve">
              <a-button type="primary" @click="handlePreserve">保存</a-button>
            </div>
            <!-- <div class="baseInfoTitle aj-padding-top-sm">第三方账户</div>
            <div class="tips">绑定第三方账户后，无密码即可登录教师工作中心</div>
            <div class="thirdPartyList">
              <div class="thirdPartyItem">
                <img class="thirdPartyIcon" src="../../assets/image/weixinIcon.png" alt="">
                <div class="thirdPartyInfo">
                  <div class="thirdPartyTitle">微信</div>
                  <div class="binding">未绑定</div>
                </div>
              </div>
              <div class="thirdPartyItem marginLeft55">
                <img class="thirdPartyIcon" src="../../assets/image/qqIcon.png" alt="">
                <div class="thirdPartyInfo">
                  <div class="thirdPartyTitle">QQ</div>
                  <div class="binding">未绑定</div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="userInfo reviseBlock" v-if="activeTab==2">
          <a-form :model="modifyInfo"
                  :labelCol="{span:3}"
                  :wrapperCol="{span:10}"
                  :rules="rules" ref="formIns">
            <a-form-item label="账号">
              {{userInfo.account}}
            </a-form-item>
            <a-form-item label="旧密码" name="oldPassword">
              <a-input-password class="passwordInput" v-model:value="modifyInfo.oldPassword" :bordered="false" placeholder="请输入旧密码" />
            </a-form-item>
            <a-form-item label="新密码" name="newPassword">
              <a-input-password class="passwordInput" v-model:value="modifyInfo.newPassword" :bordered="false" placeholder="请输入新密码" />
            </a-form-item>
            <a-form-item label="确认密码" name="affirmPassword">
              <a-input-password class="passwordInput" v-model:value="modifyInfo.affirmPassword" :bordered="false" placeholder="请确认新密码" />
            </a-form-item>
          </a-form>
          <div class="btnList">
            <a-space :size="20">
              <a-button type="primary" ghost @click="handleTab(1)">返回</a-button>
              <a-button type="primary" @click="handleRevise" :loading="btnLoading">保存</a-button>
            </a-space>
          </div>
        </div>
      </div>
    </a-spin>
    
    <a-modal v-model:visible="UnbindingVisible" width="354px" :closable="false" :footer="null" centered :maskClosable="false">
      <div class="UnbindingModal">
        <div class="UnbindingTitle">是否继续解绑手机号？</div>
        <div class="UnbindingTips">账号在未绑定手机号状态下无法使用手机号登录与找回密码。</div>
        <div class="operateBtnList">
          <a-button type="text" class="operateBtn" @click="handleContinue">继续解绑</a-button>
          <a-button type="primary" class="primaryBtn" @click="UnbindingVisible=fasle">取消</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:visible="checkingVisible" width="430px" :closable="false" :footer="null" centered :maskClosable="false">
      <div class="checkingModal">
        <div class="checkingTitle">验证当前绑定手机号</div>
        <div class="checkingTips">解绑后，您无法再使用该手机号对此账号进行登录及找回密码等操作。</div>
        <div class="point">当前绑定手机号:{{changePhone(userInfo.phone)}}</div>
        <div class="importBlock">
          <span>请输入当前绑定手机号：</span>
          <a-input class="postbox" style="width:240px" v-model:value="contrastPhone" :bordered="false" placeholder="请输入手机号" />
        </div>
        <div class="operateBtnList">
          <a-button type="text" class="operateBtn" @click="checkingVisible=false">取消</a-button>
          <a-button type="primary" class="primaryBtn" @click="handleNextStep">下一步</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:visible="bindingVisible" width="430px" :closable="false" :footer="null" centered :maskClosable="false">
      <div class="checkingModal">
        <div class="checkingTitle">绑定新手机号</div>
        <div class="importBlock paddinTop20">
          <span class="typeTitle">手机号：</span>
          <a-input class="postbox" style="width:200px" v-model:value="newForm.phone" :bordered="false" placeholder="请输入手机号" />
        </div>
        <div class="importBlock paddinTop20">
          <span class="typeTitle">短信验证码：</span>
          <a-input v-model:value="newForm.code" class="postbox" :bordered="false" style="width:200px">
            <template #suffix><a-button class="checkingBtn" :disabled="verifyCode.disabled" type="link" @click="handleSend">{{verifyCode.text}}</a-button></template>
          </a-input>
        </div>
        <div class="operateBtnList">
          <a-button type="text" class="operateBtn" @click="bindingVisible=false">取消</a-button>
          <a-button type="primary" class="primaryBtn" @click="handleNext">下一步</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:visible="accomplishVisible" width="430px" :closable="false" :footer="null" centered :maskClosable="false">
      <div class="accomplishModal">
        <img class="accomplishIcon" src="../../assets/image/accomplishIcon.png" alt="">
        <div class="accomplishText">恭喜你！手机号绑定成功</div>
        <div class="operateBtnList">
          <a-button type="primary" class="primaryBtn" @click="accomplishVisible=false">完成</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref,onMounted } from 'vue';
import { WomanOutlined,ManOutlined } from '@ant-design/icons-vue';
import {useRouter} from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    WomanOutlined,
    ManOutlined
  },
  setup() {
    const spinning=ref(false)
    const router=useRouter()
    const contrastPhone=ref('')
    const editDirection=ref(true)
    const binding=ref(true)
    const UnbindingVisible=ref(false)
    const checkingVisible=ref(false)
    const bindingVisible=ref(false)
    const accomplishVisible=ref(false)
    const userInfo=ref({})
    const careerOrientationList=ref([])
    const careerOrientation=ref(null)
    const fileList=ref([])
    const activeTab=ref(1)
    const isEditDirection=ref(false)
    const editDirectionName=ref(false)
    const isEdit=ref(false)
    const verifyCode=ref({
      text:'发送验证码',
      disabled:false,
      timeoutValue:30,
      intervalId:0,
    })
    const newForm=ref({
      phone:'',
      code:''
    })
    const modifyInfo=ref({
      oldPassword:'',
      newPassword:'',
      affirmPassword:''
    })
    const btnLoading=ref(false)
    const formIns = ref()
    const rules=ref({
      oldPassword: [{
        required: true,
        trigger: 'blur',
        message: '该项必须填写',
        type:'string'
      }],
      newPassword: [{
        required: true,
        trigger: 'blur',
        message: '该项必须填写',
        type:'string'
      }],
      affirmPassword: [{
        required: true,
        trigger: 'blur',
        message: '该项必须填写',
        type:'string'
      }],
    })
    onMounted(() => {
      getBaseInfo()
    })

    const getBaseInfo=()=>{
      spinning.value=true
      defHttp.get('/student/account/userInfo').then(data=>{
        spinning.value=false
        if(data.Success){
          userInfo.value=data.Data
          localStorage.setItem('userInfo',JSON.stringify(userInfo.value))
          getCareerOrientation()
        }else{
          message.error(`${data.Message}`)
        }
      }).catch(err => {spinning.value=false})
    }

    const handleTab=(type)=>{
      activeTab.value=type
    }

    const getCareerOrientation=()=>{
      defHttp.get('student/direction').then(data=>{
        if(data.Success){
          careerOrientationList.value=data.Data
          careerOrientation.value=userInfo.value.careerOrientationId
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handleRehearse=()=>{ 
      router.push({path:'/rehearse'})
    }
    const handleDirection=()=>{ 
      editDirectionName.value=careerOrientationList.value.find(p=>p.id==careerOrientation.value).name
      editDirection.value=true
      isEditDirection.value=true
      isEdit.value=true
    }

    const Unbinding=()=>{
      UnbindingVisible.value=true
    }

    const handleContinue=()=>{
      UnbindingVisible.value=false
      checkingVisible.value=true
      contrastPhone.value=''
    }
    
    const handleNextStep=()=>{
      if(contrastPhone.value==userInfo.value.phone){
        checkingVisible.value=false
        bindingVisible.value=true
      }else{
        message.error('当前手机号验证失败')
      }
    }

    const handleNext=()=>{
      if(!newForm.value.phone){
        message.error('请输入手机号')
        return
      }
      if(!newForm.value.code){
        message.error('请输入验证码')
        return
      }
      defHttp.post(`/student/account/savePhone?phone=${newForm.value.phone}&code=${newForm.value.code}`).then(data=>{
        if(data.Success){
          accomplishVisible.value=true
          getBaseInfo()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const checkModbile=()=>{
      let phone=newForm.value.phone
      var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      var result = re.test(phone); 
      if(!result) {
        message.error('手机号码格式不正确！')
        return false;
      }else{
        return true;
      }
	  }

    const handleSend=()=>{
      if(checkModbile()){
        countDown()
        defHttp.post(`/platform/Common/SendSMSVerifyCode?phone=${newForm.value.phone}`).then(data=>{
          if(data.Success){
            message.success('发送成功，请耐心等待')
            verifyCode.value.text
          }else{
            message.error(`${data.Message}`)
          }
        })
      }
    }

    const resetCountDown=()=>{
      clearInterval(verifyCode.value.intervalId);
      verifyCode.value.timeoutValue = 30;
      verifyCode.value.disabled = false;
      verifyCode.value.text = '发送验证码';
    }

    const countDown=()=>{
      verifyCode.value.disabled=true
      verifyCode.value.intervalId=setInterval(()=>{
        verifyCode.value.timeoutValue-=1
        if(verifyCode.value.timeoutValue==0){
          resetCountDown()
        }else{
          verifyCode.value.disabled=true
          verifyCode.value.text=`请稍等 ${verifyCode.value.timeoutValue} `;
        }
      })
    }

    const changePhone=(phone)=>{
      phone=''+phone
      return phone.substr(0,3)+'****'+phone.substr(7)
    }

    const beforeUpload = (file) => {
      let size = file.size / 1024 / 1024
      if (size > 20) {
        createMessage.error('上传文件太大',1.5,)
        return false
      }
      return true;
    }

    const customRequest = (file) => {
      const formData = new FormData();
      formData.append("file",file.file);
      defHttp.post('/platform/Common/Upload',formData).then(data=>{
        if(data.Success){
          userInfo.value.avatar=data.Data[0]
          handleAvatar()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handleAvatar=()=>{
      let params={
        avatar:userInfo.value.avatar
      }
      defHttp.post('/student/account/saveAvatar',params).then(data=>{
        if(data.Success){
          message.success('更换成功')
          getBaseInfo()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handlePreserve=()=>{
      let params={
        remarks:userInfo.value.remarks,
        postbox:userInfo.value.postbox,
        careerOrientation:careerOrientation.value
      }
      defHttp.post('/student/account/saveInfo',params).then(data=>{
        if(data.Success){
          message.success('保存成功')
          isEdit.value=false
          editDirection.value=true
          getBaseInfo()
        }else{
          message.error(`${data.Message}`)
        }
      })
    }

    const handleRevise=()=>{
      formIns.value.validate().then(() => {
        btnLoading.value=true
        if(modifyInfo.value.affirmPassword!=modifyInfo.value.newPassword){
          message.error('确认密码与新密码不匹配')
          btnLoading.value= false
          return
        }
        let params={
          oldPassword:modifyInfo.value.oldPassword,
          newPassword:modifyInfo.value.newPassword,
        }
        defHttp.post('/student/account/changePassword',params).then(data=>{
          if(data.Success){
            btnLoading.value= false
            message.success('修改成功')
          }else{
            message.error(`${data.Message}`)
          }
        }).catch(() => {btnLoading.value= false})
      }).catch(() => {btnLoading.value= false})
    }

    return {
      handleRehearse,
      editDirection,
      handleDirection,
      binding,
      Unbinding,
      UnbindingVisible,
      checkingVisible,
      handleContinue,
      bindingVisible,
      handleNextStep,
      accomplishVisible,
      getBaseInfo,
      userInfo,
      changePhone,
      careerOrientationList,
      careerOrientation,
      fileList,
      beforeUpload,
      customRequest,
      handleAvatar,
      handlePreserve,
      contrastPhone,
      handleNext,
      newForm,
      handleSend,
      verifyCode,
      countDown,
      resetCountDown,
      checkModbile,
      activeTab,
      handleTab,
      rules,
      formIns,
      modifyInfo,
      handleRevise,
      btnLoading,
      isEditDirection,
      editDirectionName,
      spinning,
      isEdit,
    };
  },
});
</script>

<style scoped>
.baseContent{
  width: 100%;
  background-color: #f0f2f5;
}
.baseInfo{
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
}
.base{
  width: 274px;
  border-radius: 5px;
  overflow: hidden;
}
.baseBlock{
  width: 100%;
  padding: 18px 0 60px 0;
  background: #FFFFFF;
  text-align: center;
}
.userImg{
  width: 76px;
  height: 76px;
  border-radius:50%;
}
.info{
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
.name{
  font-size: 24px;
  color: #333333;
  line-height: 28px;
}
.womanIcon{
  margin-left: 11px;
  color: #0960bd;
  font-size: 16px;
  line-height: 28px;
}
.manIcon{
  margin-left: 11px;
  font-size: 16px;
  color: #2A9FF5;
  line-height: 28px;
}
.baseMenuItem{
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid #f6f6f6;
}
.baseMenuIcon{
  width: 20px;
  height: 20px;
}
.baseMenuName{
  margin-left: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.userInfo{
  flex: 1;
  margin-left: 10px;
  padding: 24px 130px 120px 42px;
  background: #FFFFFF;
  border-radius: 5px;
}
.headSculpture{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.headerImg{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.shade{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(0,0,0,0.41);
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  line-height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}
.infoBlock{
  padding-top: 17px;
}
.baseInfoTitle{
  padding-top: 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
}
.infoContent{
  padding-top: 20px;
}
.infoItem{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 15px;
}
.infoTitle{
  width: 80px;
  text-align: right;
}
.infoDetail{
  width:240px;
}
.remarks{
  width: 85%;
  background: #F6F6F6;
  border-radius: 10px;
}
.remarks,.ant-input-borderless:hover{
  background: #F6F6F6 !important;
}
.clickBlock{
  color: #55ABFF;
  cursor: pointer;
}
.postbox{
  background: #F6F6F6;
  border-radius: 10px;
}
.postbox,.ant-input-borderless:hover{
  background: #F6F6F6 !important;
}
.preserve{
  text-align: right;
}
.tips{
  padding:20px 0 0 55px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.thirdPartyList{
  padding: 30px 0 0 64px;
  display: flex;
}
.thirdPartyItem{
  display: flex;
}
.thirdPartyIcon{
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.thirdPartyInfo{
  margin-left: 14px;
  padding-top: 6px;
}
.thirdPartyTitle{
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
}
.binding{
  padding-top: 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.marginLeft55{
  margin-left: 55px;
}
.directionTips{
  margin-left: 16px;
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
}
.checkingBtn{
  font-size: 12px;
  font-weight: 400;
  color: #115BA4;
  padding: 0;
}
.UnbindingModal{
  padding-top: 20px;
}
.UnbindingTitle{
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 2px;
}
.UnbindingTips{
  padding-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  letter-spacing: 1px;
}
.operateBtnList{
  padding-top: 38px;
  display: flex;
  justify-content: space-around;
}
.operateBtn{
  width: 94px;
  background: #DBDBDB;
  color: #FFFFFF;
}
.operateBtn,.ant-btn-text:hover{
  background: #DBDBDB !important;
  color: #FFFFFF !important;
}
.checkingTitle{
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 2px;
}
.checkingTips{
  padding-top: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #666666;
  line-height: 12px;
}
.point{
  padding-top: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.importBlock{
  display: flex;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.paddinTop20{
  padding-top: 20px;
}
.typeTitle{
  width: 80px;
  text-align: right;
}
.primaryBtn{
  width: 94px;
}
.accomplishModal{
  padding-top: 8px;
  text-align: center;
}
.accomplishText{
  padding-top: 22px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 2px;
}
.accomplishIcon{
  width: 72px;
  height: 72px;
}
:deep(.directionSelect .ant-select-selector){
  border-radius: 4px !important;
}
:deep(.userInfo .ant-upload.ant-upload-select-picture-card){
  border:0 !important;
  background-color:#fff !important;
}
.ant-upload-picture-card-wrapper{
  text-align:center;
}
.activeTab{
  color: var(--theme);
}
.passwordInput{
  background: #F6F6F6;
  border-radius: 10px;
  height: 34px;
  font-size: 12px !important;
}
.btnList{
  padding-top: 16px;
  margin-left: 100px;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  background: #F6F6F6 !important;
}
</style>
