<template>
  <div class="detail">
    <a-spin :spinning="loading">
      <div class="practiceCore">
        <div class="tab">
          <span class="returnHome" @click="handleReturn">实操训练></span>
          <span class="tabActive">工单详情</span>
        </div>
        <div class="ItemBlock">
          <div class="flex">
            <img class="titleIcon" src="../../assets/image/titleIcon1.png" alt="">
            <div class="titleName">基本信息</div>
          </div>
        </div>
        <div class="baseInfo">
          <div class="baseInfoForm">
            <a-row style="width:100%;">
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">工单编号:</div>
                  <div class="formContent">{{ order.Id }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">完成周期:</div>
                  <div class="formContent">{{ order.DaySpan }}天</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">委托方:</div>
                  <div class="formContent">{{ order.PartnerName }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">预结算积分:</div>
                  <div class="formContent">{{ order.ExpectSettleTotal }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">委托方电话:</div>
                  <div class="formContent">{{ order.PatnerPhone }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">技术要求:</div>
                  <div class="formContent">{{ order.TechRequirement }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">委托时间:</div>
                  <div class="formContent">{{ order.StartDate }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="formItem">
                  <div class="formName">备注信息:</div>
                  <div class="formContent">{{ order.Remark }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="ItemBlock">
          <div class="flex">
            <img class="titleIcon" src="../../assets/image/titleIcon2.png" alt="">
            <div class="titleName">作品信息</div>
          </div>
        </div>
        <div class="baseInfo">
          <div class="baseInfoForm">
            <a-row style="width:100%;">
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">文件类型:</div>
                  <div class="formContent">{{ order.ModelType }}</div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">风格类型:</div>
                  <div class="formContent">{{ order.SkuDesc }}</div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">脚本描述:</div>
                  <div class="formContent">{{ order.ScriptDesc }}</div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">动画技术效果要求:</div>
                  <div class="formContent">{{ order.AnimationRequire }}</div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">承接价格区间:</div>
                  <div class="formContent">{{ order.TakePriceMin }} - {{ order.TakePriceMax }}</div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">备注信息:</div>
                  <div class="formContent">{{ order.ModelRemark }}</div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName"></div>
                  <div class="formContent"></div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">技术文档:</div>
                  <div class="formContent" v-if="order.TechDoc">
                    <span>{{ computeImages(order.TechDoc)[0].name }}</span>
                    <a :href="computeImages(order.TechDoc)[0].url" :download="computeImages(order.TechDoc)[0].name"
                      class="download-btn bg-white aj-padding-lr aj-padding-tb-sm aj-margin-left">下载</a>
                  </div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName">项目图:</div>
                  <div class="formContent" v-if="order.ProjectImages">
                    <a-space :size="8">
                      <a-image :width="120" v-for="img in computeImages(order.ProjectImages)" :key="img.id"
                        :src="img.url" />
                    </a-space>
                  </div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem">
                  <div class="formName lineHeight40">品控表:</div>
                  <div class="formContent">
                    <a-button class="searchBtn" type="primary" size="large" @click="standardShow = true">查看</a-button>
                  </div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem" style="display: none;">
                  <div class="formName">成本渲染图:</div>
                  <div class="formContent" v-if="order.FinishedRenderImages">
                    <a-space :size="8">
                      <a-image :width="120" v-for="img in computeImages(order.FinishedRenderImages)" :key="img.id"
                        :src="img.url" />
                    </a-space>
                  </div>
                </div>
              </a-col>
              <a-col :span="24">
                <div class="formItem" style="display: none;">
                  <div class="formName">3D效果图二维码图:</div>
                  <div class="formContent" v-if="order.ThreeDEffectImages">
                    <a-space :size="8">
                      <a-image :width="120" v-for="img in computeImages(order.ThreeDEffectImages)" :key="img.id"
                        :src="img.url" />
                    </a-space>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div>
          <div class="ItemBlock justify-between">
            <div class="flex">
              <img class="titleIcon" src="../../assets/image/icon3.png" alt="">
              <div class="titleName">历史上传</div>
            </div>
          </div>
          <div class="model-body">
            <div style="width: 100%;display:flex;flex-direction: column;">
              <template v-if="modelFlowList.length">
                <div v-for="(item, index) in modelFlowList" :key="index"
                  style="margin-bottom: 20px;width:100%;display: flex;">
                  <div style="width:30%;padding-right: 20px;box-sizing: border-box;">
                    <div>{{ item.CreateDate }}</div>
                    <div>状态：{{ item.StatusDesc }}</div>
                    <div>备注：{{ item.Remark }}</div>
                  </div>
                  <div style="width:45%;padding: 0 12px;box-sizing: border-box;">
                    <div style="display: flex;justify-content: space-between;width:100%;margin-bottom: 2px;" v-if="item.Models!=null">
                      <div style="width: 70px;text-align: right;">作品内容:&nbsp;</div>
                      <div style="width:calc(100% - 80px)">
                        <div style="width:100%;display: flex;align-items: center;;margin-bottom: 6px;"
                          v-for="item in item.Models" :key="item.uid">
                          <div class="ellipsis1"
                            style="color: #333;padding-right: 12px;box-sizing: border-box;max-width:calc(100% - 30px);">
                            &nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;</div>
                          <a :href="item.url" :download="item.name"
                            style="color: #0A5AFF;display: flex;align-items: center;font-size: 12px;">下载</a>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex;width:100%;justify-content: space-between;" v-if="item.ModelThumbnails!=null">
                      <div style="width: 70px;text-align: right;">作品截图:&nbsp;</div>
                      <div style="display:flex;flex-wrap: wrap;width:calc(100% - 80px)">
                        <div v-for="item in item.ModelThumbnails" :key="item.uid"
                          style="display: flex;align-items: center;color: #333;">
                          <a-image :src="item.url" alt=""
                            style="width: 100px;height: 100px;border-radius: 10px;margin: 0 12px 12px 0;" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else style="padding-left: 36px;"> 暂无上传记录 </div>
            </div>
          </div>
        </div>
        <div v-if="order.Status == 3">
          <div class="ItemBlock justify-between">
            <div class="flex">
              <img class="titleIcon" src="../../assets/image/titleIcon2.png" alt="">
              <div class="titleName">上传作品</div>
            </div>
            <a-button class="searchBtn" type="primary" ghost size="large" @click="handleSubmit">提交(审核)</a-button>
          </div>
          <div class="baseInfo">
            <div class="baseInfoForm">
              <a-row style="width:100%;">
                <a-col :span="24">
                  <div class="formItem">
                    <div class="formName lineHeight32">上传作品:</div>
                    <div class="formContent aj-flex">
                      <a-upload v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequest"
                        :before-upload="beforeUpload" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                        <a-button type="primary">
                          <UploadOutlined />
                          点击上传
                        </a-button>
                      </a-upload>
                      <div class="uploadList lineHeight32 aj-padding-left-lg">
                        <div v-for="(item, index) in saveModal.models" :key="index">
                          <a-space>
                            <a style="color:#115BA4;" :href="item.url" :download="item.name">{{ item.name }}</a>
                          </a-space>
                        </div>
                      </div>
                      <div class="formContent" style="margin-left: 24px;" v-if="order.LatestModel">
                        <a v-if="order.LatestModel.Models" style="color:#115BA4;"
                          :href="computeImages(order.LatestModel.Models)[0].url"
                          :download="computeImages(order.LatestModel.Models)[0].name" class="download-link">{{
                            computeImages(order.LatestModel.Models)[0].name }}</a>
                      </div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="24">
                  <div class="formItem">
                    <div class="formName lineHeight32">上传作品截图:</div>
                    <div class="formContent">
                      <a-upload v-model:file-list="fileList" :showUploadList="false" :customRequest="customRequestImg"
                        :before-upload="beforeUploadImg" action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                        <a-button type="primary">
                          <UploadOutlined />
                          点击上传
                        </a-button>
                      </a-upload>
                      <div class="aj-flex">
                        <div v-for="(item, index) in saveModal.thumbnails" :key="index">
                          <div class="imageBlock aj-margin-right-sm">
                            <img class="aj-padding-top uploadImg" :src="item.url" alt="">
                            <div class="deleteImg" @click="handleDeleteImg(index)">删除</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="formContent" v-if="order.LatestModel">
                      <a-space :size="8" class="aj-margin-top-lg">
                        <a-image :width="120" v-for="img in computeImages(order.LatestModel.ModelThumbnails)" :key="img.id"
                          :src="img.url" />
                      </a-space>
                    </div> -->
                  </div>
                </a-col>

                <a-col :span="24">
                   <a-form  :labelCol="{ style: { width: '150px' } }" :wrapperCol="{ span: 18 }" >
                     <a-form-item label="是否上传到作品墙" name="photoWall">
                       <a-radio-group v-model:value="mouldModal.form.photoWall">
                         <a-radio :value="true">是</a-radio>
                         <a-radio :value="false">否</a-radio>
                       </a-radio-group>
                     </a-form-item>
                     <div v-if="mouldModal.form.photoWall">
                       <a-form-item label="模型类型" name="modelId" :wrapper-col="{ span: 12}">
                         <a-select v-model:value="mouldModal.form.modelId" placeholder="选择模型类型">
                           <a-select-option :value="item.Id" v-for="(item,index) in modelList" :key="index">{{item.Name}}</a-select-option>
                         </a-select>
                       </a-form-item>
                       <a-form-item label="风格类型" name="styleId" :wrapper-col="{ span: 12}">
                         <a-select v-model:value="mouldModal.form.styleId" placeholder="选择风格类型">
                           <a-select-option :value="item.Id" v-for="(item,index) in styleList" :key="index">{{item.Name}}</a-select-option>
                         </a-select>
                       </a-form-item>
                     </div>
                   </a-form>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>

        <div class="ItemBlock">
          <a-row style="width:100%;">
            <a-col :span="8">
              <div class="flex">
                <img class="titleIcon" src="../../assets/image/titleIcon3.png" alt="">
                <div class="titleName">合作商审核</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex">
                <img class="titleIcon" src="../../assets/image/titleIcon4.png" alt="">
                <div class="titleName">数字人审核</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="flex">
                <img class="titleIcon" src="../../assets/image/titleIcon5.png" alt="">
                <div class="titleName">委托方审核</div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="baseInfo">
          <a-row style="width:100%;">
            <a-col :span="8">
              <div v-if="modelFlowHZList.length">
                <div v-for="(item, index) in modelFlowHZList" :key="item.Id">
                  <div class="formItem">
                    <div class="formNameOther">第{{ index + 1 }}次：</div>
                    <div class="formContent">{{ item.Status == 6 ? '通过' : '未通过' }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameOther">反馈：</div>
                    <div class="formContent">{{ item.Remark }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="padding-left: 36px;"> 暂无审核记录 </div>
            </a-col>
            <a-col :span="8">
              <div v-if="modelFlowYSList.length">
                <div v-for="(item, index) in modelFlowYSList" :key="item.Id">
                  <div class="formItem">
                    <div class="formNameOther">第{{ index + 1 }}次：</div>
                    <div class="formContent">{{ item.Status == 8 ? '通过' : '未通过' }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameOther">反馈：</div>
                    <div class="formContent">{{ item.Remark }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="padding-left: 36px;"> 暂无审核记录 </div>
            </a-col>
            <a-col :span="8">
              <div v-if="modelFlowWTList.length">
                <div v-for="(item, index) in modelFlowWTList" :key="item.Id">
                  <div class="formItem">
                    <div class="formNameOther">第{{ index + 1 }}次：</div>
                    <div class="formContent">{{ item.Status == 10 ? '通过' : '未通过' }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameOther">反馈：</div>
                    <div class="formContent">{{ item.Remark }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="padding-left: 36px;"> 暂无审核记录 </div>
            </a-col>
          </a-row>

        </div>

        <div class="ItemBlock">
          <a-row style="width:100%;">
            <a-col :span="12">
              <div class="flex">
                <img class="titleIcon" src="../../assets/image/titleIcon6.png" alt="">
                <div class="titleName">合作商结算详情</div>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="flex">
                <img class="titleIcon" src="../../assets/image/titleIcon7.png" alt="">
                <div class="titleName">创作者结算详情</div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="baseInfo">
          <a-row style="width:100%;">
            <a-col :span="12">
              <div v-if="modelFlowSettleHZList.length">
                <div v-for="(item, index) in modelFlowSettleHZList" :key="item.Id">
                  <div class="formItem">
                    <div class="formNameWid">交付等级：</div>
                    <div class="formContent">{{ item.Judgment == 0 ? '优秀' : (item.Judgment == 1 ? '良好' : '合格') }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameWid">结算价格：</div>
                    <div class="formContent">{{ item.SettlePrice }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameWid">结算时间：</div>
                    <div class="formContent">{{ item.CreateDate }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameWid">工单完成说明：</div>
                    <div class="formContent">{{ item.Remark }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="padding-left: 36px;"> 暂无相关详情 </div>
            </a-col>
            <a-col :span="12">
              <div v-if="modelFlowSettleCZZList.length">
                <div v-for="(item, index) in modelFlowSettleCZZList" :key="item.Id">
                  <div class="formItem">
                    <div class="formNameWid">交付等级：</div>
                    <div class="formContent">{{ item.Judgment == 0 ? '优秀' : (item.Judgment == 1 ? '良好' : '合格') }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameWid">结算价格：</div>
                    <div class="formContent">{{ item.SettlePrice }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameWid">结算时间：</div>
                    <div class="formContent">{{ item.CreateDate }}</div>
                  </div>
                  <div class="formItem">
                    <div class="formNameWid">工单完成说明：</div>
                    <div class="formContent">{{ item.Remark }}</div>
                  </div>
                </div>
              </div>
              <div v-else style="padding-left: 36px;"> 暂无相关详情 </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>

    <!-- 品控标准 -->
    <a-modal v-model:visible="standardShow" width="900px" style="left: 80px;top:140px" :footer="null" :maskClosable="false">
      <template #title>
        <div class="title-tip2">品控标准</div>
      </template>
      <a-table class="noscrollTable" :data-source="order.OrderSubmits">
        <a-table-column data-index="SortNo" title="序号" :width="80"></a-table-column>
        <a-table-column data-index="ConditionDesc" title="交付条件" :width="220">
          <template #default="{ record }">
            <div style="display:flex">
              <div style="flex:1">{{ record.ConditionDesc }}</div>
              <a-tooltip title="点击查看附件"
                v-if="(record.Images && JSON.parse(record.Images).length) || (record.Videos && JSON.parse(record.Videos).length)">
                <div @click="privewFJ(record)"
                  style="color: #E34D4E;cursor: pointer;margin-left: 10px;;text-align: center;">附件</div>
              </a-tooltip>
            </div>
          </template>
        </a-table-column>
        <a-table-column data-index="CustomerCheck" title="委托方审核" :width="150" align="center">
          <template #default="{ record }">
            <CheckCircleOutlined style="color:green;" v-if="record.CustomerCheck" />
            <CloseCircleOutlined style="color:red;" v-else />
          </template>
        </a-table-column>
        <a-table-column data-index="SystemCheck" title="数字人审核" :width="150" align="center">
          <template #default="{ record }">
            <CheckCircleOutlined style="color:green;" v-if="record.SystemCheck" />
            <CloseCircleOutlined style="color:red;" v-else />
          </template>
        </a-table-column>
        <a-table-column data-index="PartnerCheck" title="合作商审核" :width="150" align="center">
          <template #default="{ record }">
            <CheckCircleOutlined style="color:green;" v-if="record.PartnerCheck" />
            <CloseCircleOutlined style="color:red;" v-else />
          </template>
        </a-table-column>
        <a-table-column data-index="CreatorCheck" title="创作者审核" :width="150" align="center">
          <template #default="{ record }">
            <CheckCircleOutlined style="color:green;" v-if="record.CreatorCheck" />
            <CloseCircleOutlined style="color:red;" v-else />
          </template>
        </a-table-column>
      </a-table>
      <!-- <template #footer>
        <div style="display:flex;align-items: center;justify-content: flex-end;margin-bottom: 8px;">
          <a-button danger ghost @click="modalData.standardShow = false" style="margin-right: 0;">取消</a-button>
          <a-button type="primary" style="margin: 0 20px;" @click="modalData.standardShow = false" :loading="modalData.loading">确定</a-button>
        </div>
      </template> -->
    </a-modal>

    <!-- 查看品控标准附件 -->
    <a-modal v-model:visible="privewFJModel.visible" width="850px" :footer="null" style="left: 80px;top:140px" :maskClosable="false">
      <template #title>
        <div class="title-tip2">查看附件</div>
      </template>
      <div style="max-height: 60vh;overflow-y: auto;">
        <a-form>
          <a-form-item :labelCol="{ style: 'width: 100px' }" v-if="privewFJModel.imgList.length">
            <template #label>
              <div> 图片 </div>
            </template>
            <div style="display:flex;flex-wrap: wrap;">
              <div v-for="item in privewFJModel.imgList" :key="item.uid"
                style="display: flex;align-items: center;color: #333;">
                <a-image :src="item.url" alt=""
                  style="width: 100px;height: 100px;border-radius: 10px;margin: 0 12px 12px 0;" />
              </div>
            </div>
          </a-form-item>
          <a-form-item :labelCol="{ style: 'width: 100px' }" v-if="privewFJModel.videoList.length">
            <template #label>
              <div> 视频 </div>
            </template>
            <div style="display:flex;flex-wrap: wrap;">
              <div v-for="item in privewFJModel.videoList" :key="item.uid"
                style="display: flex;align-items: center;color: #333;">
                <video v-if="item.type?.search('video') > -1"
                  style="border-radius: 10px; height: 100px; background-color: black" controls :src="item.url"></video>
              </div>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
  
<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { CheckCircleOutlined, UploadOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router'
import defHttp from "@/utils/http.js";
import { message } from 'ant-design-vue';

import TestJson from "@/utils/response.json";
import { tryParseJson } from '@/utils/common'
export default defineComponent({
  components: {
    CheckCircleOutlined,
    UploadOutlined,
    CloseCircleOutlined
  },
  setup() {
    const fileList = ref([])
    const router = useRouter()
    const route = useRoute()
    const modelFlowHZList = ref([])
    const modelFlowYSList = ref([])
    const modelFlowWTList = ref([])
    const modelFlowSettleCZZList = ref([])
    const modelFlowSettleHZList = ref([])
    const standardShow = ref(false)
    const modelFlowList = ref([])
    const saveModal = ref({
      id: route.query.id,
      models: [],
      thumbnails: []
    })

    const privewFJModel = ref({
      visible: false,
      imgList: [],
      videoList: []
    })

    const order = ref({
      Id: route.query.id,
      TechDoc: null,
      ProjectImages: null,
      FinishedRenderImages: null,
      ThreeDEffectImages: null,
      LatestModel: null,
      AnimationRequire: "",
      Flows: []
    })

    const loading = ref(false)

    onMounted(() => {
      getDetail()
    })

    const computeImages = computed(() => {
      return (str) => {
        try {
          return JSON.parse(str)
        } catch (err) {
          return [{ url: '', name: '' }]
        }
      }
    })

    const getDetail = () => {
      loading.value = true
      defHttp.get('system/order/detail', { params: { id: route.query.id } }).then(data => {
        loading.value = false
        if (data.Success) {
          order.value = data.Data.order
          modelFlowHZList.value = order.value.Flows.filter(a => a.Status == 5 || a.Status == 6)
          modelFlowYSList.value = order.value.Flows.filter(a => a.Status == 7 || a.Status == 8)
          modelFlowWTList.value = order.value.Flows.filter(a => a.Status == 9 || a.Status == 10)
          modelFlowSettleHZList.value = order.value.Flows.filter(a => a.Status == 11)
          modelFlowSettleCZZList.value = order.value.Flows.filter(a => a.Status == 12)
          order.value.Flows.forEach(i => {
            i.Models = tryParseJson(i.Models, 'array')
            i.ModelThumbnails = tryParseJson(i.ModelThumbnails, 'array')
          })
          modelFlowList.value = order.value.Flows
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => { message.error('请求错误') }).finally(() => { loading.value = false })

      // if(TestJson){
      //   order.value=TestJson
      //   order.value.Attachments=JSON.parse(TestJson.Attachments)
      //   modelFlowHZList.value = order.value.Flows.filter(a => a.Status == 5 ||  a.Status == 6)
      //   modelFlowYSList.value = order.value.Flows.filter(a => a.Status == 7 ||  a.Status == 8)
      //   modelFlowWTList.value = order.value.Flows.filter(a => a.Status == 9 ||  a.Status == 10)
      //   modelFlowSettleHZList.value = order.value.Flows.filter(a => a.Status == 11)
      //   modelFlowSettleCZZList.value = order.value.Flows.filter(a => a.Status == 12)
      // }
    }

    const privewFJ = (item) => {
      if (item.Images && JSON.parse(item.Images).length) {
        privewFJModel.value.imgList = JSON.parse(item.Images)
      } else {
        privewFJModel.value.imgList = []
      }
      if (item.Videos && JSON.parse(item.Videos).length) {
        privewFJModel.value.videoList = JSON.parse(item.Videos)
      } else {
        privewFJModel.value.videoList = []
      }
      privewFJModel.value.visible = true
    }


    const handleReturn = () => {
      router.go(-1)
    }

    const beforeUpload = (file) => {
      let size = file.size / 1024 / 1024
      if (size > 20) {
        createMessage.error('上传文件太大', 1.5,)
        return false
      }
      return true;
    }

    const beforeUploadImg = (file) => {
      let size = file.size / 1024 / 1024
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
        return false
      }
      if (size > 20) {
        createMessage.error('上传文件太大', 1.5,)
        return false
      }
      return true;
    }

    const customRequest = (file) => {
      const formData = new FormData();
      formData.append("file", file.file);
      let hide = message.loading({ content: 'Loading...' });
      defHttp.post('/platform/Common/Upload', formData).then(data => {
        if (data.Success) {
          let list = {
            url: data.Data[0],
            id: file.file.uid,
            name: file.file.name,
            percent: 100,
            status: 'done'
          }
          saveModal.value.models.push(list)
        } else {
          message.error(`${data.Message}`)
        }
      }).finally(() => hide())
    }

    const customRequestImg = (file) => {
      const formData = new FormData();
      formData.append("file", file.file);
      let hide = message.loading({ content: 'Loading...' });
      defHttp.post('/platform/Common/Upload', formData).then(data => {
        if (data.Success) {
          let list = {
            url: data.Data[0],
            id: file.file.uid,
            name: file.file.name,
            percent: 100,
            status: 'done'
          }
          saveModal.value.thumbnails.push(list)
        } else {
          message.error(`${data.Message}`)
        }
      }).finally(() => hide())
    }

    const handleDeleteImg = (index) => {
      saveModal.value.thumbnails.splice(index, 1)
    }

    const mouldModal = ref({
      form:{
        photoWall:true,
        modelId:null,
        styleId:null,
      }
    })
    const modelList=ref([])
    const styleList=ref([])
    const getClassifyList=()=>{
      defHttp.get('/trainer/PhotoWall/CategoryList', { params: {type:0,page:1,limit:999} }).then(data => {
        if (data.Success) {
          modelList.value=data.Data.Rows
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
      defHttp.get('/trainer/PhotoWall/CategoryList', { params: {type:1,page:1,limit:999} }).then(data => {
        if (data.Success) {
          styleList.value=data.Data.Rows
        } else {
          message.error(`${data.Message}`)
        }
      }).catch(err => {})
    }
    getClassifyList()
    const handleSubmit = () => {
      let formData = {
        id: order.value.Id,
        models: JSON.stringify(saveModal.value.models),
        thumbnails: JSON.stringify(saveModal.value.thumbnails)
      }
      if (mouldModal.value.form.photoWall && (!mouldModal.value.form.modelId || !mouldModal.value.form.styleId)) {
        message.error('请选择模型类型和模型类型')
        return 
      } 
      if (mouldModal.value.form.photoWall) {
        formData.photoWall=true
        formData.modelId=mouldModal.value.form.modelId
        formData.styleId=mouldModal.value.form.styleId
      } 

      
      defHttp.post('/system/Order/SaveModels', formData).then(data => {
        if (data.Success) {
          handleExamine()
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    const handleExamine = () => {
      let formData = {
        id: order.value.Id,
      }
      defHttp.post('/system/Order/CreatorSubmit', formData).then(data => {
        if (data.Success) {
          message.success('提交成功，请耐心等待审核')
        } else {
          message.error(`${data.Message}`)
        }
      })
    }

    return {
      order,
      fileList,
      loading,
      handleReturn,
      computeImages,
      modelFlowWTList,
      modelFlowYSList,
      modelFlowHZList,
      modelFlowSettleCZZList,
      modelFlowSettleHZList,
      standardShow,
      privewFJ,
      privewFJModel,
      beforeUpload,
      customRequest,
      saveModal,
      customRequestImg,
      beforeUploadImg,
      handleDeleteImg,
      handleSubmit,
      handleExamine,
      modelFlowList,
      mouldModal,
      styleList,
      modelList
    };
  },
});
</script>
  
<style scoped>
a.download-btn {
  color: #618aec;
  border: 1px solid #666;
  border-radius: 4px;
}

a.download-link {
  color: #618aec;
}

.detail {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.practiceCore {
  padding-top: 30px;
  width: 62.5%;
  min-width: 1200px;
  margin: 0 auto;
}

.tab {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  padding-bottom: 20px;
}

.tabActive {
  color: var(--theme);
}

.ItemBlock {
  width: 100%;
  height: 68px;
  padding: 0 30px;
  box-sizing: border-box;
  background: #E1E1E1;
  border-radius: 15px;
  display: flex;
}

.titleIcon {
  width: 34px;
  height: 34px;
  display: block;
  margin: auto 0;
}

.titleName {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 68px;
  margin-left: 20px;
}

.baseInfo {
  padding: 40px 0 20px 0;
}

.baseInfoForm {
  width: 100%;
  display: flex;
}

.formItem {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  margin-bottom: 28px;
}

.formContent {
  padding-left: 24px;
}

.formName {
  width: 140px;
  text-align: right;
}

.formNameOther {
  width: 60px;
  text-align: right;
}

.formNameWid {
  width: 100px;
  text-align: right;
}

.searchBtn {
  margin: auto 0;
}

.lineHeight40 {
  line-height: 40px;
}

.lineHeight32 {
  line-height: 32px;
}

.returnHome {
  cursor: pointer;
}

.imageBlock {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
}

.uploadImg {
  width: 100%;
  height: 100%;
}

.deleteImg {
  width: 100%;
  height: 28px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.model-body {
  width: 100%;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
}
</style>