import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router';
import 'ant-design-vue/dist/antd.less'
import 'vue3-mindmap/dist/style.css'
import 'vite-mindmap/dist/style.css'
createApp(App)
    .use(Antd)
    .use(router)
    .mount("#app");